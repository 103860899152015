import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';

@Component({
    selector: 'otb-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: [
        './thank-you.component.scss',
        './thank-you.component.justice.scss',
        './thank-you.component.hzd-internal.scss',
    ],
    standalone: false,
})
export class ThankYouComponent implements OnInit {
    constructor(private sessionStorageService: SessionStorageService) {}

    ngOnInit(): void {
        this.sessionStorageService.clearWorkflowAndBooking();
    }
}
