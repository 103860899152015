import { Injectable } from '@angular/core';
import { SessionStorageKeyEnum } from '../../enum/session-storage-key-enum';
import { Agency } from '../../api/models/agency';
import { Service } from '../../api/models/service';
import { Booking } from '../../model/booking';
import { Strategy } from '../assignment-strategy/strategy/strategy';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { PersonalDataForm } from '../../api/models/personal-data-form';
import { StateMachine } from 'xstate';
import { WorkflowContext, WorkflowEvent } from '../state/state.types';
import { AssignmentStrategyService } from '../assignment-strategy/assignment-strategy.service';
import { ApplicationSettingsService } from '../application-settings/application-settings.service';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    private readonly CLEAR_AFTER_MINUTES = 120;

    constructor(
        private assigmentStrategyService: AssignmentStrategyService,
        private applicationSettingsService: ApplicationSettingsService,
    ) {
        this.deleteOldStorage();
    }

    deleteOldStorage(): void {
        const timestamp = sessionStorage.getItem(SessionStorageKeyEnum.TIMESTAMP);
        if (timestamp) {
            const oldDate = new Date(timestamp);

            if (oldDate.toString() === 'Invalid Date') {
                this.clearWorkflowAndBooking();
                return;
            }

            const now = new Date();
            const diffMinutes = Math.round((now.getTime() - oldDate.getTime()) / 60000);
            if (diffMinutes >= this.CLEAR_AFTER_MINUTES) {
                this.clearWorkflowAndBooking();
            }
        }
    }

    clearWorkflowAndBooking(): void {
        sessionStorage.removeItem(SessionStorageKeyEnum.BOOKING);
        sessionStorage.removeItem(SessionStorageKeyEnum.PERSONAL_DATA_FORM);
        sessionStorage.removeItem(SessionStorageKeyEnum.STATE_MACHINE_LAST_STATE);
        sessionStorage.removeItem(SessionStorageKeyEnum.STATE_MACHINE_CONFIG);
        sessionStorage.removeItem(SessionStorageKeyEnum.TIMESTAMP);
        sessionStorage.removeItem(SessionStorageKeyEnum.APPLICATION_SETTINGS);
    }

    persistBooking(booking: Booking): void {
        const bookingStorage: BookingExportObject = {
            ...booking,
        };
        sessionStorage.setItem(SessionStorageKeyEnum.BOOKING, JSON.stringify(bookingStorage));
        this.setTimestamp();
    }

    parseBookingFromStorage(): Booking | null {
        const bookingStorage = sessionStorage.getItem(SessionStorageKeyEnum.BOOKING);

        try {
            if (bookingStorage) {
                const booking = new Booking();
                const bookingObj = <BookingExportObject>JSON.parse(bookingStorage);

                booking.agency = bookingObj.agency;
                booking.service = bookingObj.service;
                booking.date = new Date(<string>(bookingObj.date as unknown));
                booking.time = bookingObj.time;
                booking.strategy = this._parseStrategy(bookingObj.strategy);
                booking.uuid = <string>bookingObj.uuid;
                booking.participantsCount = <number>bookingObj.participantsCount;
                booking.personalDataValues = <
                    {
                        [index: string]: string | string[] | number | NgbDateStruct | null;
                    }
                >bookingObj.personalDataValues;
                booking.personalDataForm = <PersonalDataForm>bookingObj.personalDataForm;
                booking.hasAssignmentStrategy = <boolean>bookingObj.hasAssignmentStrategy;
                booking.hasParticipantsCount = <boolean>bookingObj.hasParticipantsCount;
                booking.appointmentType = bookingObj.appointmentType;
                booking.nextRelease = <string>bookingObj.nextRelease;

                return booking;
            }
        } catch (e) {
            console.error({ e });
            this.clearWorkflowAndBooking();
        }

        return null;
    }

    _parseStrategy(strategy: any): Strategy {
        return this.assigmentStrategyService.createStrategyFromSessionStorageObject(strategy);
    }

    persistStateMachine(machine: StateMachine<WorkflowContext, any, WorkflowEvent>, pageContext?: any): void {
        const machineJson = machine.toJSON();
        if (pageContext) {
            machineJson.context = pageContext;
        }
        sessionStorage.setItem(SessionStorageKeyEnum.STATE_MACHINE_CONFIG, JSON.stringify(machineJson));

        this.setTimestamp();
    }

    persistCurrentStateName(currentPage: string): void {
        sessionStorage.setItem(SessionStorageKeyEnum.STATE_MACHINE_LAST_STATE, currentPage);
    }

    private setTimestamp(): void {
        const date = new Date();

        sessionStorage.setItem(SessionStorageKeyEnum.TIMESTAMP, date.toString());
    }

    persistPersonalData(data: string): void {
        sessionStorage.setItem(SessionStorageKeyEnum.PERSONAL_DATA_FORM, data);
    }

    getPersonalData(): any {
        const storage = sessionStorage.getItem(SessionStorageKeyEnum.PERSONAL_DATA_FORM);

        if (!storage) {
            return null;
        }
        try {
            return JSON.parse(storage);
        } catch (e) {
            sessionStorage.removeItem(SessionStorageKeyEnum.PERSONAL_DATA_FORM);
        }

        return null;
    }

    persistApplicationSettings(): void {
        sessionStorage.setItem(
            SessionStorageKeyEnum.APPLICATION_SETTINGS,
            JSON.stringify(this.applicationSettingsService.toJson()),
        );
    }

    loadApplicationSettings(): any {
        try {
            const appSettings = sessionStorage.getItem(SessionStorageKeyEnum.APPLICATION_SETTINGS);
            if (!appSettings) {
                return;
            }

            return this.applicationSettingsService.parseFromJson(JSON.parse(appSettings));
        } catch (e) {
            sessionStorage.clear();
        }
    }
}

interface BookingExportObject {
    agency?: Agency;
    service?: Service;
    date?: Date;
    time?: string;
    strategy?: Strategy;
    uuid?: string;
    participantsCount?: number;
    personalDataValues?: {
        [index: string]: string | string[] | number | NgbDateStruct | null;
    };
    personalDataForm?: PersonalDataForm;
    hasAssignmentStrategy?: boolean;
    hasParticipantsCount?: boolean;
    appointmentType?: string;
    nextRelease?: string;
}
