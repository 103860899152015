import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../../api/models/design-frontend-settings';
import { ContentPage } from '../../api/models/content-page';
import { ContentLinksService } from '../../services/content-links/content-links.service';

@Component({
    selector: 'otb-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss', './footer.component.mags-sop.scss'],
    standalone: false,
})
export class FooterComponent implements OnDestroy {
    sub: Subscription = new Subscription();
    footerColor = '';
    contentPages: Array<ContentPage> = [];
    leftPages: Array<ContentPage> = [];
    rightPages: Array<ContentPage> = [];
    constructor(
        private translateService: TranslateService,
        private applicationSettingsService: ApplicationSettingsService,
        private contentLinksService: ContentLinksService,
    ) {}

    ngOnInit(): void {
        this.initialize();
        this.retrieveContentPages();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.footerColor = settings?.color_footer ?? '';
                },
            ),
        );
    }

    private retrieveContentPages() {
        const getFooterContentPages = this.contentLinksService.footerContentPages$.subscribe(
            (contentPage: Array<ContentPage>) => {
                if (contentPage.length > 0) {
                    this.contentPages = this.contentLinksService.sortContentPages(contentPage);
                    this.leftPages = this.contentPages.filter((page) => page.alignment === 'left');
                    this.rightPages = this.contentPages.filter((page) => page.alignment === 'right');
                }
            },
        );
        this.sub.add(getFooterContentPages);
    }

    getFooterColor(): string | null {
        return this.footerColor?.trim() || null;
    }

    generateAriaLabel(valueTranslationKey: string, ariaLabelTranslationKey: string): string {
        return (
            this.translateService.instant(valueTranslationKey) +
            ' - ' +
            this.translateService.instant(ariaLabelTranslationKey)
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
