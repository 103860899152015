import { Component } from '@angular/core';

@Component({
    selector: 'otb-footer-sh',
    templateUrl: './footer-sh.component.html',
    styleUrls: ['./footer-sh.component.scss']
})
export class FooterShComponent {
    constructor() {}
}
