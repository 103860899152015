import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'otb-accessablity-info',
    templateUrl: './accessablity-info.component.html',
    styleUrls: ['./accessablity-info.component.scss'],
    encapsulation: ViewEncapsulation.None // Textstyles won't apply without this because we load styled text from the backend
})
export class AccessablityInfoComponent {
    constructor() {}
}
