<div [formGroup]="form">
    <ng-select
        [items]="formField.options"
        [formControlName]="fieldName"
        [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
        [placeholder]="'dynamische_frontend_texte.persoenliche_daten.attribute_text.platzhalter_dropdown' | translate"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        [inputAttrs]="{ 'aria-describedby': fieldName + '-error-required', 'aria-labelledby': fieldName + '-label' }"
    >
    </ng-select>
    <otb-form-error-message
        [id]="fieldName + '-error-required'"
        class="required"
        [show]="!isValid && (isDirty || isTouched) && errors?.['required']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.auswahl_nicht_leer' | translate"
    >
    </otb-form-error-message>
</div>
