import { Injectable } from '@angular/core';
import { StrategyName } from 'src/app/services/assignment-strategy/strategy/strategy-name';
import { StrategyFileNumber } from './strategy/strategy-file-number';
import { BookingService } from '../booking/booking.service';
import { RequestBodyDates, RequestBodyTimes } from '../calendar/RequestBodyInterface';
import { ReservationBody } from '../appointment/ReservationBodyInterface';
import { StrategyList } from './strategy/strategy-list';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AssignmentStrategyDataBuilderService {
    constructor(
        private bookingService: BookingService,
        private sessionStorageService: SessionStorageService
    ) {}
    public addStrategyFieldData(body: RequestBodyTimes | RequestBodyDates | ReservationBody): void {
        if (this.bookingService.booking.strategy instanceof StrategyName) {
            body.strategy_name_last_name = this.bookingService.booking.strategy.lastName;
        }
        if (this.bookingService.booking.strategy instanceof StrategyFileNumber) {
            body.strategy_file_number_file_number = this.bookingService.booking.strategy.fileNumberPart;
            body.strategy_file_number_department_number =
                this.bookingService.booking.strategy.selectedDepartmentNumber.value;
        }
        if (this.bookingService.booking.strategy instanceof StrategyList) {
            body.strategy_list_chosen_option = this.bookingService.booking.strategy.chosenOption;
        }
    }
}
