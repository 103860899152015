import { Injectable } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class DateInputService {
    ngbDatepickerFields: NgbInputDatepicker[] = [];
    constructor() {}

    closeAllCalender(inputField: HTMLInputElement): void {
        this.ngbDatepickerFields.forEach((input: NgbInputDatepicker) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const nativeElement = input._elRef.nativeElement;
            if (nativeElement !== inputField) {
                input.close();
            }
        });
    }
}
