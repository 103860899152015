import { Strategy } from './strategy';
import { Assignment } from '../../../api/models/assignment';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { DinSpec91379Service } from '../../../pages/personal-data/dynamic-form/form-group-factory-service/form-field-validator-mapper-service/custom-validators/dinSpec91379/din-spec-91379.service';
import { FormGroup, UntypedFormGroup } from '@angular/forms';

export class StrategyUnknown implements Strategy {
    notice: string = '';
    strategyName: string = 'unknown';

    setDataFromAssignment(assignment: Assignment): void {
        //do nothing on purpose
    }

    buildForm(personalDataForm: PersonalDataForm, dinSpec91379Service: DinSpec91379Service): UntypedFormGroup {
        //should not be called todo use interface segration instead!
        return new FormGroup<any>({});
    }

    markDirty(form: UntypedFormGroup): void {
        //do nothing on purpose todo use interface segration instead!
    }
}
