import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateNamesEnum } from '../../enum/state-names-enum';

@Injectable({
    providedIn: 'root',
})
export class RedirectService {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    handleStaticRedirect(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['static'] === 'true') {
                switch (params['redirect']) {
                    case 'confirmation':
                        this.redirectToConfirmation(params);
                        break;
                    case 'cancellation':
                        this.redirectToCancellation(params);
                        break;
                    case 'barrierefreiheit':
                        this.redirectToBarrierefreiheit(params);
                        break;
                    case 'leichte_sprache':
                        this.redirectToEasyLanguage(params);
                        break;
                }
            }
        });
    }

    private redirectToConfirmation(params: any): void {
        this.router.navigate([StateNamesEnum.CONFIRMATION], {
            queryParams: params,
        });
    }

    private redirectToCancellation(params: any): void {
        this.router.navigate([StateNamesEnum.CANCEL], { queryParams: params });
    }

    private redirectToBarrierefreiheit(params: any): void {
        this.router.navigate(['barrierefreiheit'], { queryParams: params });
    }

    private redirectToEasyLanguage(params: any): void {
        this.router.navigate(['leichte_sprache'], { queryParams: params });
    }
}
