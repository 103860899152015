<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row">
    <h1
        *otbShowInEnv="['hzd-internal']"
        class="col-12"
        [innerHTML]="'dynamische_frontend_texte.anzahl_teilnehmer.ueberschrift' | translate | safeStyle"
    ></h1>
    <h2
        *otbHideInEnv="['hzd-internal']"
        class="col-12"
        [innerHTML]="'dynamische_frontend_texte.anzahl_teilnehmer.ueberschrift' | translate | safeStyle"
    ></h2>
</div>
<div class="row">
    <div class="col-sm-5">
        <h2>
            <small
                [innerHTML]="'dynamische_frontend_texte.anzahl_teilnehmer.hinweis_ueberschrift' | translate | safeStyle"
            ></small>
        </h2>
        <p class="autoext-note">{{ aeSignal().note }}</p>
    </div>
    <div class="col-sm-7 mb-3">
        <otb-form-builder-select-numeric
            label="{{ aeSignal().label! | star }}"
            name="a"
            [items]="options"
            [(value)]="value"
            [defaultValue]="value"
            helpText="Bitte geben Sie an, mit wie vielen Personen Sie zum Termin erscheinen"
        >
        </otb-form-builder-select-numeric>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="form-group d-flex justify-content-between flex-md-row flex-column">
            <button
                class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                [title]="'dynamische_frontend_texte.anzahl_teilnehmer.button_text' | translate"
                (click)="next()"
                [innerHTML]="'dynamische_frontend_texte.anzahl_teilnehmer.button_text' | translate | safeStyle"
                accesskey="w"
            ></button>
            <a
                (click)="back()"
                class="btn-secondary btn pull-left order-md-1 order-2"
                [title]="'dynamische_frontend_texte.anzahl_teilnehmer.zurueck' | translate"
                [innerHTML]="'dynamische_frontend_texte.anzahl_teilnehmer.zurueck' | translate | safeStyle"
                accesskey="z"
                tabindex="0"
            ></a>
        </div>
    </div>
</div>
