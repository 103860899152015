import { Injectable } from '@angular/core';
import { AppointmentService as AppointmentAPIService } from '../../api/services/appointment.service';
import { BookingService } from '../booking/booking.service';
import { Booking } from '../../model/booking';
import { TranslateService } from '@ngx-translate/core';
import { LanguageISOType } from '../../types/LanguageISOType';

@Injectable({
    providedIn: 'root',
})
export class ReservationAgent {
    booking: Booking;

    constructor(
        private appointmentApiService: AppointmentAPIService,
        private bookingService: BookingService,
        private transService: TranslateService,
    ) {}

    cancelReservation(): void {
        if (!this.bookingService.booking.uuid) {
            return;
        }

        this.appointmentApiService
            .deleteApiPublicAppointmentDeleteReservation({
                uuid: this.bookingService.booking.uuid,
                _locale: <LanguageISOType>this.transService.currentLang,
            })
            .subscribe({
                error: (error: any) => {
                    console.error(error);
                    throw new Error('Deleting reservation failed.');
                },
            });
    }
}
