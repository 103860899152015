import { Injectable } from '@angular/core';
import { Agency } from '../../../api/models/agency';

@Injectable({
    providedIn: 'root'
})
export class ServiceOnlyService {
    selectedAgencyFilter: Agency | null = null;
    constructor() {}
}
