<form
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
    [attr.aria-label]="'dynamische_frontend_texte.barrierefreiheit.pflichtfeld_hinweis' | translate"
>
    <div class="row">
        <div
            class="mb-3"
            [innerHTML]="'dynamische_frontend_texte.barrierefreiheit.pflichtfeld_hinweis' | translate | safeStyle"
        ></div>
    </div>

    <div class="row">
        <div class="col-sm-6 clearfix leftSide">
            <div *ngFor="let formField of getLeftSide()" class="form-row left-side-element">
                <otb-form-field [formField]="formField" [form]="form" [formNumber]="0"></otb-form-field>
            </div>
        </div>
        <div class="col-sm-6 rightSide">
            <div *ngFor="let formField of getRightSide()" class="form-row right-side-element">
                <otb-form-field [formField]="formField" [form]="form" [formNumber]="0"></otb-form-field>
            </div>
        </div>

        <ng-template [ngIf]="subFormGroups.length > 0 && notMags()">
            <div id="subForms" [formArrayName]="'subForms'">
                <div class="accordion-container mb-5" *ngFor="let subFormGroup of subFormGroups; let i = index">
                    <div ngbAccordion #acc="ngbAccordion" class="mb-5">
                        <div ngbAccordionItem>
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton>Weitere teilnehmende Person {{ i + 1 }}</button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div class="row" ngbAccordionBody>
                                    <div class="col-sm-6 clearfix leftSide">
                                        <div
                                            *ngFor="let formField of getLeftSideSub()"
                                            class="form-row left-side-element"
                                        >
                                            <ng-container *ngIf="formField">
                                                <otb-form-field
                                                    [formField]="formField"
                                                    [form]="subFormGroup"
                                                    [formNumber]="i + 1"
                                                ></otb-form-field>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 rightSide">
                                        <div
                                            *ngFor="let formField of getRightSideSub()"
                                            class="form-row right-side-element"
                                        >
                                            <ng-container *ngIf="formField">
                                                <otb-form-field
                                                    [formField]="formField"
                                                    [form]="subFormGroup"
                                                    [formNumber]="i + 1"
                                                ></otb-form-field>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="col-sm-12" *otbShowInEnv="['mags-sop', 'default']">
            <p
                class="py-3"
                [innerHTML]="'dynamische_frontend_texte.persoenliche_daten.hinweis' | translate | safeStyle"
            ></p>
        </div>

        <div class="col-sm-12 personaldata-btn--container">
            <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                <!-- todo: backbutton must be optional -->
                <button
                    type="submit"
                    class="btn btn-success btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                    accesskey="w"
                    [disabled]="isUploadProgress"
                    [title]="'dynamische_frontend_texte.persoenliche_daten.button_text' | translate"
                    [innerHTML]="'dynamische_frontend_texte.persoenliche_daten.button_text' | translate | safeStyle"
                ></button>
                <a
                    (click)="back()"
                    class="btn-secondary btn pull-left order-md-1 order-2"
                    [title]="'dynamische_frontend_texte.persoenliche_daten.zurueck' | translate"
                    [innerHTML]="'dynamische_frontend_texte.persoenliche_daten.zurueck' | translate | safeStyle"
                    accesskey="z"
                    tabindex="0"
                >
                </a>
            </div>
        </div>
    </div>
</form>
