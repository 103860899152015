import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'otb-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss', './footer.component.mags-sop.scss']
})
export class FooterComponent {
    constructor(private translateService: TranslateService) {}

    generateAriaLabel(valueTranslationKey: string, ariaLabelTranslationKey: string): string {
        return (
            this.translateService.instant(valueTranslationKey) +
            ' - ' +
            this.translateService.instant(ariaLabelTranslationKey)
        );
    }
}
