import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CancelService } from '../../services/appointment/cancel.service';
import { Appointment } from '../../api/models/appointment';
import { AppointmentResponse } from '../../api/models/appointment-response';
import { AppointmentService } from '../../api/services/appointment.service';
import { ApiConfiguration } from '../../api/api-configuration';

@Component({
    selector: 'otb-cancel',
    templateUrl: './cancel.component.html',
    styleUrls: ['./cancel.component.scss'],
    //Dieser Hack ist leider notwendig bis wir den error-interceptor durch ein besseres system ersetzten.
    //Einfach global alle fehler abfangen war leider nicht gut durchdacht.
    providers: [
        {
            provide: AppointmentService,
            useFactory: (config: ApiConfiguration, httpBackend: HttpBackend) => {
                const httpClientWithoutInterceptors = new HttpClient(httpBackend);
                return new AppointmentService(config, httpClientWithoutInterceptors);
            },
            deps: [ApiConfiguration, HttpBackend],
        },
        {
            provide: CancelService,
            useFactory: (appointmentService: AppointmentService, transService: TranslateService) => {
                return new CancelService(appointmentService, transService);
            },
            deps: [AppointmentService, TranslateService],
        },
    ],
    standalone: false,
})
export class CancelComponent implements OnInit {
    uuid: string = '';
    code: string = '';

    appointmentCancellation: Appointment | null = null;
    success: boolean = false;
    error: boolean = false;

    constructor(
        private cancelService: CancelService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.uuid = params['uuid'];
            this.code = params['code'];

            this.cancelService.getAppointmentDetail(this.uuid).subscribe((response: AppointmentResponse) => {
                this.appointmentCancellation = response.data;
            });
        });
    }

    confirmCancel(): void {
        this.cancelService.cancelAppointment(this.uuid, this.code).subscribe(
            () => {
                this.success = true;
            },
            () => {
                this.error = true;
            },
        );
    }
}
