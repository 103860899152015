import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingScreenService {
    loading: Subject<boolean> = new Subject<boolean>();
    constructor() {}
}
