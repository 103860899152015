import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlashMessageErrorHandlerService } from '../../services/flash-message-error-handler/flash-message-error-handler.service';
import { Subscription } from 'rxjs';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    imports: [NgbAlert, TranslateModule, CommonModule],
    selector: 'otb-error-flash-message',
    standalone: true,
    styleUrls: ['./error-flash-message.component.scss'],
    templateUrl: './error-flash-message.component.html'
})
export class ErrorFlashMessageComponent implements OnInit, OnDestroy {
    messages: Set<string> = new Set<string>();
    messageErrorCodeMap: { [key: string]: string } = {
        '400': 'dynamische_frontend_texte.error_message.400',
        '400_appointment-already-confirmed-exception':
            'dynamische_frontend_texte.error_message.400_appointment-already-confirmed-exception',
        '401': 'dynamische_frontend_texte.error_message.401',
        '403': 'dynamische_frontend_texte.error_message.403',
        '404': 'dynamische_frontend_texte.error_message.404',
        '500': 'dynamische_frontend_texte.error_message.500',
        '503': 'dynamische_frontend_texte.error_message.503'
    };
    show: boolean = false;
    subs: Subscription = new Subscription();
    constructor(private flashMessageErrorHandlerService: FlashMessageErrorHandlerService) {}

    ngOnInit(): void {
        const error$ = this.flashMessageErrorHandlerService.errorSubject.subscribe((error) => {
            if (error && error.status) {
                this.messages.add(this.mapErrorMessages(error));
                this.show = true;
            }
        });

        this.subs.add(error$);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    close(): void {
        this.show = false;
        this.messages.clear();
    }

    private mapErrorMessages(error: any): string {
        let message = '';
        if (error.status) {
            try {
                if (error.status === 400) {
                    message = this.messageErrorCodeMap[this.generateErrorCode(error)];
                } else {
                    message = this.messageErrorCodeMap[error.status];
                }
            } catch (e) {
                console.error(e, 'No error message found for status code: ' + error.status);
            }
        }
        return message;
    }

    private generateErrorCode(error: any): string {
        const jsonObject = JSON.parse(error.error);

        const exceptionName = jsonObject.errors[0].type
            .split('\\')
            .pop()
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            .toLowerCase();

        return `${error.status}_${exceptionName}`;
    }
}
