import { Injectable } from '@angular/core';
import { AppointmentService as AppointmentAPIService } from '../../api/services/appointment.service';
import { BookingService } from '../booking/booking.service';
import { ReservationResponse } from '../../api/models/reservation-response';
import { StateService } from '../state/state.service';
import { AssignmentStrategyDataBuilderService } from '../assignment-strategy/assignment-strategy-data-builder.service';
import { ReservationBody } from './ReservationBodyInterface';
import { BehaviorSubject } from 'rxjs';
import { LanguageISOType } from '../../types/LanguageISOType';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    appointmentReservationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        private appointmentApiService: AppointmentAPIService,
        private bookingService: BookingService,
        private stateService: StateService,
        private assignmentStrategyDataBuilderService: AssignmentStrategyDataBuilderService,
        private transService: TranslateService
    ) {}

    reserveAppointment(): void {
        if (!this.bookingService.booking.agency) {
            throw new Error('We need an agency here');
        }
        if (!this.bookingService.booking.service) {
            throw new Error('We need a service here');
        }

        const body: ReservationBody = {
            agency_id: this.bookingService.booking.agency.id.toString(),
            service_id: this.bookingService.booking.service.id.toString(),
            date_choice: this.bookingService.booking.date?.toLocaleDateString('de-DE') as string,
            time_choice: this.bookingService.booking.time as string,
            _locale: <LanguageISOType>this.transService.currentLang
        };
        if (typeof this.bookingService.booking.uuid !== undefined) {
            body.reserved_uuid = this.bookingService.booking.uuid;
        }
        if (this.bookingService.booking.participantsCount > 1) {
            body.participants_count = this.bookingService.booking.participantsCount;
        }
        if (this.bookingService.booking.appointmentType !== null) {
            body.service_type = this.bookingService.booking.appointmentType;
        }

        this.assignmentStrategyDataBuilderService.addStrategyFieldData(body);

        this.appointmentApiService
            .postApiPublicAppointmentReservation(body)
            .subscribe((response: ReservationResponse) => {
                if (response.data.is_successful) {
                    this.stateService.next();
                    this.bookingService.booking.uuid = response.data.uuid;
                }

                this.appointmentReservationLoading.next(false);
            });
    }
}
