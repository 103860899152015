import { Component, Input } from '@angular/core';
import { faCancel, faFile } from '@fortawesome/free-solid-svg-icons';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { finalize, Subscription } from 'rxjs';
import { AppointmentService } from 'src/app/api/services';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ApiConfiguration } from '../../../../../../api/api-configuration';
import { DynamicFormService } from '../../../dynamic-form.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'otb-file-upload-field',
    templateUrl: './file-upload-field.component.html',
    styleUrls: ['./file-upload-field.component.scss'],
    standalone: false,
})
export class FileUploadFieldComponent extends FieldBase {
    faFile = faFile;
    faCancel = faCancel;

    fileName = '';
    oldFileName = '';

    uploadProgress: number | null;
    uploadSub: Subscription | null;
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;
    @Input() formNumber: number = 0;

    constructor(
        private http: HttpClient,
        private booking: BookingService,
        private apiConfiguration: ApiConfiguration,
        private dynamicFormService: DynamicFormService,
        private transService: TranslateService,
    ) {
        super();
    }
    onFileSelected(event: any): void {
        const file: File = event.target.files[0];

        if (file) {
            this.dynamicFormService.setValidation(true);
            this.form.controls[this.formField.name].setErrors(null);
            this.form.controls[this.formField.name].markAsDirty();
            const fileSizeLimit = this.formField.file_size * 1024 * 1024;

            if (file.size > fileSizeLimit) {
                this.dynamicFormService.setValidation(false);
                this.form.controls[this.formField.name].setErrors({
                    maxSize: { file: file, limit: fileSizeLimit },
                });
                return;
            }
            if (file.size <= 0) {
                this.dynamicFormService.setValidation(false);
                this.form.controls[this.formField.name].setErrors({
                    emptyFile: { file: file },
                });
                return;
            }

            let permittedType = this.formField.permitted_values;
            permittedType = this.extractMimeTypesFromPermittedValues(permittedType);
            if (!permittedType.includes(file.type)) {
                this.dynamicFormService.setValidation(false);
                const readablePermittedExt = this.formField.permitted_extensions.join(', ');
                this.form.controls[this.formField.name].setErrors({
                    mimeTypes: {
                        file: file,
                        permitted_values: readablePermittedExt,
                    },
                });
                return;
            }

            this.oldFileName = this.fileName;
            this.fileName = file.name;
            this.form.get(this.formField.name)?.setValue(file.name);
            const body = new FormData();
            body.append('myFile', file);
            body.append('fieldName', this.formField.name);
            body.append('subFormNumber', this.formNumber.toString());

            const url =
                this.apiConfiguration.rootUrl +
                AppointmentService.PostApiPublicAppointmentUploadPath.replace(
                    '{uuid}',
                    this.booking.booking.uuid,
                ).replace('{_locale}', this.transService.currentLang);
            const upload$ = this.http
                .post(url, body, {
                    reportProgress: true,
                    observe: 'events',
                })
                .pipe(finalize(() => this.reset()));

            this.uploadSub = upload$.subscribe((event) => {
                if (event.type == HttpEventType.UploadProgress && event.total !== undefined) {
                    this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                    if (this.uploadProgress == 100) {
                        this.dynamicFormService.setValidation(false);
                    }
                }
            });
        }
    }

    cancelUpload(): void {
        this.uploadSub?.unsubscribe();
        this.fileName = this.oldFileName;
        this.form.value[this.formField.name] = this.oldFileName;
        this.dynamicFormService.setValidation(false);
        this.reset();
    }

    reset(): void {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    extractMimeTypesFromPermittedValues(permittedType: string[]): string[] {
        let additionalTypes: any[] = [];
        permittedType.forEach((element: string, _index: number) => {
            if (element.indexOf(',') != -1) {
                additionalTypes = element.split(',');
                permittedType = permittedType.concat(additionalTypes);
            }
        });
        return permittedType;
    }
}
