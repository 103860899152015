import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class NoHtmlCharactersService {
    noHtmlCharacters = (): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            if (new RegExp(/([<>])/).test(control.value)) {
                return {
                    nohtmlcharacters: {
                        value: control.value
                    }
                };
            }

            return null;
        };
    };
}
