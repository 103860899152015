import { Injectable } from '@angular/core';
import { PersonalDataForm } from '../../../../api/models/personal-data-form';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldDefaultValueMapperService } from './form-field-default-value-mapper-service/form-field-default-value-mapper.service';
import { FormFieldValidatorMapperService } from './form-field-validator-mapper-service/form-field-validator-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class FormGroupFactory {
    constructor(
        private defaultValueMapperService: FormFieldDefaultValueMapperService,
        private validatorMapperService: FormFieldValidatorMapperService,
    ) {}

    toFormGroup(personalDataForm: PersonalDataForm, subForms: number[], currentFormData: any): UntypedFormGroup {
        const group: any = {};
        personalDataForm.form_fields.forEach((field) => {
            group[field.name] = new UntypedFormControl(
                this.defaultValueMapperService.mapDefaultValue(field, null, currentFormData),
                this.validatorMapperService.mapValidators(field),
            );
        });

        if (!personalDataForm.sub_form_fields) {
            return new UntypedFormGroup(group);
        }

        if (personalDataForm.sub_form_fields?.length > 0 && subForms.length > 0) {
            const innerFormGroup: UntypedFormGroup[] = [];
            subForms.forEach((formNumber) => {
                const innerGroup: any = {};
                personalDataForm.sub_form_fields!.forEach((field) => {
                    innerGroup[field.name] = new UntypedFormControl(
                        this.defaultValueMapperService.mapDefaultValue(field, formNumber, currentFormData),
                        this.validatorMapperService.mapValidators(field),
                    );
                });
                innerFormGroup.push(new UntypedFormGroup(innerGroup));
            });
            group['subForms'] = new UntypedFormArray(innerFormGroup);
        }
        return new UntypedFormGroup(group);
    }
}
