/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvailableDatesResponse } from '../models/available-dates-response';
import { AvailableTimesResponse } from '../models/available-times-response';
import { getApiPublicAvailableDates } from '../fn/appointment-calendar/get-api-public-available-dates';
import { GetApiPublicAvailableDates$Params } from '../fn/appointment-calendar/get-api-public-available-dates';
import { getApiPublicCalendarTime } from '../fn/appointment-calendar/get-api-public-calendar-time';
import { GetApiPublicCalendarTime$Params } from '../fn/appointment-calendar/get-api-public-calendar-time';

@Injectable({ providedIn: 'root' })
export class AppointmentCalendarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicAvailableDates()` */
  static readonly GetApiPublicAvailableDatesPath = '/api/public/{_locale}/available_dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicAvailableDates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAvailableDates$Response(params: GetApiPublicAvailableDates$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableDatesResponse>> {
    return getApiPublicAvailableDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicAvailableDates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicAvailableDates(params: GetApiPublicAvailableDates$Params, context?: HttpContext): Observable<AvailableDatesResponse> {
    return this.getApiPublicAvailableDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableDatesResponse>): AvailableDatesResponse => r.body)
    );
  }

  /** Path part for operation `getApiPublicCalendarTime()` */
  static readonly GetApiPublicCalendarTimePath = '/api/public/{_locale}/available_times';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicCalendarTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicCalendarTime$Response(params: GetApiPublicCalendarTime$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableTimesResponse>> {
    return getApiPublicCalendarTime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicCalendarTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicCalendarTime(params: GetApiPublicCalendarTime$Params, context?: HttpContext): Observable<AvailableTimesResponse> {
    return this.getApiPublicCalendarTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableTimesResponse>): AvailableTimesResponse => r.body)
    );
  }

}
