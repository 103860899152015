<div [formGroup]="form">
    <input
        [formControlName]="fieldName"
        [id]="fieldName"
        type="email"
        [placeholder]="formField.placeholder"
        class="form-control"
        [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        [attr.aria-labelledby]="fieldName + '-label'"
        [attr.aria-describedby]="fieldName + '-error-required ' + fieldName + '-error-email'"
    />
    <otb-form-error-message
        [id]="fieldName + '-error-required'"
        class="required"
        [show]="!isValid && (isDirty || isTouched) && errors?.['required'] !== undefined"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.email_nicht_leer' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-email'"
        class="email"
        [show]="!isValid && (isDirty || isTouched) && errors?.['email'] !== undefined"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.email_ungültig' | translate"
    >
    </otb-form-error-message>
</div>
