<div class="row">
    <div class="col-12" *ngIf="isLandingPage">
        <p class="addition">
            <span [innerHTML]="'dynamische_frontend_texte.landing.ueberschrift' | translate | safeStyle"></span>
        </p>
    </div>

    <otb-inner-breadcrumb *otbShowInEnv="['default', 'sh', 'education-check']"></otb-inner-breadcrumb>
    <otb-progress-bar *ngIf="!isConfirmationPage && !isCancelPage"></otb-progress-bar>
</div>
