<nav
    aria-label="breadcrumb"
    class="breadcrumb-container mb-4 d-flex flex-wrap"
    *ngIf="showBreadcrumb"
    #breadcrumbContainer
>
    <ol class="breadcrumb">
        <otb-breadcrumb-item
            *ngFor="let breadcrumb of breadcrumbs; last as isLast"
            [breadcrumb]="breadcrumb"
            [isLast]="isLast"
        ></otb-breadcrumb-item>
    </ol>
</nav>
