<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'bb-jm', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

@if (hasAppointments) {
    <div class="row">
        <div class="col-12">
            <h1
                *otbShowInEnv="['hzd-internal']"
                [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.html_content.ueberschrift' | translate | safeStyle"
            ></h1>
            <h2
                *otbHideInEnv="['hzd-internal']"
                [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.html_content.ueberschrift' | translate | safeStyle"
            ></h2>
        </div>
        @if (!showFormSwitcherTop) {
            <div class="col-12 mb-5">
                <div class="changeform__container">
                    <a
                        (click)="switchToDateFirst($event)"
                        href="#"
                        class="changeform has--before-arrow"
                        title="{{
                            'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmtes_datum'
                                | translate
                        }}"
                        [innerHTML]="
                            'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmtes_datum'
                                | translate
                                | safeStyle
                        "
                    >
                    </a>
                </div>
            </div>
        }
        <div class="col-12 col-sm-6" [class.d-none]="timesLoading">
            <otb-time-pick-selection
                [dateFirst]="false"
                [availableAgencies]="times"
                (timeSelect)="onTimeSelect($event)"
            ></otb-time-pick-selection>
        </div>
        @if (timeChosen) {
            <div class="col-12 col-sm-6 mb-5">
                <div class="upper__container mb-5 mb-sm-2">
                    <p
                        [innerHTML]="
                            'dynamische_frontend_texte.zeit_auswahl.html_content.auswahl'
                                | translate
                                | replaceDateTime: selectedTimeDateFormat
                                | async
                        "
                        role="status"
                    ></p>
                </div>
                <div
                    #calendarContainer
                    [class.colorblind-active]="colorLegendShow | async"
                    [class.d-none]="timesLoading || calendarLoading"
                >
                    <input type="hidden" #datepicker />
                </div>
                @if (timeChosen && calendarLoading) {
                    <div class="loading--text">
                        <otb-loading-animation-dots
                            [text]="'dynamische_frontend_texte.lade_animation.kalender'"
                        ></otb-loading-animation-dots>
                    </div>
                }
                @if (!showFormSwitcherTop) {
                    <div class="col-12 mb-5">
                        <div class="changeform__container">
                            <a
                                (click)="switchToDateFirst($event)"
                                href="#"
                                class="changeform has--before-arrow"
                                title="{{
                                    'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmtes_datum'
                                        | translate
                                }}"
                                [innerHTML]="
                                    'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmtes_datum'
                                        | translate
                                        | safeStyle
                                "
                            >
                            </a>
                            <fa-icon class="fa" [icon]="faArrowCircleRight"></fa-icon>
                        </div>
                    </div>
                }
                <otb-color-legend [class.d-none]="timesLoading || calendarLoading"></otb-color-legend>
                <!-- only relevant for time first -->
                @if (dateChosen | async) {
                    <button
                        type="submit"
                        class="btn btn-success float-end mt-5"
                        accesskey="w"
                        btnPrimaryColorDirective
                        (click)="submit()"
                        [innerHTML]="'dynamische_frontend_texte.shared.button.next' | translate | safeStyle"
                    ></button>
                }
            </div>
        }
        <div class="loading--text col-6" [class.d-none]="!timesLoading"></div>
    </div>
} @else {
    <div class="row">
        <div class="col-12">
            <p
                class="info__header"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift_keine_termine'
                        | translate
                        | safeStyle
                "
            ></p>
            <p
                class="info__content"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.naechste_freie_termine'
                        | translate
                        | replaceDateTime: nextAppointmentAt
                        | async
                "
            ></p>
        </div>
    </div>
}
@if (timesLoading) {
    <div class="row">
        <div class="col-12">
            <otb-loading-animation-dots
                [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
            ></otb-loading-animation-dots>
        </div>
    </div>
}
