import { Injectable } from '@angular/core';
import { Booking } from '../../model/booking';
import { BookingService } from '../booking/booking.service';
import { StateService } from '../state/state.service';
import { AppointmentService } from '../../api/services/appointment.service';
import { CustomNgbDateParserFormatter } from '../../pages/personal-data/dynamic-form/form-field/fields/date-field/custom-ngbDateParserFormatter';
import { LoadingScreenService } from '../loading-screen/loading-screen.service';
import { LanguageISOType } from '../../types/LanguageISOType';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SummaryService {
    constructor(
        private bookingService: BookingService,
        private stateService: StateService,
        private appointmentService: AppointmentService,
        private loadingScreenService: LoadingScreenService,
        private transService: TranslateService
    ) {}

    get booking(): Booking {
        return this.bookingService.booking;
    }

    confirmBooking(): void {
        this.loadingScreenService.loading.next(true);
        this.appointmentService
            .postApiPublicAppointment({
                uuid: this.booking.uuid,
                _locale: <LanguageISOType>this.transService.currentLang,
                body: this.getPersonalDataValues()
            })
            .subscribe({
                next: () => {
                    this.bookingService.clearBooking();
                    this.stateService.next();
                },
                error: () => {
                    this.loadingScreenService.loading.next(false);
                },
                complete: () => {
                    this.loadingScreenService.loading.next(false);
                }
            });
    }

    private getPersonalDataValues(): any {
        const personalDataValues = this.booking.personalDataValues;
        const convertedValues: any = {};
        for (const k in personalDataValues) {
            const v = personalDataValues[k];
            convertedValues[k] = v;
            if (typeof v === 'object' && v !== null && 'day' in v && 'month' in v && 'year' in v) {
                const parser = new CustomNgbDateParserFormatter();
                convertedValues[k] = parser.format(v);
            }
        }
        return convertedValues;
    }

    abortBooking(): void {
        this.stateService.back();
    }
}
