import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppointmentResponse } from '../../api/models/appointment-response';
import { AppointmentService } from '../../api/services/appointment.service';
import { LanguageISOType } from '../../types/LanguageISOType';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class CancelService {
    constructor(
        private appointmentService: AppointmentService,
        private transService: TranslateService,
    ) {}

    getAppointmentDetail(uuid: string): Observable<AppointmentResponse> {
        return this.appointmentService.getApiPublicGetAppointment({
            uuid: uuid,
            _locale: <LanguageISOType>this.transService.currentLang,
        });
    }

    cancelAppointment(uuid: string, code: string): Observable<void> {
        return this.appointmentService.postApiPublicAppointmentCancellation({
            uuid: uuid,
            _locale: <LanguageISOType>this.transService.currentLang,
            body: {
                code: code,
                comment: '',
            },
        });
    }
}
