import { computed, Injectable, OnDestroy, signal, Signal, WritableSignal } from '@angular/core';
import { ApplicationSettingsService } from '../application-settings/application-settings.service';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApplicationLoadedService implements OnDestroy {
    /**
     * computed signal if the application is loaded
     * add more conditions if needed
     */
    applicationLoaded: Signal<boolean> = computed(() => this.languageLoaded() && this.applicationSettingsLoaded());
    languageLoaded: WritableSignal<boolean> = signal(false);
    applicationSettingsLoaded: WritableSignal<boolean> = signal(false);

    private appSubscription: Subscription;
    constructor(private applicationSettingsService: ApplicationSettingsService) {
        this.appSubscription = this.applicationSettingsService.applicationSettingsLoaded.subscribe((loaded) => {
            this.applicationSettingsLoaded.set(loaded);
        });
    }

    ngOnDestroy(): void {
        this.appSubscription.unsubscribe();
    }
}
