import { Component, Input, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeStylePipe } from '../../pipes/sanitizer/safe-style.pipe';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'otb-back-button',
    templateUrl: './back-button.component.html',
    styleUrl: './back-button.component.scss',
    standalone: false,
})
export class BackButtonComponent implements OnInit {
    @Input() text: string = 'dynamische_frontend_texte.shared.button.back';

    translatedText: SafeHtml = '';

    constructor(
        private translate: TranslateService,
        private stateService: StateService,
        private safeStylePipe: SafeStylePipe,
    ) {}

    ngOnInit(): void {
        this.translate.get(this.text).subscribe((res: string) => {
            this.translatedText = this.safeStylePipe.transform(res);
        });
    }

    back(): void {
        this.stateService.back();
    }
}
