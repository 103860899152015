import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { BookingService } from '../../../services/booking/booking.service';

@Injectable({
    providedIn: 'root'
})
export class PersonalDataFormService {
    personalDataForm: BehaviorSubject<PersonalDataForm> = new BehaviorSubject<PersonalDataForm>({
        form_fields: [],
        form_id: 0
    });
    constructor(private bookingService: BookingService) {
        this.personalDataForm.subscribe((dataForm: PersonalDataForm) => {
            if (!this.bookingService.booking) {
                throw new Error('Booking is missing');
            }
            this.bookingService.booking.personalDataForm = dataForm;
        });
    }
}
