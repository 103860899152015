<div class="form-group" [formGroup]="form">
    <label [for]="fieldName" [ngClass]="{ required: formField.is_required }" [id]="fieldName + '-label'">
        {{ formField.label }}
        <span *ngIf="formField.is_required">*</span>
    </label>

    <div [ngSwitch]="formField.type">
        <otb-text-field
            *ngSwitchCase="'text'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-text-field>
        <otb-email-field
            *ngSwitchCase="'email'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-email-field>
        <otb-phone-field
            *ngSwitchCase="'phone'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-phone-field>
        <otb-text-area-field
            *ngSwitchCase="'textarea'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-text-area-field>
        <otb-numeric-field
            *ngSwitchCase="'numeric'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-numeric-field>
        <otb-select-field
            *ngSwitchCase="'select'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
            [id]="fieldName"
        ></otb-select-field>
        <otb-select-field
            *ngSwitchCase="'country_select'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
            [id]="fieldName"
        ></otb-select-field>
        <otb-numeric-select-field
            *ngSwitchCase="'numeric_select'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
            [id]="fieldName"
        ></otb-numeric-select-field>
        <otb-date-field
            *ngSwitchCase="'date'"
            [minDate]="formField.min_date"
            [maxDate]="formField.max_date"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-date-field>
        <otb-checkbox-field
            *ngSwitchCase="'checkbox'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
            [id]="fieldName"
        ></otb-checkbox-field>
        <otb-file-upload-field
            *ngSwitchCase="'file_upload'"
            [formField]="formField"
            [form]="form"
            [formNumber]="formNumber"
        ></otb-file-upload-field>
    </div>
    <small *ngIf="formField.help !== ''" class="form-text text-muted">{{ formField.help }}</small>
</div>
