import { Component, WritableSignal } from '@angular/core';
import { ColorExtractorService } from '../color-extractor.service';

@Component({
    selector: 'otb-skeleton-loader-logo',
    templateUrl: './skeleton-loader-logo.component.html',
    styleUrls: ['./skeleton-loader-logo.component.scss'],
    standalone: false,
})
export class SkeletonLoaderLogoComponent {
    backgroundColor: WritableSignal<string>;
    constructor(private colorExtractorService: ColorExtractorService) {
        this.backgroundColor = this.colorExtractorService.headerColor;
    }
}
