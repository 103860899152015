import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormField } from 'src/app/api/models';

export abstract class FieldBase {
    abstract formField: FormField;
    abstract form: UntypedFormGroup;
    abstract formNumber: number;

    get isValid(): boolean {
        return this.form.controls[this.formField.name].valid;
    }

    get isDirty(): boolean {
        return this.form.controls[this.formField.name].dirty;
    }

    get isTouched(): boolean {
        return this.form.controls[this.formField.name].touched;
    }

    get isDisabled(): boolean {
        return this.form.controls[this.formField.name].disabled;
    }

    get errors(): ValidationErrors | null {
        return this.form.controls[this.formField.name].errors;
    }

    get fieldName(): string {
        return this.formField.name;
    }

    get isDinSpec91379compliant(): boolean {
        return this.formField.is_din_spec91379_compliant;
    }
}
