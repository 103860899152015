import { Injectable } from '@angular/core';
import { SessionStorageKeyEnum } from '../../../enum/session-storage-key-enum';
import { StrategyName } from './strategy-name';
import { SelectOptionType } from '../../../pages/assignment-strategy/types';

@Injectable({
    providedIn: 'root',
})
export class StrategySessionStorageParserService {
    static getLastName(): string | undefined {
        return this.getStrategyField('lastName');
    }
    static getFileNumberPart(): string | undefined {
        return this.getStrategyField('fileNumberPart');
    }
    static getFileSelectedDepartmentNumber(): SelectOptionType | undefined {
        return this.getStrategyField('selectedDepartmentNumber');
    }
    static getFileSelectedRegistrationMark(): SelectOptionType | undefined {
        return this.getStrategyField('selectedRegistrationMark');
    }
    static getFileSelectedYear(): SelectOptionType | undefined {
        return this.getStrategyField('selectedYear');
    }

    static getListChosenOption(): string | undefined {
        return this.getStrategyField('chosenOption');
    }

    private static getStrategyField(fieldName: string): any | undefined {
        try {
            const strategy = this.getStrategyFromStorage();
            return strategy ? strategy[fieldName] : undefined;
        } catch (e) {
            return undefined;
        }
    }

    private static getStrategyFromStorage(): any | undefined {
        const bookingStorage = sessionStorage.getItem(SessionStorageKeyEnum.BOOKING);

        if (!bookingStorage) {
            return undefined;
        }

        try {
            let bookingSessionStorage = JSON.parse(bookingStorage);
            return bookingSessionStorage!.strategy;
        } catch (e) {}

        return undefined;
    }
}
