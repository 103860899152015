import { Component, Input } from '@angular/core';

@Component({
    selector: 'otb-form-error-message',
    templateUrl: './form-error-message.component.html',
    styleUrls: ['./form-error-message.component.scss', './form-error-message.component.mags-sop.scss']
})
export class FormErrorMessageComponent {
    @Input() show: boolean;
    @Input() showErrorBadge: boolean = true;
    @Input() text: string = 'Dieser Wert sollte nicht leer sein.';

    constructor() {}
}
