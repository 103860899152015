import { AfterViewInit, Component, Input, ViewChild, Renderer2, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { UntypedFormGroup } from '@angular/forms';
import { DateInputService } from '../../../../../../services/date-input/date-input.service';
import { NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { DateHelperService } from '../../../../../../services/date-helper/date-helper.service';
import { CustomDatepickerI18n } from './custom-datepicker-i18n';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CalendarLoadTranslationService } from '../../../../../../services/calendar/calendar-load-translation.service';
import { DynamicLocaleId } from '../../../../../../services/language/DynamicLocaleId';
import { Observable, Subscription, tap } from 'rxjs';
import { TranslationData } from '../../../../../../interfaces/translation-data';
import { I18NTranslationResponse } from '../../../../../../api/models/i-18-n-translation-response';

@Component({
    selector: 'otb-date-field',
    templateUrl: './date-field.component.html',
    styleUrls: ['./date-field.component.scss'],
    standalone: false,
})
export class DateFieldComponent extends FieldBase implements OnInit, AfterViewInit, OnDestroy {
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;

    @Input() formNumber: number = 0;
    @ViewChild('d') ngbPicker: NgbInputDatepicker;
    @Input() maxDate: string;
    @Input() minDate: string;
    private dynamicLocaleId: DynamicLocaleId;
    private langChangeSubscription: Subscription;
    private prevLang: string;

    constructor(
        private dateInputService: DateInputService,
        private dateHelperService: DateHelperService,
        private ngbDatepickerI18n: NgbDatepickerI18n,
        private translateService: TranslateService,
        private calendarLoadTranslationService: CalendarLoadTranslationService,
        private renderer: Renderer2,
        private el: ElementRef,
    ) {
        super();
        this.dynamicLocaleId = new DynamicLocaleId(this.translateService);
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            const currentLang = event.lang;

            if (this.prevLang !== currentLang) {
                this.prevLang = currentLang;

                this.loadDatePickerTranslation().subscribe(() => {
                    this.updateButtons();
                });
            }
        });
    }

    private dateToNgbStruct(date: string): NgbDateStruct {
        return this.dateHelperService.dateToNgbDateStruct(this.dateHelperService.dateISOStringtoDate(date));
    }

    get maxDateNgb(): NgbDateStruct {
        if (this.maxDate === '1900-01-01T00:00:00+00:00') {
            this.maxDate = '2100-01-01T00:00:00+00:00';
        }
        return this.dateToNgbStruct(this.maxDate);
    }

    get minDateNgb(): NgbDateStruct {
        return this.dateToNgbStruct(this.minDate);
    }

    updateButtons(): void {
        if (this.ngbDatepickerI18n instanceof CustomDatepickerI18n) {
            const translations = (<CustomDatepickerI18n>this.ngbDatepickerI18n).translations;
            const { previousMonth, nextMonth, selectMonth, selectYear } = translations;

            const elementsInfo = [
                {
                    selector: "button[title='Vorheriger Monat'][aria-label='Vorheriger Monat']",
                    translation: previousMonth,
                },
                {
                    selector: "button[title='Nächster Monat'][aria-label='Nächster Monat']",
                    translation: nextMonth,
                },
                {
                    selector: "select[title='Monat wählen'][aria-label='Monat wählen']",
                    translation: selectMonth,
                },
                {
                    selector: "select[title='Jahr wählen'][aria-label='Jahr wählen']",
                    translation: selectYear,
                },
            ];

            elementsInfo.forEach((info) => {
                const elements = this.el.nativeElement.querySelectorAll(info.selector);

                elements.forEach((element: any) => {
                    this.renderer.setAttribute(element, 'title', info.translation);
                    this.renderer.setAttribute(element, 'aria-label', info.translation);
                });
            });
        }
    }

    ngOnInit(): void {
        this.loadDatePickerTranslation();
    }

    ngAfterViewInit(): void {
        this.dateInputService.ngbDatepickerFields.push(this.ngbPicker);

        const observer = new MutationObserver(() => {
            this.updateButtons();
        });

        observer.observe(this.el.nativeElement, {
            childList: true,
            subtree: true,
        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    loadDatePickerTranslation(): Observable<I18NTranslationResponse> {
        const locale: string = this.dynamicLocaleId.toString();
        return this.calendarLoadTranslationService.getI18NTranslationData(locale).pipe(
            tap((i18nResponse: I18NTranslationResponse) => {
                const translationData = <TranslationData>JSON.parse(<string>i18nResponse.data);
                if (this.ngbDatepickerI18n instanceof CustomDatepickerI18n) {
                    (this.ngbDatepickerI18n as CustomDatepickerI18n).translations = translationData.ngb.datepicker;
                }
            }),
        );
    }
}
