import { Component, Input } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormField } from '../../../../api/models/form-field';

@Component({
    selector: 'otb-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss', './form-field.component.mags-sop.scss'],
    standalone: false,
})
export class FormFieldComponent {
    @Input() formField!: FormField;
    @Input() form!: UntypedFormGroup;
    @Input() formNumber: number = 0;

    get isValid(): boolean {
        return this.form.controls[this.formField.name].valid;
    }

    get isDirty(): boolean {
        return this.form.controls[this.formField.name].dirty;
    }

    get isTouched(): boolean {
        return this.form.controls[this.formField.name].touched;
    }

    get errors(): ValidationErrors | null {
        return this.form.controls[this.formField.name].errors;
    }

    get fieldName(): string {
        return this.formField.name;
    }
}
