import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'star'
})
export class StarPipe implements PipeTransform {
    blacklistEnv: string[] = [EnvironmentsEnum.SH];

    transform(value: string): string {
        if (this.blacklistEnv.includes(environment.deploy_environment)) {
            return value;
        }
        return value + '*';
    }
}
