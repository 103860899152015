import { Component, Input } from '@angular/core';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'otb-numeric-field',
    templateUrl: './numeric-field.component.html',
    styleUrls: ['./numeric-field.component.scss'],
    standalone: false,
})
export class NumericFieldComponent extends FieldBase {
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;

    @Input() formNumber: number = 0;
}
