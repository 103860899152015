import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OtbComponent } from '../../otb.component';

@Component({
    selector: 'otb-appointment-booking-loading',
    templateUrl: './appointment-booking-loading.component.html',
    styleUrls: ['./appointment-booking-loading.component.scss'],
    standalone: false,
})
export class AppointmentBookingLoadingComponent {
    @Input() loading: boolean;
    @Input() message: string;
    @ViewChild(OtbComponent, { static: true }) otbRoot: ElementRef;
    constructor() {}
}
