<div [formGroup]="form" [attr.aria-labelledby]="fieldName + '-label'">
    <div class="form-check" *ngFor="let opt of formField.options; let i = index">
        <input
            [id]="fieldName + '_' + i"
            [type]="formField.type"
            class="form-check-input"
            [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
            [value]="opt"
            (change)="onCheckChange($event)"
            [checked]="isChecked(opt)"
            [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        />
        <label [for]="formField.name + '_' + i" class="form-check-label ms-2">
            {{ opt }}
        </label>
    </div>
    <otb-form-error-message
        [show]="!isValid && (isDirty || isTouched) && errors?.['required']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.checkbox_nicht_leer' | translate"
    >
    </otb-form-error-message>
</div>
