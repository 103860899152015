import { Injectable } from '@angular/core';
import { FormField } from '../../../../../api/models/form-field';

@Injectable({
    providedIn: 'root',
})
export class FormFieldDefaultValueMapperService {
    mapDefaultValue(field: FormField, formNumber: number | null, currentFormData: any): any {
        if (formNumber === null && currentFormData[field.name] !== undefined) {
            return currentFormData[field.name];
        } else if (
            typeof formNumber === 'number' &&
            currentFormData.hasOwnProperty('subForms') &&
            currentFormData['subForms'][formNumber][field.name] !== undefined
        ) {
            return currentFormData['subForms'][formNumber][field.name];
        }

        switch (field.type) {
            case 'date':
                if (field.default_date !== '1900-01-01T00:00:00+00:00') {
                    // default date from backend
                    const date = new Date(field.default_date);
                    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                    const noTimezoneDate = new Date(date.getTime() - userTimezoneOffset);
                    return {
                        day: noTimezoneDate.getUTCDate(),
                        month: noTimezoneDate.getUTCMonth() + 1,
                        year: noTimezoneDate.getUTCFullYear(),
                    };
                }
                return null;

            case 'checkbox':
                return field.default_values;
            case 'numeric':
            case 'numeric_select':
                return field.default_int_value;
            case 'select':
            case 'country_select':
                return field.default_value !== '' ? field.default_value : null;
            case 'file_upload':
            case 'text':
            case 'textarea':
            case 'email':
            case 'phone':
            default:
                return field.default_value;
        }
    }
}
