<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'bb-jm', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row mb-5 confirmation-list">
    @if (notMags()) {
        <div class="col-12">
            <h1
                *otbShowInEnv="['hzd-internal']"
                class="headline--large"
                [innerHTML]="'dynamische_frontend_texte.uebersicht.html_content.einfuehrung' | translate | safeStyle"
            ></h1>
            <h2
                *otbHideInEnv="['hzd-internal']"
                class="headline--large"
                [innerHTML]="'dynamische_frontend_texte.uebersicht.html_content.einfuehrung' | translate | safeStyle"
            ></h2>
        </div>
        <div class="col-12 col-sm-4 appointment-summary">
            <h2
                class="section-headline"
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_termin' | translate | safeStyle
                "
            ></h2>
            <p [innerText]="booking.agency?.name"></p>
            <p class="agency-address" [innerText]="booking.agency?.address"></p>
            <p class="service-name" [innerText]="booking.service?.name"></p>
            <h2
                class="section-headline"
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_datum' | translate | safeStyle
                "
            ></h2>
            <p class="booking-date">{{ booking.date | date: 'dd.MM.yyyy' }}</p>
            <p
                class="booking-time"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                        | translate
                        | replaceDateTime: booking.time
                        | async
                "
            ></p>
        </div>
        <div class="col-12 col-sm-8">
            <h2
                class="section-headline"
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_buerger_daten'
                        | translate
                        | safeStyle
                "
            ></h2>
            @for (fieldTitle of formFieldTitles; track fieldTitle; let i = $index) {
                <dl>
                    @if (formFieldValues[i]) {
                        <dt>{{ fieldTitle }}</dt>
                        <dd>{{ formFieldValues[i] }}</dd>
                    }
                </dl>
            }
        </div>
        @for (subformNumber of subForms; track subformNumber) {
            <div class="row mb-5">
                <div class="col-12 col-sm-8 offset-0 offset-sm-4">
                    <h3 class="section-headline">Persönliche Daten zusätzliche Person {{ subformNumber + 1 }}</h3>
                </div>
                <div class="col-12 col-sm-8 offset-0 offset-sm-4">
                    @for (fieldTitle of subFormFieldTitles; track fieldTitle; let i = $index) {
                        <dl>
                            @if (subFormFieldValues[subformNumber][i]) {
                                <dt>{{ fieldTitle }}</dt>
                                <dd>{{ subFormFieldValues[subformNumber][i] }}</dd>
                            }
                        </dl>
                    }
                </div>
            </div>
        }
    } @else {
        <div class="col-12">
            <h2
                class="headline--large"
                [innerHTML]="'dynamische_frontend_texte.uebersicht.html_content.einfuehrung' | translate | safeStyle"
            ></h2>
        </div>
        <div class="col-12">
            <h2
                class="section-headline"
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_termin' | translate | safeStyle
                "
            ></h2>
        </div>
        <div class="col-6">
            <p [innerText]="booking.agency?.name"></p>
            <p class="fw-bold" [innerText]="booking.agency?.address"></p>
            <p class="fw-bold" [innerText]="booking.service?.name"></p>
            @if (booking.service?.meeting_type !== 'offline') {
                <p class="fw-bold meeting-type">
                    <span
                        [innerHTML]="'dynamische_frontend_texte.terminart.zusammenfassung' | translate | safeStyle"
                    ></span>
                    <span>&nbsp;</span>
                    @if (booking.appointmentType === 'online') {
                        <span [innerHTML]="'dynamische_frontend_texte.terminart.online' | translate | safeStyle"></span>
                    } @else {
                        <span
                            [innerHTML]="'dynamische_frontend_texte.terminart.offline' | translate | safeStyle"
                        ></span>
                    }
                    <ng-template #offline>
                        <span
                            [innerHTML]="'dynamische_frontend_texte.terminart.offline' | translate | safeStyle"
                        ></span>
                    </ng-template>
                </p>
            }
        </div>
        <div class="col-6">
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_datum' | translate | safeStyle
                "
            ></p>
            <p class="fw-bold">{{ booking.date | date: 'dd.MM.yyyy' }}</p>
            <p
                class="fw-bold"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                        | translate
                        | replaceDateTime: booking.time
                        | async
                "
            ></p>
        </div>
        <div class="row mb-5">
            <div class="col-12">
                <h2
                    class="section-headline"
                    [innerHTML]="
                        'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_buerger_daten'
                            | translate
                            | safeStyle
                    "
                ></h2>
            </div>
            <div class="col-12">
                @for (fieldTitle of formFieldTitles; track fieldTitle; let i = $index) {
                    <dl>
                        <dt>{{ fieldTitle }}</dt>
                        <dd>{{ formFieldValues[i] }}</dd>
                    </dl>
                }
            </div>
        </div>
        @for (subformNumber of subForms; track subformNumber) {
            <div class="row mb-5">
                <div class="col-12">
                    <h2 class="section-headline">Persönliche Daten zusätzliche Person {{ subformNumber + 1 }}</h2>
                </div>
                <div class="col-12">
                    @for (fieldTitle of subFormFieldTitles; track fieldTitle; let i = $index) {
                        <dl>
                            <dt>{{ fieldTitle }}</dt>
                            <dd>{{ subFormFieldValues[subformNumber][i] }}</dd>
                        </dl>
                    }
                </div>
            </div>
        }
    }
</div>

@if (mustAcceptTerms) {
    <div class="row">
        <div class="col-12">
            <h2 class="section-headline border-0" id="agb-required">
                <span
                    [innerHTML]="
                        'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_datenschutz'
                            | translate
                            | safeStyle
                    "
                ></span>
                <span
                    [innerHTML]="
                        'dynamische_frontend_texte.uebersicht.html_content.required_mark' | translate | safeStyle
                    "
                ></span>
            </h2>
        </div>
        <div class="col-12" [class.invalid-feedback]="showTermsError" [class.d-block]="showTermsError">
            <div class="form-group">
                <div class="form-check">
                    <input
                        [formControl]="termsControl"
                        class="form-check-input"
                        type="checkbox"
                        id="agb"
                        aria-describedby="agb-required agb-error"
                    />
                    <label
                        for="agb"
                        id="agb-label"
                        class="form-check-label"
                        [innerHTML]="
                            'dynamische_frontend_texte.uebersicht.html_content.datenschutz' | translate | safeStyle
                        "
                    >
                    </label>
                </div>
                <otb-form-error-message
                    id="agb-error"
                    [show]="showTermsError"
                    [text]="'dynamische_frontend_validatoren.uebersicht.datenschutz' | translate"
                >
                </otb-form-error-message>
            </div>
        </div>
        <div class="col-12" *otbShowInEnv="['mags-sop']">
            <p
                class="agb-subline"
                [innerHTML]="
                    'dynamische_frontend_texte.uebersicht.html_content.datenschutz_hinweistext' | translate | safeStyle
                "
            ></p>
        </div>
    </div>
}

@if (mustAcceptEmailConsent) {
    <div class="row">
        <div class="col-12">
            <h2 class="section-headline border-0" id="consent-email-required">
                <span
                    [innerHTML]="
                        'dynamische_frontend_texte.uebersicht.html_content.ueberschrift_einverstaendniserklaerung'
                            | translate
                            | safeStyle
                    "
                ></span>
                <span
                    [innerHTML]="
                        'dynamische_frontend_texte.uebersicht.html_content.required_mark' | translate | safeStyle
                    "
                ></span>
            </h2>
        </div>
        <div class="col-12" [class.invalid-feedback]="showEmailConsentError" [class.d-block]="showEmailConsentError">
            <div class="form-group">
                <div class="form-check">
                    <input
                        [formControl]="emailConsentControl"
                        class="form-check-input"
                        type="checkbox"
                        id="consent-email"
                        aria-describedby="consent-email-required consent-email-error"
                    />
                    <label
                        for="consent-email"
                        id="consent-email-label"
                        class="form-check-label"
                        [innerHTML]="
                            'dynamische_frontend_texte.uebersicht.html_content.einverstaendniserklaerung'
                                | translate
                                | replace: { 'Bürger-E-Mail': email }
                        "
                    >
                    </label>
                </div>
                <otb-form-error-message
                    id="consent-email-error"
                    [show]="showEmailConsentError"
                    [text]="'dynamische_frontend_validatoren.uebersicht.einverstaendniserklaerung' | translate"
                >
                </otb-form-error-message>
            </div>
        </div>
    </div>
}

<div class="row mb-5">
    <div class="col-12 d-flex justify-content-between flex-wrap-reverse">
        <div>
            <button
                class="btn btn-secondary"
                [attr.aria-label]="'dynamische_frontend_texte.uebersicht.attribute_text.zurueck' | translate"
                [innerHTML]="'dynamische_frontend_texte.uebersicht.html_content.zurueck' | translate | safeStyle"
                accesskey="z"
                [title]="'dynamische_frontend_texte.uebersicht.attribute_text.zurueck' | translate"
                (click)="back()"
                tabindex="0"
            ></button>
        </div>
        <div>
            <button
                class="btn btn-success mb-sm-0 mb-3"
                [attr.aria-label]="'dynamische_frontend_texte.uebersicht.attribute_text.button_text' | translate"
                (click)="submit()"
                [innerHTML]="'dynamische_frontend_texte.uebersicht.html_content.button_text' | translate | safeStyle"
                [title]="'dynamische_frontend_texte.uebersicht.attribute_text.button_text' | translate"
                accesskey="w"
                btnPrimaryColorDirective
                [disabled]="formLoading"
                [attr.aria-disabled]="formLoading"
            ></button>
            <otb-appointment-booking-loading
                [loading]="formLoading"
                [message]="'Ihr Termin wird gebucht...'"
            ></otb-appointment-booking-loading>
        </div>
    </div>
</div>
