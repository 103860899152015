import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
/**
 * @Deprecated
 */
export class PreSelectionService {
    private agencyRef: string = '';
    private serviceRef: string = '';

    getAgencyRef(): string {
        return this.agencyRef;
    }

    getServiceRef(): string {
        return this.serviceRef;
    }
}
