import { Component, OnInit } from '@angular/core';
import { CancelService } from '../../services/appointment/cancel.service';
import { Appointment } from '../../api/models/appointment';
import { AppointmentResponse } from '../../api/models/appointment-response';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'otb-cancel',
    templateUrl: './cancel.component.html',
    styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
    uuid: string = '';
    code: string = '';

    appointmentCancellation: Appointment | null = null;
    success: boolean = false;
    error: boolean = false;

    constructor(
        private cancelService: CancelService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.uuid = params['uuid'];
            this.code = params['code'];

            this.cancelService.getAppointmentDetail(this.uuid).subscribe((response: AppointmentResponse) => {
                this.appointmentCancellation = response.data;
            });
        });
    }

    confirmCancel(): void {
        this.cancelService.cancelAppointment(this.uuid, this.code).subscribe(
            () => {
                this.success = true;
            },
            () => {
                this.error = true;
            }
        );
    }
}
