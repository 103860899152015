import { Component } from '@angular/core';
import { ApiConfiguration } from '../../../api/api-configuration';
import { StateNamesEnum } from '../../../enum/state-names-enum';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { StateService } from '../../../services/state/state.service';

@Component({
    selector: 'otb-bb-jm-header',
    templateUrl: './bb-jm-header.component.html',
    styleUrl: './bb-jm-header.component.scss',
    standalone: false,
})
export class BbJmHeaderComponent {
    rootUrl = '';

    constructor(
        private apiConfiguration: ApiConfiguration,
        private applicationSettingsService: ApplicationSettingsService,
        private sessionStorageService: SessionStorageService,
        private stateService: StateService,
    ) {
        this.rootUrl = this.apiConfiguration.rootUrl;
    }

    getRootUrl(): string {
        if (this.applicationSettingsService.serviceOnly) {
            return StateNamesEnum.SERVICE_ONLY;
        }

        return StateNamesEnum.COURT_AND_SERVICE;
    }

    clearStorage(): void {
        this.sessionStorageService.clearWorkflowAndBooking();
        this.stateService.init();
    }
}
