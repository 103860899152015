import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AvailableCourtTime } from '../../../api/models/available-court-time';
import { AvailableTime } from '../../../api/models/available-time';
import { Subscription } from 'rxjs';
import { CalendarDateFirstService } from '../../../services/calendar/date-first/calendar-date-first.service';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { Agency } from '../../../api/models/agency';
import { AgencyTimePickInterface } from './AgencyTimePickInterface';
import { AgencyService } from '../../../services/agency/agency.service';
import { AvailableDetailedAgencyTime } from './AvailableDetailedAgencyTime';
import { ServiceOnlyService } from '../../../services/calendar/service-only/service-only.service';

@Component({
    selector: 'otb-service-only-time-pick',
    templateUrl: './service-only-time-pick.component.html',
    styleUrls: ['./service-only-time-pick.component.scss'],
    standalone: false,
})
export class ServiceOnlyTimePickComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() availableDetailedAgencies: Array<AvailableDetailedAgencyTime> = [];
    @Output() timeSelect = new EventEmitter<AgencyTimePickInterface>();

    subs: Subscription = new Subscription();
    selectedTime: AvailableTime;
    selectedDate: Date = new Date();
    selectedAgencyName: string = '';
    selectedAgencyAdress: string = '';
    availableAgencies: Array<Agency> = [];
    availableAgenciesSelectionTransformed: Array<any> = [];
    availableAgenciesSelection: Array<AvailableDetailedAgencyTime> = [];
    timeSelected: boolean = false;
    applicationSettingsLoading: boolean = true;

    submitBtnActive: boolean = true;

    /**
     * ngModel which uses the ID
     */
    selectedAgency: number | undefined;

    constructor(
        private calendarDateFirstService: CalendarDateFirstService,
        private calendarService: CalendarService,
        private agencyService: AgencyService,
        private serviceOnlyService: ServiceOnlyService,
    ) {}

    ngOnInit(): void {
        this.submitBtnActive = true;
        this.availableAgenciesSelection = this.availableDetailedAgencies;
        this.selectedAgency = this.serviceOnlyService.selectedAgencyFilter?.id;
        const allAgencies$ = this.agencyService.allAgencies$.subscribe((agencies: Array<Agency>) => {
            if (agencies.length <= 0) {
                return;
            }
            this.availableAgencies = [
                {
                    id: 0,
                    name: 'Dienststelle auswählen',
                    external_reference: '',
                },
                ...agencies,
            ];

            this.availableAgenciesSelectionTransformed = this.transformAgencyDropdownLabel(this.availableAgencies);
        });

        // Reset filter when select new date
        const times$ = this.calendarService.availableTimes.subscribe((_times: Array<AvailableCourtTime>) => {
            this.availableAgenciesSelection = this.availableDetailedAgencies;

            // set preselected agency when go back to page
            const preSelectedAgency = this.serviceOnlyService.selectedAgencyFilter;
            if (preSelectedAgency) {
                this.filterAvailableAgencies(preSelectedAgency);
            }
        });

        this.subs.add(allAgencies$);
        this.subs.add(times$);
    }

    ngAfterViewInit(): void {
        this.subs.add(
            this.calendarService.selectedDate.subscribe((date) => {
                this.selectedDate = date;
            }),
        );
    }

    timeClick(time: AvailableTime, agency: AvailableDetailedAgencyTime, event: Event): void {
        event.preventDefault();
        this.selectedTime = time;
        this.selectedAgencyAdress = agency.address;
        this.selectedAgencyName = agency.name;
        this.timeSelected = true;
    }

    submit(): void {
        if (!this.submitBtnActive) {
            return;
        }

        this.submitBtnActive = false;
        this.serviceOnlyService.selectedAgencyFilter = null;
        this.selectedAgency = undefined;
        this.timeSelect.emit({
            time: this.selectedTime,
            agencyName: this.selectedAgencyName,
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    buildSelectedValuesText(): string {
        let text = '';

        const textArr = [];
        if (this.selectedDate) {
            textArr.push(this.formatDate(this.selectedDate));
        }
        if (this.selectedTime?.time) {
            textArr.push(this.selectedTime.time);
        }
        if (this.selectedAgencyAdress) {
            textArr.push(this.selectedAgencyAdress);
        }
        text = textArr.join(', ');

        return text;
    }

    formatDate(date: Date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    filterAvailableAgencies(agency?: Agency): void {
        if (!agency || agency.id === 0) {
            this.availableAgenciesSelection = this.availableDetailedAgencies;
            this.serviceOnlyService.selectedAgencyFilter = null;
            this.selectedAgency = undefined;
            return;
        }

        this.selectedAgency = agency.id;
        this.serviceOnlyService.selectedAgencyFilter = agency;
        this.availableAgenciesSelection = this.availableDetailedAgencies.filter(
            (availAgency: AvailableDetailedAgencyTime) => {
                return availAgency.agencyId === agency.id;
            },
        );
    }

    transformAgencyDropdownLabel(availableAgenciesSelection: Array<Agency>): Array<any> {
        return availableAgenciesSelection.map((agency: Agency) => {
            let name = agency.name;
            if (agency.address) {
                name = `${agency.address}, ${agency.name}`;
            }
            return {
                name: name,
                id: agency.id,
                external_reference: agency.external_reference,
            };
        });
    }
}
