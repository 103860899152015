export enum EnvironmentsEnum {
    DEFAULT = 'default',
    JUSTICE = 'justice',
    MAGS_SOP = 'mags-sop',
    SH = 'sh',
    EDUCATION_CHECK = 'education-check',
    FINANCE = 'finance',
    HMDJ = 'hmdj',
    HMDIS_INTERNAL = 'hzd-internal',
    BB_JM = 'bb-jm',
}
