import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'otb-top-information-container',
    templateUrl: './top-information-container.component.html',
    styleUrls: ['./top-information-container.component.scss', './top-information-container.component.hmdj.scss'],
    standalone: false,
})
export class TopInformationContainerComponent implements OnInit, OnDestroy {
    isLandingPage: boolean = true;
    isConfirmationPage: boolean = true;
    isCancelPage: boolean = true;
    subs: Subscription = new Subscription();
    constructor(private route: Router) {}

    ngOnInit(): void {
        const routeEvents$ = this.route.events.subscribe((el: Event): void => {
            if (!(el instanceof NavigationEnd)) {
                return;
            }

            this.isLandingPage = el.urlAfterRedirects.indexOf('/court_and_service') > -1;
            this.isConfirmationPage = el.urlAfterRedirects.indexOf('/confirmation') > -1;
            this.isCancelPage = el.urlAfterRedirects.indexOf('/cancel') > -1;
        });

        this.subs.add(routeEvents$);
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
