import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from '../../api/services/languages.service';
import { LanguagesResponse } from '../../api/models/languages-response';
import { BehaviorSubject } from 'rxjs';
import { WebcomponentInterfaceService } from '../webcomponent-interface/webcomponent-interface.service';
import { LanguageISOType } from '../../types/LanguageISOType';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly STORAGE_LANG_KEY: string = 'otb-locale';
    private activeLanguage: string = '';
    private languageFallback: string = 'DE';
    allLanguages: BehaviorSubject<Array<{ id: string; name: string }>> = new BehaviorSubject([
        { id: '', name: 'Sprache' },
    ]);

    constructor(
        private transService: TranslateService,
        private languagesService: LanguagesService,
        private webcomponentInterfaceService: WebcomponentInterfaceService,
    ) {
        this.transService.currentLang = this.activeLanguage;
    }

    getActiveLanguage(): string {
        // set lagnauge from storage if set
        this.setActiveLanguageFromStorage();

        // set language from interface
        if (this.webcomponentInterfaceService.language && !this.activeLanguage) {
            this.activeLanguage = this.webcomponentInterfaceService.language;
        }

        // set language from api if storage is not set
        if (!this.activeLanguage) {
            this.allLanguages.subscribe((languages) => {
                this.activeLanguage = languages[0].id;
            });
        }
        return this.activeLanguage;
    }

    setActiveLanguage(langCode: string): void {
        if (langCode) {
            langCode = langCode.substring(0, 2).toLowerCase();
        }
        this.activeLanguage = langCode;
        sessionStorage.setItem(this.STORAGE_LANG_KEY, langCode);
    }

    setActiveLanguageFromStorage(): void {
        const storageLang = this.getLanguageCodeFromStorage();
        if (storageLang) {
            this.activeLanguage = storageLang;
        }
    }

    getAllLanguagesFromAPI(): void {
        let langIso = this.getLanguageCodeFromStorage()?.toLowerCase();
        if (!langIso) {
            langIso = this.languageFallback.toLowerCase();
        }
        this.languagesService
            .getApiPublicLanguages({
                _locale: langIso.toLowerCase() as LanguageISOType,
            })
            .subscribe((response: LanguagesResponse) => {
                const langMapped = response.data.map((language) => {
                    return { id: language.iso, name: language.name };
                });

                if (langMapped.length > 0) {
                    this.allLanguages.next(langMapped);
                }
            });
    }

    changeLanguageAndReload(lang: { id: string; name: string }): void {
        sessionStorage.setItem(this.STORAGE_LANG_KEY, lang.id);
        location.reload();
    }

    hasLanguageInStorage(): boolean {
        const storageLangCode = this.getLanguageCodeFromStorage();

        return !!storageLangCode;
    }

    initLanguage(languageCode?: string | null): void {
        if (!this.hasLanguageInStorage()) {
            const lang: string = languageCode && languageCode !== '' ? languageCode : this.languageFallback;
            this.setActiveLanguage(lang);
        } else {
            this.setActiveLanguageFromStorage();
        }
    }

    getLanguageCodeFromStorage(): string {
        return <string>sessionStorage.getItem(this.STORAGE_LANG_KEY)?.toLowerCase();
    }
}
