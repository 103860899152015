import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Agency } from '../../api/models/agency';
import { Service } from '../../api/models/service';
import { AgenciesService } from '../../api/services/agencies.service';
import { ServicesService } from '../../api/services/services.service';
import { PreSelectionService } from '../../services/pre-chooser/pre-selection.service';
import { WarningService } from '../../services/warning/warning.service';
import { StateService } from '../../services/state/state.service';
import { BookingService } from '../../services/booking/booking.service';
import { LanguageISOType } from '../../types/LanguageISOType';
import { ServicesResponse } from '../../api/models/services-response';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';

@Component({
    selector: 'otb-service-only',
    templateUrl: './service-only.component.html',
    styleUrls: ['./service-only.component.scss']
})
export class ServiceOnlyComponent implements OnInit, OnDestroy {
    serviceFormGroup: UntypedFormGroup;
    subs: Subscription = new Subscription();

    services: Array<Service>;
    servicesSelect: Array<Service> = [
        {
            id: 0,
            name: 'Dienstleistung auswählen',
            notice: '',
            external_reference: ''
        }
    ];

    selectedService: Service | undefined;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private formBuilder: UntypedFormBuilder,
        private agencyService: AgenciesService,
        private serviceService: ServicesService,
        private preSelectionService: PreSelectionService,
        private warningService: WarningService,
        private stateService: StateService,
        private bookingService: BookingService,
        private appSettingsService: ApplicationSettingsService
    ) {}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.intialize();
    }
    private intialize(): void {
        this.bookingService.clearBooking();
        this.initializeForm();
        const clearBookingEvent$ = this.bookingService.clearBookingEvent.subscribe(() => {
            this.initializeForm();
        });
        this.subs.add(clearBookingEvent$);
        this.appSettingsService.serviceOnly = true;
        this.getAllServices();
    }
    getAllServices(): void {
        const getAllServices = this.serviceService
            .getApiPublicAllServices({
                _locale: this.locale.toLowerCase() as LanguageISOType
            })
            .subscribe((servicesByApi: ServicesResponse) => {
                this.services = servicesByApi.data;
                this.servicesSelect = servicesByApi.data
                    ? [...servicesByApi.data]
                    : [
                          {
                              id: 0,
                              name: 'Dienstleistung auswählen',
                              notice: '',
                              external_reference: ''
                          }
                      ];
                this.prefillServiceByApplicationSettings(this.servicesSelect);
            });
        this.subs.add(getAllServices);
    }

    private initializeForm(): void {
        const formControlsConfig = {
            service: [0, [Validators.required, Validators.min(1)]]
        };

        this.serviceFormGroup = this.formBuilder.group(formControlsConfig);
        if (this.bookingService.booking.agency?.id !== 0) this.retrieveServices(this.bookingService.booking?.agency);
    }

    private retrieveServices(agency: Agency | undefined): void {
        if (!agency) {
            return;
        }
        const getServices$ = this.serviceService
            .getApiPublicServices({
                agency_id: agency.id.toString(),
                _locale: this.locale.toLowerCase() as LanguageISOType
            })
            .subscribe((servicesByApi: ServicesResponse) => {
                this.services = servicesByApi.data;
                this.servicesSelect = servicesByApi.data
                    ? [...servicesByApi.data]
                    : [
                          {
                              id: 0,
                              name: 'Dienstleistung auswählen',
                              notice: '',
                              external_reference: ''
                          }
                      ];
            });
        this.subs.add(getServices$);
    }

    onServiceSelect(selectedService: Service): void {
        this.selectedService = selectedService;
        this.bookingService.booking.service = selectedService;
    }

    private prefillServiceByApplicationSettings(services: Array<Service>): void {
        const preSelectedService = services.find((service) => service.default);
        if (typeof preSelectedService === 'object') {
            this.prefillService(preSelectedService);
        }
    }

    private prefillService(preSelectedService: Service): void {
        this.selectedService = preSelectedService;
        this.bookingService.booking.service = this.selectedService;
        this.serviceFormGroup.controls['service'].patchValue(preSelectedService.id);
        //this.agencyForm.controls["service"].disable(); // //todo: if JM is ok remove... if not make conditionally
    }

    onSubmit(): void {
        if (this.serviceFormGroup.valid) {
            const sub = this.stateService
                .updateMachineNoAgency(this.bookingService.booking.service?.id.toString())
                .subscribe((isUpdated) => {
                    if (isUpdated) {
                        this.stateService.next();
                    }
                });

            this.subs.add(sub);
        } else {
            this.formService.markAsDirty();
        }
    }

    get formService(): AbstractControl {
        return this.serviceFormGroup.get('service') as AbstractControl;
    }
}
