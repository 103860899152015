/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AppointmentResponse } from '../models/appointment-response';
import { deleteApiPublicAppointmentDeleteReservation } from '../fn/appointment/delete-api-public-appointment-delete-reservation';
import { DeleteApiPublicAppointmentDeleteReservation$Params } from '../fn/appointment/delete-api-public-appointment-delete-reservation';
import { getApiPublicGetAppointment } from '../fn/appointment/get-api-public-get-appointment';
import { GetApiPublicGetAppointment$Params } from '../fn/appointment/get-api-public-get-appointment';
import { postApiPublicAppointment } from '../fn/appointment/post-api-public-appointment';
import { PostApiPublicAppointment$Params } from '../fn/appointment/post-api-public-appointment';
import { postApiPublicAppointmentCancellation } from '../fn/appointment/post-api-public-appointment-cancellation';
import { PostApiPublicAppointmentCancellation$Params } from '../fn/appointment/post-api-public-appointment-cancellation';
import { postApiPublicAppointmentConfirmation } from '../fn/appointment/post-api-public-appointment-confirmation';
import { PostApiPublicAppointmentConfirmation$Params } from '../fn/appointment/post-api-public-appointment-confirmation';
import { postApiPublicAppointmentReservation } from '../fn/appointment/post-api-public-appointment-reservation';
import { PostApiPublicAppointmentReservation$Params } from '../fn/appointment/post-api-public-appointment-reservation';
import { postApiPublicAppointmentUpload } from '../fn/appointment/post-api-public-appointment-upload';
import { PostApiPublicAppointmentUpload$Params } from '../fn/appointment/post-api-public-appointment-upload';
import { ReservationResponse } from '../models/reservation-response';


/**
 * Appointment endpoints
 */
@Injectable({ providedIn: 'root' })
export class AppointmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicGetAppointment()` */
  static readonly GetApiPublicGetAppointmentPath = '/api/public/{_locale}/appointments/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicGetAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicGetAppointment$Response(params: GetApiPublicGetAppointment$Params, context?: HttpContext): Observable<StrictHttpResponse<AppointmentResponse>> {
    return getApiPublicGetAppointment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicGetAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicGetAppointment(params: GetApiPublicGetAppointment$Params, context?: HttpContext): Observable<AppointmentResponse> {
    return this.getApiPublicGetAppointment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AppointmentResponse>): AppointmentResponse => r.body)
    );
  }

  /** Path part for operation `postApiPublicAppointment()` */
  static readonly PostApiPublicAppointmentPath = '/api/public/{_locale}/appointments/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApiPublicAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointment$Response(params: PostApiPublicAppointment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postApiPublicAppointment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postApiPublicAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointment(params: PostApiPublicAppointment$Params, context?: HttpContext): Observable<void> {
    return this.postApiPublicAppointment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postApiPublicAppointmentCancellation()` */
  static readonly PostApiPublicAppointmentCancellationPath = '/api/public/{_locale}/appointment/{uuid}/cancellation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApiPublicAppointmentCancellation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointmentCancellation$Response(params: PostApiPublicAppointmentCancellation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postApiPublicAppointmentCancellation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postApiPublicAppointmentCancellation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointmentCancellation(params: PostApiPublicAppointmentCancellation$Params, context?: HttpContext): Observable<void> {
    return this.postApiPublicAppointmentCancellation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postApiPublicAppointmentConfirmation()` */
  static readonly PostApiPublicAppointmentConfirmationPath = '/api/public/{_locale}/appointment/{uuid}/confirmation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApiPublicAppointmentConfirmation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointmentConfirmation$Response(params: PostApiPublicAppointmentConfirmation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postApiPublicAppointmentConfirmation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postApiPublicAppointmentConfirmation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApiPublicAppointmentConfirmation(params: PostApiPublicAppointmentConfirmation$Params, context?: HttpContext): Observable<void> {
    return this.postApiPublicAppointmentConfirmation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postApiPublicAppointmentUpload()` */
  static readonly PostApiPublicAppointmentUploadPath = '/api/public/{_locale}/appointment/{uuid}/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApiPublicAppointmentUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postApiPublicAppointmentUpload$Response(params: PostApiPublicAppointmentUpload$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postApiPublicAppointmentUpload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postApiPublicAppointmentUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postApiPublicAppointmentUpload(params: PostApiPublicAppointmentUpload$Params, context?: HttpContext): Observable<void> {
    return this.postApiPublicAppointmentUpload$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postApiPublicAppointmentReservation()` */
  static readonly PostApiPublicAppointmentReservationPath = '/api/public/{_locale}/appointments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApiPublicAppointmentReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  postApiPublicAppointmentReservation$Response(params: PostApiPublicAppointmentReservation$Params, context?: HttpContext): Observable<StrictHttpResponse<ReservationResponse>> {
    return postApiPublicAppointmentReservation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postApiPublicAppointmentReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postApiPublicAppointmentReservation(params: PostApiPublicAppointmentReservation$Params, context?: HttpContext): Observable<ReservationResponse> {
    return this.postApiPublicAppointmentReservation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReservationResponse>): ReservationResponse => r.body)
    );
  }

  /** Path part for operation `deleteApiPublicAppointmentDeleteReservation()` */
  static readonly DeleteApiPublicAppointmentDeleteReservationPath = '/api/public/{_locale}/appointment/{uuid}/reservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiPublicAppointmentDeleteReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPublicAppointmentDeleteReservation$Response(params: DeleteApiPublicAppointmentDeleteReservation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteApiPublicAppointmentDeleteReservation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiPublicAppointmentDeleteReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPublicAppointmentDeleteReservation(params: DeleteApiPublicAppointmentDeleteReservation$Params, context?: HttpContext): Observable<void> {
    return this.deleteApiPublicAppointmentDeleteReservation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
