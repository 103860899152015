import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'replaceDateTime',
    standalone: false,
})
export class ReplaceDateTimePipe implements PipeTransform {
    constructor(private translationService: TranslateService) {}

    transform(text: string, placeholder?: Date | string): Observable<string> {
        placeholder = placeholder || '';

        const weekdayRegex = new RegExp(/[%{]+weekday[%}]+/gm);
        const dateRegex = new RegExp(/[%{]+date[%}]+/gm);
        const timeRegex = new RegExp(/[%{]+time[%}]+/gm);
        const timeslotRegex = new RegExp(/[%{]+timeSlot[%}]+/gm);

        if (timeslotRegex.test(text)) {
            return this.replaceTimeSlot(text, placeholder as string);
        }

        if (placeholder instanceof Date) {
            return this.replaceDateTimePlaceholders(text, placeholder, weekdayRegex, dateRegex, timeRegex);
        }

        return of(text);
    }

    private replaceDateTimePlaceholders(
        text: string,
        date: Date,
        weekdayRegex: RegExp,
        dateRegex: RegExp,
        timeRegex: RegExp,
    ): Observable<string> {
        if (weekdayRegex.test(text)) {
            return this.getTranslatedWeekday(date).pipe(
                map((translatedWeekday) => {
                    text = this.replaceWeekday(text, translatedWeekday, weekdayRegex);
                    text = this.replaceDate(text, date, dateRegex);
                    text = this.replaceTime(text, date, timeRegex);
                    return text;
                }),
            );
        }
        text = this.replaceDate(text, date, dateRegex);
        text = this.replaceTime(text, date, timeRegex);
        return of(text);
    }

    private replaceTimeSlot(text: string, timeSlot: string): Observable<string> {
        return of(text.replace(/[%{]+timeSlot[%}]+/gm, timeSlot));
    }

    private replaceWeekday(text: string, translatedWeekday: string, weekdayRegex: RegExp): string {
        return text.replace(weekdayRegex, translatedWeekday);
    }

    private replaceDate(text: string, date: Date, dateRegex: RegExp): string {
        return text.replace(dateRegex, moment(date).format('DD.MM.YYYY'));
    }

    private replaceTime(text: string, date: Date, timeRegex: RegExp): string {
        return text.replace(timeRegex, moment(date).format('HH:mm'));
    }

    private getTranslatedWeekday(date: Date): Observable<string> {
        const dayOfWeek = date.toLocaleString('en-EN', { weekday: 'long' });
        const translationKey = 'dynamische_frontend_texte.calendar.weekdays_long.' + dayOfWeek.toLowerCase();
        return this.translationService.get(translationKey);
    }
}
