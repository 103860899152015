<div [formGroup]="form">
    <input
        [formControlName]="fieldName"
        [id]="fieldName"
        [placeholder]="formField.placeholder"
        (focus)="d.open()"
        [autoClose]="'inside'"
        [minDate]="minDateNgb"
        [maxDate]="maxDateNgb"
        class="form-control"
        restoreFocus="false"
        [attr.aria-labelledby]="fieldName + '-label'"
        [attr.aria-describedby]="
            fieldName +
            '-error-required ' +
            fieldName +
            '-error-mindate ' +
            fieldName +
            '-error-maxdate ' +
            fieldName +
            '-error-date_range'
        "
        ngbDatepicker
        #d="ngbDatepicker"
        i18n
        [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
    />
    <otb-form-error-message
        [id]="fieldName + '-error-required'"
        [show]="!isValid && (isDirty || isTouched) && errors?.['required'] !== undefined"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.datum_nicht_leer' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-mindate'"
        class="minDate"
        [show]="!isValid && (isDirty || isTouched) && errors?.['mindate'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.min_date'
                | translate
                    : {
                          compared_value: errors?.['mindate']?.minDate.toLocaleDateString('de-DE'),
                      }
        "
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-maxdate'"
        class="maxDate"
        [show]="!isValid && (isDirty || isTouched) && errors?.['maxdate'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.max_date'
                | translate
                    : {
                          compared_value: errors?.['maxdate']?.maxDate.toLocaleDateString('de-DE'),
                      }
        "
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-date_range'"
        class="dateRange"
        [show]="!isValid && (isDirty || isTouched) && errors?.['date_range'] !== undefined"
        [text]="
            'dynamische_frontend_validatoren.persönliche_daten.nicht_in_datums_bereich'
                | translate
                    : {
                          min: errors?.['date_range']?.['minDate'].toLocaleDateString('de-DE'),
                          max: errors?.['date_range']?.['maxDate'].toLocaleDateString('de-DE'),
                      }
        "
    >
    </otb-form-error-message>
</div>
