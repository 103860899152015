/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getApiPublicLanguages } from '../fn/languages/get-api-public-languages';
import { GetApiPublicLanguages$Params } from '../fn/languages/get-api-public-languages';
import { LanguagesResponse } from '../models/languages-response';

@Injectable({ providedIn: 'root' })
export class LanguagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApiPublicLanguages()` */
  static readonly GetApiPublicLanguagesPath = '/api/public/{_locale}/languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPublicLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicLanguages$Response(params: GetApiPublicLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<LanguagesResponse>> {
    return getApiPublicLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiPublicLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPublicLanguages(params: GetApiPublicLanguages$Params, context?: HttpContext): Observable<LanguagesResponse> {
    return this.getApiPublicLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<LanguagesResponse>): LanguagesResponse => r.body)
    );
  }

}
