import { Strategy } from './strategy';
import { Assignment } from '../../../api/models/assignment';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { DinSpec91379Service } from '../../../pages/personal-data/dynamic-form/form-group-factory-service/form-field-validator-mapper-service/custom-validators/dinSpec91379/din-spec-91379.service';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { StrategySessionStorageParserService } from './strategy-session-storage-parser.service';

export class StrategyList implements Strategy {
    strategyName: string = 'list';
    notice: string = '';
    chosenOption: string | undefined = undefined;
    options: Array<string> = [];
    listResultFormField: string;

    setDataFromAssignment(assignment: Assignment): void {
        if (assignment.list_settings) {
            this.options = assignment.list_settings.list_entries;
            this.notice = assignment.list_settings.notice;
            this.listResultFormField = assignment.list_settings.form_field;
            this.chosenOption = StrategySessionStorageParserService.getListChosenOption() ?? undefined;
        }
    }

    buildForm(personalDataForm: PersonalDataForm, dinSpec91379Service: DinSpec91379Service): UntypedFormGroup {
        const validators = [Validators.required];
        const personalDataSelectedField = personalDataForm?.form_fields.find(
            (field) => field.name === this.listResultFormField,
        );

        //todo: we should validate that with the list creation as well maybe?
        if (personalDataSelectedField && personalDataSelectedField.is_din_spec91379_compliant) {
            validators.push(dinSpec91379Service.dinSpec91379Validator());
        }

        return new FormGroup<any>({
            //todo: this required does not work because its not empty
            list: new FormControl(this.chosenOption, Validators.compose(validators)),
        });
    }

    markDirty(form: UntypedFormGroup): void {
        form.get('list')?.markAsDirty();
    }
}
