import { Strategy } from './strategy';
import { Assignment } from '../../../api/models/assignment';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FormField } from '../../../api/models/form-field';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { DinSpec91379Service } from '../../../pages/personal-data/dynamic-form/form-group-factory-service/form-field-validator-mapper-service/custom-validators/dinSpec91379/din-spec-91379.service';
import { StrategySessionStorageParserService } from './strategy-session-storage-parser.service';

export class StrategyName implements Strategy {
    strategyName: string = 'name';
    notice: string = '';
    lastName: string;
    lastNameFormField: string;
    nameAddition: string;

    setDataFromAssignment(assignment: Assignment): void {
        if (assignment.name_settings) {
            this.lastName = StrategySessionStorageParserService.getLastName() ?? '';
            this.lastNameFormField = assignment.name_settings.form_field;
            this.notice = assignment.name_settings.notice;
        }
    }

    buildForm(personalDataForm: PersonalDataForm, dinSpec91379Service: DinSpec91379Service): UntypedFormGroup {
        const validators = [Validators.required];

        const personalDataSelectedField = personalDataForm?.form_fields.find(
            (field: FormField) => field.name === this.lastNameFormField
        );

        if (personalDataSelectedField && personalDataSelectedField.is_din_spec91379_compliant) {
            validators.push(dinSpec91379Service.dinSpec91379Validator());
        }

        return new FormGroup({
            name: new FormControl(this.lastName, Validators.compose(validators)),
            nameAddition: new FormControl(this.nameAddition)
        });
    }

    markDirty(form: UntypedFormGroup): void {
        form.get('name')?.markAsDirty();
    }
}
