import { Component, Input } from '@angular/core';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'otb-phone-field',
    templateUrl: './phone-field.component.html',
    styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent extends FieldBase {
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;
    @Input() formNumber: number = 0;
}
