import { TranslateService } from '@ngx-translate/core';

export class DynamicLocaleId extends String {
    constructor(protected service: TranslateService) {
        super('');
    }

    override toString(): string {
        // set the language from storage if set
        // the language is set in the language service
        const lang = <string>sessionStorage.getItem('otb-locale');
        if (lang) {
            this.service.currentLang = lang.toLowerCase();
        }

        return this.service.currentLang;
    }
}
