import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DesignFrontendSettings } from '../../api/models/design-frontend-settings';

@Injectable({
    providedIn: 'root',
})
export class ApplicationSettingsService {
    serviceOnly: boolean = false;
    onlineService: boolean = false;
    showEasyLanguagePageSubject = new BehaviorSubject<boolean>(false);
    showUploadedLogoSubject = new BehaviorSubject<boolean>(false);
    showCookieNoticeSubject = new BehaviorSubject<boolean>(false);
    public readonly showCookieNotice$ = this.showCookieNoticeSubject.asObservable();
    markFieldAsRequired = new BehaviorSubject<boolean>(false);
    designFrontendSettingsSubject = new BehaviorSubject<DesignFrontendSettings>({
        color_header1: '',
        color_header2: '',
        color_footer: '',
        color_progress_bar: '',
        color_primary_button: '',
    });
    applicationSettingsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    confirmButton: boolean = false;
    errorLoadingApplicationSettings: WritableSignal<boolean> = signal(false);
    constructor() {}

    toJson(): object {
        return {
            serviceOnly: this.serviceOnly,
            onlineService: this.onlineService,
            showEasyLanguagePage: this.showEasyLanguagePageSubject.value,
            showUploadedLogo: this.showUploadedLogoSubject.value,
            showCookieNotice: this.showCookieNoticeSubject.value,
            markFieldAsRequired: this.markFieldAsRequired.value,
            designFrontendSettings: this.designFrontendSettingsSubject.value,
            confirmButton: this.confirmButton,
        };
    }

    parseFromJson(json: {
        serviceOnly?: boolean;
        onlineService?: boolean;
        showEasyLanguagePage?: boolean;
        showUploadedLogo?: boolean;
        showCookieNotice?: boolean;
        markFieldAsRequired?: boolean;
        designFrontendSettings?: DesignFrontendSettings;
        confirmButton?: boolean;
    }): any {
        const {
            serviceOnly,
            onlineService,
            showEasyLanguagePage,
            showUploadedLogo,
            showCookieNotice,
            markFieldAsRequired,
            designFrontendSettings,
            confirmButton,
        } = json;

        if (serviceOnly !== undefined) {
            this.serviceOnly = serviceOnly;
        }

        if (onlineService !== undefined) {
            this.onlineService = onlineService;
        }

        if (showEasyLanguagePage !== undefined) {
            this.showEasyLanguagePageSubject.next(showEasyLanguagePage);
        }

        if (showUploadedLogo !== undefined) {
            this.showUploadedLogoSubject.next(showUploadedLogo);
        }

        if (showCookieNotice !== undefined) {
            this.showCookieNoticeSubject.next(showCookieNotice);
        }

        if (markFieldAsRequired !== undefined) {
            this.markFieldAsRequired.next(markFieldAsRequired);
        }

        if (designFrontendSettings !== undefined) {
            this.designFrontendSettingsSubject.next(designFrontendSettings);
        }

        if (confirmButton !== undefined) {
            this.confirmButton = confirmButton;
        }

        return json;
    }
}
