import { Injectable } from '@angular/core';
import { Strategy } from './strategy/strategy';
import { Assignment } from '../../api/models/assignment';
import { StrategyFactory } from './strategy/strategy-factory';

@Injectable({
    providedIn: 'root'
})
export class AssignmentStrategyService {
    createStrategyFromAssignment(assignment: Assignment): Strategy {
        const strategy: Strategy = StrategyFactory.createStrategy(assignment.name);
        strategy.setDataFromAssignment(assignment);

        return strategy;
    }

    createStrategyFromSessionStorageObject(strategy: any): Strategy {
        if (!strategy.hasOwnProperty('strategyName')) {
            throw new Error('Strategy object is not valid');
        }

        const createdStrategy = StrategyFactory.createStrategy(strategy.strategyName.toLowerCase());
        Object.assign(createdStrategy, strategy);

        return createdStrategy;
    }
}
