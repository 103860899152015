import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WarningService {
    servicePreselectionWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    agencyPreselectionWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    showAgencyPreselectionWarning(): void {
        this.agencyPreselectionWarning.next(true);
    }

    hideAgencyPreselectionWarning(): void {
        this.agencyPreselectionWarning.next(false);
    }

    showServicePreselectionWarning(): void {
        this.servicePreselectionWarning.next(true);
    }

    hideServicePreselectionWarning(): void {
        this.servicePreselectionWarning.next(false);
    }
}
