<p>
    <a
        [attr.aria-label]="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
        (click)="clearStorage()"
        [routerLink]="getRootUrl()"
    >
        <span
            [innerHTML]="'dynamische_frontend_texte.header.html_content.top_ueberschrift' | translate | safeStyle"
        ></span>
    </a>
</p>
