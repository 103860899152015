<div *ngIf="servicePreselectionWarning || agencyPreselectionWarning" class="animated fadeIn">
    <div class="row" *ngIf="servicePreselectionWarning">
        <div class="col-lg-12">
            <div class="alert-box">
                <div
                    class="alert alert-warning"
                    role="alert"
                    [innerHTML]="'dynamische_frontend_texte.landing.unbekannte_leistung' | translate | safeStyle"
                ></div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="agencyPreselectionWarning">
        <div class="col-lg-12">
            <div class="alert-box">
                <div
                    class="alert alert-warning"
                    role="alert"
                    [innerHTML]="'dynamische_frontend_texte.landing.unbekannte_dienststelle' | translate | safeStyle"
                ></div>
            </div>
        </div>
    </div>
</div>
