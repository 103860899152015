import { Component, WritableSignal } from '@angular/core';
import { StateService } from '../../services/state/state.service';
import '@fortawesome/free-solid-svg-icons';
import { BookingService } from '../../services/booking/booking.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { Booking } from '../../model/booking';
import { AutomaticExtension } from '../../api/models/automatic-extension';

@Component({
    selector: 'otb-participant-count',
    templateUrl: './participant-count.component.html',
    styleUrls: ['./participant-count.component.scss', './participant-count.component.mags-sop.scss'],
    standalone: false,
})
export class ParticipantCountComponent {
    value: number = 1;
    options: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    aeSignal: WritableSignal<AutomaticExtension>;

    constructor(
        private stateService: StateService,
        private bookingService: BookingService,
        private sessStorageService: SessionStorageService,
    ) {
        const booking = this.bookingService.booking ?? this.sessStorageService.parseBookingFromStorage();
        this.aeSignal = booking.aESignal;

        if (booking?.participantsCount) {
            this.value = booking?.participantsCount;
            const participantCountField = booking?.personalDataForm?.form_fields.find(
                (field) => field.name === this.bookingService.booking.automaticExtension!['field_name'],
            );
            if (participantCountField) {
                this.options = participantCountField.options;
            }
        }
    }

    next(): void {
        this.bookingService.booking.participantsCount = Number(this.value);
        this.writeToSessionStorage();

        this.stateService.next();
    }

    back(): void {
        this.stateService.back();
    }

    private writeToSessionStorage(): void {
        const sessData = this.sessStorageService.getPersonalData();

        const participantField = this.bookingService.booking.automaticExtension!['field_name'];
        if (sessData && participantField) {
            if (sessData?.[participantField]) {
                sessData[participantField] = this.value;
                this.sessStorageService.persistPersonalData(JSON.stringify(sessData));
            }
        }
    }
}
