import { Component, OnInit } from '@angular/core';
import { WarningService } from '../../services/warning/warning.service';

@Component({
    selector: 'otb-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss'],
    standalone: false,
})
export class WarningComponent implements OnInit {
    servicePreselectionWarning = false;
    agencyPreselectionWarning = false;

    constructor(private warningService: WarningService) {}

    ngOnInit(): void {
        this.warningService.servicePreselectionWarning.subscribe((hasPreSelectionWarning) => {
            this.servicePreselectionWarning = hasPreSelectionWarning;
        });
        this.warningService.agencyPreselectionWarning.subscribe((hasPreSelectionWarning) => {
            this.agencyPreselectionWarning = hasPreSelectionWarning;
        });
    }
}
