import { Injectable } from '@angular/core';
import { PikadayComponent } from '../../components/pikaday/pikaday';
@Injectable({
    providedIn: 'root',
})
export class CalendarFactoryService {
    constructor() {}

    buildCalendar(): PikadayComponent {
        return new PikadayComponent();
    }
}
