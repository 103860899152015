import { Strategy } from './strategy';
import { StrategyName } from './strategy-name';
import { StrategyFileNumber } from './strategy-file-number';
import { StrategyList } from './strategy-list';
import { StrategyRandom } from './strategy-random';
import { StrategyUnknown } from './strategy-unknown';

export class StrategyFactory {
    static createStrategy(assignmentName: string): Strategy {
        switch (assignmentName.toLowerCase()) {
            case 'name':
                return new StrategyName();
            case 'aktenzeichen':
                return new StrategyFileNumber();
            case 'auswahlliste':
            case 'list':
                return new StrategyList();
            case 'random':
                return new StrategyRandom();

            default:
                console.warn('Strategy not found: ' + assignmentName);

                return new StrategyUnknown();
        }
    }
}
