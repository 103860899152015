import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

@Injectable({
    providedIn: 'root',
})
export class DateHelperService {
    constructor() {}

    dateISOStringtoDate(dateString: string): Date {
        // "2022-11-19T00:00:00+01:00"
        const date = dateString.split('T')[0];
        const dateValues = date.split('-');
        if (!date || !dateValues || dateValues.length < 3) {
            throw new Error('No ISO Format found');
        }
        return new Date(Number(dateValues[0]), Number(dateValues[1]) - 1, Number(dateValues[2]));
    }

    dateToNgbDateStruct(date: Date): NgbDateStruct {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
    }
}
