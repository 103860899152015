import { Injectable } from '@angular/core';
import { FormField } from '../../../../../api/models/form-field';
import { ValidatorFn, Validators } from '@angular/forms';
import { MinDateService } from './custom-validators/minDate/min-date.service';
import { MaxDateService } from './custom-validators/maxDate/max-date.service';
import { DateRangeService } from './custom-validators/dateRange/date-range.service';
import { NoHtmlCharactersService } from './custom-validators/noHtmlCharacters/no-html-characters.service';
import { DinSpec91379Service } from './custom-validators/dinSpec91379/din-spec-91379.service';

@Injectable({
    providedIn: 'root'
})
export class FormFieldValidatorMapperService {
    constructor(
        private minDate: MinDateService,
        private maxDate: MaxDateService,
        private dateRange: DateRangeService,
        private noHtmlCharacters: NoHtmlCharactersService,
        private dinSpec91379: DinSpec91379Service
    ) {}

    mapValidators(field: FormField): ValidatorFn[] {
        const val: any[] = [];
        if (field.is_required) {
            val.push(Validators.required);
        }
        if (
            field.type === 'date' &&
            field.min_date !== '1900-01-01T00:00:00+00:00' &&
            field.max_date !== '1900-01-01T00:00:00+00:00'
        ) {
            val.push(this.dateRange.dateRange(field.min_date, field.max_date));
        } else {
            if (field.type === 'date' && field.min_date !== '1900-01-01T00:00:00+00:00') {
                val.push(this.minDate.minDate(field.min_date));
            }
            if (field.type === 'date' && field.max_date !== '1900-01-01T00:00:00+00:00') {
                val.push(this.maxDate.maxDate(field.max_date));
            }
        }

        if (field.type === 'text' && field.length > 0) {
            val.push(Validators.maxLength(field.length));
        }

        if (field.type === 'email') {
            val.push(Validators.email);
        }

        if (field.type === 'phone') {
            const noInvalidPhonePatternValidator = Validators.pattern(/^(-|\/|\+|[[:space:]]|[0-9])+$/i);
            val.push(noInvalidPhonePatternValidator);
        }

        if (field.type === 'text' || field.type === 'textarea') {
            val.push(this.noHtmlCharacters.noHtmlCharacters());
        }

        if ((field.type === 'text' || field.type === 'textarea') && field.is_din_spec91379_compliant) {
            val.push(this.dinSpec91379.dinSpec91379Validator());
        }

        return val;
    }
}
