import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiConfiguration } from '../../api/api-configuration';
import { I18NTranslationService } from '../../api/services/i-18-n-translation.service';
import { I18NTranslationResponse } from '../../api/models/i-18-n-translation-response';

@Injectable({
    providedIn: 'root',
})
export class CalendarLoadTranslationService {
    rootUrl = '';

    constructor(
        private http: HttpClient,
        private apiConfiguration: ApiConfiguration,
        private I18NTranslationService: I18NTranslationService,
    ) {}
    getI18NTranslationData(locale: string): Observable<I18NTranslationResponse> {
        return this.I18NTranslationService.getApiPublicI18NTranslation({
            _locale: <'de' | 'en' | 'fr'>locale,
        }).pipe(
            catchError(() => {
                const fallback = JSON.stringify({
                    pikaday: {
                        today: 'Heute',
                        previousMonth: 'Vorheriger Monat',
                        nextMonth: 'Nächster Monat',
                        fullMonths: [
                            'Januar',
                            'Februar',
                            'März',
                            'April',
                            'Mai',
                            'Juni',
                            'Juli',
                            'August',
                            'September',
                            'Oktober',
                            'November',
                            'Dezember',
                        ],
                        months: [
                            'Januar',
                            'Februar',
                            'März',
                            'April',
                            'Mai',
                            'Juni',
                            'Juli',
                            'August',
                            'September',
                            'Oktober',
                            'November',
                            'Dezember',
                        ],
                        monthAbbrs: [
                            'Jan',
                            'Feb',
                            'Mär',
                            'Apr',
                            'Mai',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Okt',
                            'Nov',
                            'Dez',
                        ],
                        fullDays: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
                        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                        dayAbbrs: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        titles: [
                            'Vorheriger Monat',
                            'Nächster Monat',
                            'Vorheriges Jahr',
                            'Nächstes Jahr',
                            'Heute',
                            'Kalender anzeigen',
                            'KW',
                            'Woche [[%0%]] von [[%1%]]',
                            'Woche',
                            'Datum wählen',
                            'Klicken & ziehen, um zu verschieben',
                            '[[%0%]] zuerst anzeigen',
                            'Zum heutigen Datum',
                            'Deaktiviertes Datum',
                            'Keine Termine verfügbar am',
                            'Termine verfügbar am',
                        ],
                        labels: ['Monat wählen', 'Jahr wählen'],
                        caption:
                            'Tabelle mit [[%0%]] Spalten und [[%1%]] Reihen. Sie können die zusätzlich die Pfeiltasten zur Navigation verwenden.',
                    },
                    ngb: {
                        datepicker: {
                            dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                            dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                            monthNamesShort: [
                                'Jan',
                                'Feb',
                                'Mär',
                                'Apr',
                                'Mai',
                                'Jun',
                                'Jul',
                                'Aug',
                                'Sep',
                                'Okt',
                                'Nov',
                                'Dez',
                            ],
                            monthNames: [
                                'Januar',
                                'Februar',
                                'März',
                                'April',
                                'Mai',
                                'Juni',
                                'Juli',
                                'August',
                                'September',
                                'Oktober',
                                'November',
                                'Dezember',
                            ],
                            selectMonth: 'Monat wählen',
                            selectYear: 'Jahr wählen',
                            previousMonth: 'Vorheriger Monat',
                            nextMonth: 'Nächster Monat',
                            today: 'Heute',
                        },
                    },
                });
                const data: I18NTranslationResponse = {
                    data: fallback,
                    meta_data: [],
                };

                return of(data);
            }),
        );
    }
}
