import { Agency } from '../api/models/agency';
import { Service } from '../api/models/service';
import { Strategy } from '../services/assignment-strategy/strategy/strategy';
import { PersonalDataForm } from '../api/models/personal-data-form';
import { StrategyRandom } from '../services/assignment-strategy/strategy/strategy-random';
import { AutomaticExtension } from '../api/models/automatic-extension';
import { signal, WritableSignal } from '@angular/core';

export class Booking {
    agency?: Agency;
    service?: Service;
    appointmentType?: string;
    date?: Date;
    time?: string;
    strategy: Strategy = new StrategyRandom();
    participantsCount: number = 1;
    uuid: string;
    personalDataValues: { [index: string]: any };
    personalDataForm: PersonalDataForm;
    hasAssignmentStrategy: boolean = false;
    hasParticipantsCount: boolean = false;
    automaticExtension?: AutomaticExtension;
    aESignal: WritableSignal<AutomaticExtension>;
    nextRelease: string;

    constructor() {
        this.personalDataValues = {};
        this.aESignal = signal<AutomaticExtension>({
            note: 'Automatische Terminverlängerung',
            label: 'Teilnehmer/in Anzahl',
        });
    }
}
