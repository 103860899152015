import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WebcomponentInterfaceService {
    private _headless: string = 'false';
    private _agency: string = '';
    private _service: string = '';
    private _endpoint: string = '';
    private _language: string = '';
    private _cancelBooking: Subject<boolean> = new Subject<boolean>();
    private _blockAgency: string = 'false';
    private _blockService: string = 'false';

    constructor() {}

    headlessToBoolean(): boolean {
        return this._headless === 'true';
    }

    get headless(): string {
        return this._headless;
    }

    set headless(value: string) {
        this._headless = value;
    }

    get agency(): string {
        return this._agency;
    }

    set agency(value: string) {
        this._agency = value;
    }

    get blockAgency(): string {
        return this._blockAgency;
    }

    set blockAgency(value: string) {
        this._blockAgency = value;
    }

    get service(): string {
        return this._service;
    }

    set service(value: string) {
        this._service = value;
    }

    get blockService(): string {
        return this._blockService;
    }

    set blockService(value: string) {
        this._blockService = value;
    }

    get endpoint(): string {
        return this._endpoint;
    }

    set endpoint(value: string) {
        this._endpoint = value;
    }

    get language(): string {
        return this._language;
    }

    set language(value: string) {
        this._language = value;
    }

    get cancelBooking(): Subject<boolean> {
        return this._cancelBooking;
    }

    set cancelBooking(value: Subject<boolean>) {
        this._cancelBooking = value;
    }
}
