import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LanguageISOType } from '../../types/LanguageISOType';
import { AgenciesResponse } from '../../api/models/agencies-response';
import { Agency } from '../../api/models/agency';
import { AgenciesService } from '../../api/services/agencies.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    allAgencies$: BehaviorSubject<Array<Agency>> = new BehaviorSubject<Array<Agency>>([]);
    constructor(
        private agenciesHttpService: AgenciesService,
        @Inject(LOCALE_ID) public locale: string
    ) {}

    retrieveAgencies(): void {
        this.agenciesHttpService
            .getApiPublicAgencies({
                _locale: this.locale.toLowerCase() as LanguageISOType
            })
            .subscribe((agenciesResponse: AgenciesResponse) => {
                this.allAgencies$.next(agenciesResponse.data);
            });
    }
}
