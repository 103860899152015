import { Injectable } from '@angular/core';
import { Booking } from '../../model/booking';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    booking: Booking = new Booking();
    clearBookingEvent = new Subject<void>();

    constructor(private sessionStorageService: SessionStorageService) {
        this.loadBooking();
    }

    loadBooking(): void {
        const storageBooking = this.sessionStorageService.parseBookingFromStorage();

        if (storageBooking) {
            Object.assign(this.booking, storageBooking);
        }
    }

    resetDateTime(): void {
        this.booking.date = undefined;
        this.booking.time = undefined;
    }

    clearBooking(): void {
        this.booking = new Booking();
        this.clearBookingEvent.next();
    }
}
