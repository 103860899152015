import { Component, OnDestroy, OnInit } from '@angular/core';
import { faStar, faCheck, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ColorLegendService } from '../../../services/calendar/color-legend.service';

@Component({
    selector: 'otb-color-legend',
    templateUrl: './color-legend.component.html',
    styleUrls: ['./color-legend.component.scss', './color-legend.component.mags-sop.scss']
})
export class ColorLegendComponent implements OnInit, OnDestroy {
    faCheck = faCheck;
    faStar = faStar;
    faSquare = faSquare;
    faTimes = faTimes;
    showLegend: boolean = true;
    sub: Subscription = new Subscription();

    constructor(private colorLegendService: ColorLegendService) {}

    toggleLegend(_$event: Event): void {
        this.colorLegendService.showLegend$.next(!this.showLegend);
    }

    ngOnInit(): void {
        this.sub.add(
            this.colorLegendService.showLegend$.subscribe((show) => {
                this.showLegend = show;
            })
        );
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
