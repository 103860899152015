<div class="d-flex align-items-center error--container mb-2 invalid-feedback" *ngIf="show">
    <!--    <span class="form-error-icon badge bg-danger text-uppercase me-1" *ngIf="showErrorBadge">Fehler</span>-->
    <img
        class="warning-icon"
        src="data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIG92ZXJmbG93PSJ2aXNpYmxlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgeT0iMHB4IiB4PSIwcHgiIGlkPSJMYXllcl8xXzE2MjIwMTcyNDY2MjAiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDAsMCkiPgoJPGc+CgkJPHBhdGggZD0iTTEyLDE4Yy0wLjYsMC0xLjEtMC41LTEuMS0xLjFzMC41LTEuMSwxLjEtMS4xczEuMSwwLjUsMS4xLDEuMVMxMi42LDE4LDEyLDE4eiIgc3R5bGU9ImZpbGw6IHJnYigyNTUsIDI1NSwgMjU1KTsgc3Ryb2tlLXdpZHRoOiAwcHg7IiB2ZWN0b3ItZWZmZWN0PSJub24tc2NhbGluZy1zdHJva2UiLz4KCTwvZz4KCTxnPgoJCTxwYXRoIGQ9Ik0xMiwxNC4yYy0wLjQsMC0wLjgtMC4zLTAuOC0wLjhWNS4yYzAtMC40LDAuMy0wLjgsMC44LTAuOHMwLjgsMC4zLDAuOCwwLjh2OC4yQzEyLjgsMTMuOSwxMi40LDE0LjIsMTIsMTQuMnoiIHN0eWxlPSJmaWxsOiByZ2IoMjU1LCAyNTUsIDI1NSk7IHN0cm9rZS13aWR0aDogMHB4OyIgdmVjdG9yLWVmZmVjdD0ibm9uLXNjYWxpbmctc3Ryb2tlIi8+Cgk8L2c+CjwvZz48L2c+PC9zdmc+"
        aria-hidden="true"
        alt="Achtung Icon"
    />
    <span class="text-danger form-error-message">{{ text }}</span>
</div>
