import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ColorLegendService {
    showLegend$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    constructor() {}
}
