import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyServiceSelectionComponent } from './pages/agency-service-selection/agency-service-selection.component';
import { StateNamesEnum } from './enum/state-names-enum';
import { DatePickComponent } from './pages/date-pick/date-pick.component';
import { TimePickComponent } from './pages/time-pick/time-pick.component';
import { AssignmentStrategyComponent } from './pages/assignment-strategy/assignment-strategy.component';
import { ParticipantCountComponent } from './pages/participant-count/participant-count.component';
import { PersonalDataComponent } from './pages/personal-data/personal-data.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { CancelComponent } from './pages/cancel/cancel.component';
import { ServiceOnlyComponent } from './pages/service-only/service-only.component';
import { AppointmentTypeComponent } from './pages/appointment-type/appointment-type.component';
import { AccessablityInfoComponent } from './pages/accessablity-info/accessablity-info.component';
import { EasyLanguageComponent } from './pages/easy-language/easy-language.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: StateNamesEnum.COURT_AND_SERVICE,
        pathMatch: 'prefix'
    },
    {
        path: StateNamesEnum.SERVICE_ONLY,
        component: ServiceOnlyComponent
    },
    {
        path: StateNamesEnum.COURT_AND_SERVICE,
        component: AgencyServiceSelectionComponent
    },
    {
        path: StateNamesEnum.APPOINTMENT_TYPE,
        component: AppointmentTypeComponent
    },
    {
        path: StateNamesEnum.DATE_FIRST,
        component: DatePickComponent
    },
    {
        path: StateNamesEnum.TIME_FIRST,
        component: TimePickComponent
    },
    {
        path: StateNamesEnum.ASSIGNMENT_STRATEGY,
        component: AssignmentStrategyComponent
    },
    {
        path: StateNamesEnum.PARTICIPANT_COUNT,
        component: ParticipantCountComponent
    },
    {
        path: StateNamesEnum.PERSONAL_DATA,
        component: PersonalDataComponent
    },
    {
        path: StateNamesEnum.SUMMARY,
        component: SummaryComponent
    },
    {
        path: StateNamesEnum.THANK_YOU,
        component: ThankYouComponent
    },
    {
        path: StateNamesEnum.CANCEL, // Warning! Change the redirect URL in the backend if you change the URL here!
        component: CancelComponent
    },
    {
        path: StateNamesEnum.CONFIRMATION, // Warning! Change the redirect URL in the backend if you change the URL here!
        component: ConfirmationComponent
    },
    {
        path: 'barrierefreiheit',
        component: AccessablityInfoComponent
    },
    {
        path: 'leichte_sprache',
        component: EasyLanguageComponent
    },

    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class OtbRoutingModule {}
