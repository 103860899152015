import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnvironmentsEnum } from '../enum/environments-enum';
import { environment } from '../../environments/environment';

@Directive({
    selector: '[otbHideInEnv]',
    standalone: false,
})
export class HideInEnvDirective {
    private readonly env: EnvironmentsEnum;
    @Input() set otbHideInEnv(envs: string[]) {
        if (envs.includes(this.env)) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {
        this.env = <EnvironmentsEnum>environment.deploy_environment;
    }
}
