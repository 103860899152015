import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { PagesService } from '../../api/services/pages.service';
import { ContentPageResponse } from '../../api/models/content-page-response';
import { ContentPage } from '../../api/models/content-page';
import { LanguageISOType } from '../../types/LanguageISOType';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ContentLinksService {
    constructor(
        private contentPagesHttpService: PagesService,
        @Inject(LOCALE_ID) public locale: string,
    ) {}

    contentPageList$: BehaviorSubject<Array<ContentPage>> = new BehaviorSubject<Array<ContentPage>>([]);

    headerContentPages$ = this.contentPageList$.pipe(
        map((pages) => pages.filter((page) => page.positioning === 'header')),
        shareReplay(1),
    );

    footerContentPages$ = this.contentPageList$.pipe(
        map((pages) => pages.filter((page) => page.positioning === 'footer')),
        shareReplay(1),
    );

    getContentPages(): void {
        this.contentPagesHttpService
            .getApiPublicContentPageList({
                _locale: this.locale.toLowerCase() as LanguageISOType,
            })
            .subscribe((contentPageResponse: ContentPageResponse) => {
                if (contentPageResponse?.data?.length) {
                    this.contentPageList$.next(contentPageResponse.data);
                }
            });
    }

    getContentPageByPath(path: string): Observable<ContentPage | undefined> {
        return this.contentPageList$.pipe(map((pages) => pages.find((page) => page.path_name == path)));
    }

    sortContentPages(pages: ContentPage[]): ContentPage[] {
        return pages.sort((a, b) => a.sort_order - b.sort_order);
    }
}
