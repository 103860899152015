import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AvailableTime } from '../../api/models/available-time';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { CalendarService } from '../../services/calendar/calendar.service';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { StateService } from '../../services/state/state.service';
import { ColorLegendService } from '../../services/calendar/color-legend.service';
import { CalendarTimeFirstService } from '../../services/calendar/time-first/calendar-time-first.service';
import { BookingService } from '../../services/booking/booking.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { environment } from '../../../environments/environment';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { AvailableCourtTime } from '../../api/models/available-court-time';

@Component({
    selector: 'otb-time-pick',
    templateUrl: './time-pick.component.html',
    styleUrls: ['./time-pick.component.scss']
})
export class TimePickComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('calendarContainer') calendarWrapper: ElementRef<HTMLDivElement>;
    faArrowCircleRight = faArrowCircleRight;
    times: Array<AvailableCourtTime> = [];
    subs: Subscription = new Subscription();
    selectedTime: AvailableTime;
    selectedTimeDateFormat: Date;

    nextAppointmentAt = new Date(this.bookingService.booking.nextRelease);

    colorLegendShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    hasAppointments: boolean = true;
    timesLoading: boolean = true;
    timeChosen: boolean = false;
    calendarLoading: boolean = true;
    showFormSwitcherTop: boolean = true;
    dateChosen: BehaviorSubject<boolean>;

    constructor(
        private calendarTimeFirstService: CalendarTimeFirstService,
        private calendarService: CalendarService,
        private changeDetector: ChangeDetectorRef,
        private stateService: StateService,
        private colorLegendService: ColorLegendService,
        private bookingService: BookingService,
        private appointmentService: AppointmentService
    ) {
        this.colorLegendShow = this.colorLegendService.showLegend$;
        this.dateChosen = this.calendarTimeFirstService.dateChosen;
        this.showFormSwitcherTop = this.showSwitchTop();
    }

    ngOnInit(): void {
        this.bookingService.resetDateTime();
        this.calendarService.availableTimes.pipe(take(2)).subscribe((times) => {
            if (times.length > 0) {
                this.timesLoading = false;
                this.times = times;
            }
        });

        this.subs.add(
            this.calendarService.availableDates.subscribe((dates) => {
                this.hasAppointments = dates.length > 0;
                this.calendarLoading = false;
            })
        );
    }

    showSwitchTop(): boolean {
        return ![EnvironmentsEnum.SH].includes(<EnvironmentsEnum>environment.deploy_environment);
    }

    ngAfterViewInit(): void {
        this.calendarService.getAvailableTimes();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onTimeSelect(time: AvailableTime): void {
        this.selectedTime = time;
        this.selectedTimeDateFormat = this.convertTimeStringToDateTime(this.selectedTime);
        this.timeChosen = true;
        this.calendarLoading = true;
        this.calendarTimeFirstService.dateChosen.next(false);
        this.changeDetector.detectChanges();
        const calendarContainer = this.calendarWrapper.nativeElement;
        const calendarInput = calendarContainer.getElementsByTagName('input')[0];
        this.calendarTimeFirstService.initializeCalendar(calendarContainer, calendarInput, time);
        this.bookingService.booking.time = time.time;
    }

    switchToDateFirst($event: Event): void {
        $event.preventDefault();
        this.bookingService.resetDateTime();
        this.stateService.switchToDateFirst();
    }

    private convertTimeStringToDateTime(time: AvailableTime): Date {
        const date = new Date();
        const parts = time.time.split(':');
        date.setHours(Number(parts[0]));
        date.setMinutes(Number(parts[1]));
        return date;
    }

    submit(): void {
        if (this.bookingService.booking.time && this.bookingService.booking.date) {
            this.appointmentService.reserveAppointment();
        }
    }
}
