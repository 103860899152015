import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Service } from '../../api/models/service';
import { ServicesService } from '../../api/services/services.service';
import { ServicesResponse } from '../../api/models/services-response';
import { Agency } from '../../api/models/agency';
import { WarningService } from '../../services/warning/warning.service';
import { StateService } from '../../services/state/state.service';
import { BookingService } from '../../services/booking/booking.service';
import { ApplicationSettingsService as AppSettingsService } from '../../services/application-settings/application-settings.service';
import { EnvironmentCssClassesGeneratorService } from '../../services/environment-css-classes-generator/environment-css-classes-generator.service';
import { LanguageISOType } from '../../types/LanguageISOType';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../../api/services/application-settings.service';
import { ApplicationSettingsResponse } from '../../api/models/application-settings-response';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { EnvironmentsEnum } from '../../enum/environments-enum';
import { environment } from '../../../environments/environment';
import { WebcomponentInterfaceService } from '../../services/webcomponent-interface/webcomponent-interface.service';

@Component({
    selector: 'otb-agency-service-selection',
    templateUrl: './agency-service-selection.component.html',
    styleUrls: [
        './agency-service-selection.component.scss',
        './agency-service-selection.component.mags-sop.scss',
        './agency-service-selection.component.sh.scss',
        './agency-service-selection.component.hmdj.scss',
        './agency-service-selection.component.bb-jm.scss',
    ],
    standalone: false,
})
export class AgencyServiceSelectionComponent implements OnInit, OnDestroy {
    agencyFormGroup: FormGroup;
    subs: Subscription = new Subscription();

    services: Array<Service>;
    servicesSelect: Array<Service> = [
        {
            id: 0,
            name: 'Dienstleistung auswählen',
            notice: '',
            external_reference: '',
        },
    ];

    selectedService: Service | undefined;
    isEnvJustice: boolean = false;
    serviceSelectionAjaxLoading: boolean = false;
    deployEnv = EnvironmentsEnum;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private formBuilder: FormBuilder,
        private serviceService: ServicesService,
        private warningService: WarningService,
        private stateService: StateService,
        private bookingService: BookingService,
        public envCssClassesGenerator: EnvironmentCssClassesGeneratorService,
        private applicationSettingsService: ApplicationSettingsService,
        private appSettingsService: AppSettingsService,
        private sessionStorageService: SessionStorageService,
        private webcompInterfaceService: WebcomponentInterfaceService,
    ) {}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.initialize();
        const applicationServiceHttp$ = this.applicationSettingsService
            .getApiPublicApplicationSettings({ _locale: 'de' }) // langauge is not important here
            .subscribe((settings: ApplicationSettingsResponse) => {
                if (settings.data.online_service) {
                    this.appSettingsService.onlineService = settings.data.online_service;
                    this.sessionStorageService.persistApplicationSettings();
                }
            });
        this.subs.add(applicationServiceHttp$);
        if (environment.deploy_environment == EnvironmentsEnum.JUSTICE) {
            this.isEnvJustice = true;
        }
    }

    private initialize(): void {
        this.bookingService.clearBooking();
        this.initializeForm();
        const clearBookingEvent$ = this.bookingService.clearBookingEvent.subscribe(() => {
            this.initializeForm();
        });
        this.subs.add(clearBookingEvent$);
    }

    private initializeForm(): void {
        const formControlsConfig = {
            service: [0, [Validators.required, Validators.min(1)]],
            agency: [this.bookingService.booking.agency?.id ?? 0, [Validators.required, Validators.min(1)]],
        };

        this.agencyFormGroup = this.formBuilder.group(formControlsConfig);
        if (this.bookingService.booking.agency?.id !== 0) this.retrieveServices(this.bookingService.booking?.agency);
    }

    onAgencySelect(agency: Agency): void {
        this.bookingService.booking.agency = agency;
        this.agencyFormGroup.controls['service'].reset(0);
        this.retrieveServices(agency);
    }

    private retrieveServices(agency: Agency | undefined): void {
        if (!agency) {
            return;
        }
        this.serviceSelectionAjaxLoading = true;

        const servicePlaceholder = {
            id: 0,
            name: 'Dienstleistung auswählen',
            notice: '',
            external_reference: '',
        };

        const getServices$ = this.serviceService
            .getApiPublicServices({
                agency_id: agency.id.toString(),
                _locale: this.locale.toLowerCase() as LanguageISOType,
            })
            .subscribe((servicesByApi: ServicesResponse) => {
                this.serviceSelectionAjaxLoading = false;
                this.services = servicesByApi.data;

                this.servicesSelect = servicesByApi.data
                    ? [servicePlaceholder, ...servicesByApi.data]
                    : [servicePlaceholder];
                this.prefillServiceByExternalReference(this.servicesSelect);
            });
        this.subs.add(getServices$);
    }

    onServiceSelect(selectedService: Service): void {
        this.selectedService = selectedService;
        this.bookingService.booking.service = selectedService;

        if (!this.bookingService.booking.agency?.id) {
            throw new Error('We need an agency before we can select a service');
        }

        if (this.bookingService.booking.service.id === 0) {
            return;
        }
        this.stateService.updateMachine(
            this.bookingService.booking.service.id.toString(),
            this.bookingService.booking.agency?.id.toString(),
        );
    }

    private prefillServiceByExternalReference(services: Array<Service>): void {
        this.warningService.hideServicePreselectionWarning();
        if (this.webcompInterfaceService.service === '') {
            return;
        }

        const preSelectedService = services.find(
            (service) => service.external_reference === this.webcompInterfaceService.service,
        );

        if (typeof preSelectedService === 'object') {
            this.prefillService(preSelectedService);
        } else {
            this.warningService.showServicePreselectionWarning();
        }
    }

    prefillService(preSelectedService: Service): void {
        this.selectedService = preSelectedService;
        this.bookingService.booking.service = this.selectedService;
        this.agencyFormGroup.controls['service'].patchValue(preSelectedService.id);
        const blockedService: string = this.webcompInterfaceService.blockService;
        if (blockedService === 'true') {
            this.agencyFormGroup.controls['service'].disable();
        }
    }

    onSubmit(): void {
        const agencyId = this.agencyFormGroup.controls['agency'].value;
        const serviceId = this.agencyFormGroup.controls['service'].value;

        if (this.isFormValidOrFieldsDisabled()) {
            if (this.appSettingsService.onlineService && this.bookingService.booking.service?.meeting_type !== 'both') {
                this.bookingService.booking.appointmentType = this.bookingService.booking.service?.meeting_type;
            }
            const subscription = this.stateService.updateMachine(serviceId, agencyId).subscribe((isUpdated) => {
                if (isUpdated) {
                    this.stateService.next();
                    subscription.unsubscribe();
                }
            });
        } else {
            this.formAgency.markAsDirty();
            this.formService.markAsDirty();
        }
    }

    // Skip validation if both fields are disabled as they are not editable.
    private isFormValidOrFieldsDisabled(): boolean {
        return (
            this.agencyFormGroup.valid ||
            (this.agencyFormGroup.controls['service'].disabled && this.agencyFormGroup.controls['agency'].disabled)
        );
    }

    get formAgency(): AbstractControl {
        return this.agencyFormGroup.get('agency') as AbstractControl;
    }

    get formService(): AbstractControl {
        return this.agencyFormGroup.get('service') as AbstractControl;
    }

    protected readonly environment = environment;
}
