import { Component, Input } from '@angular/core';

@Component({
    selector: 'otb-loading-animation-dots',
    templateUrl: './loading-animation-dots.component.html',
    styleUrls: ['./loading-animation-dots.component.scss'],
    standalone: false,
})
export class LoadingAnimationDotsComponent {
    @Input() text: string = '';
    @Input() mode: 'light' | 'dark' = 'light';

    constructor() {}
}
