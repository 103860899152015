import { Component, HostListener, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { AbstractControl, FormRecord, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormGroupFactory } from './form-group-factory-service/form-group-factory.service';
import { FormField } from '../../../api/models/form-field';
import { BookingService } from '../../../services/booking/booking.service';
import { StateService } from '../../../services/state/state.service';
import { StrategyName } from '../../../services/assignment-strategy/strategy/strategy-name';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { StrategyFileNumber } from 'src/app/services/assignment-strategy/strategy/strategy-file-number';
import { FileNumberBuilderService } from '../../../services/assignment-strategy/file-number-builder.service';
import { DynamicFormService } from './dynamic-form.service';
import { environment } from '../../../../environments/environment';
import { StrategyList } from '../../../services/assignment-strategy/strategy/strategy-list';

@Component({
    selector: 'otb-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    standalone: false,
})
export class DynamicFormComponent implements OnInit {
    @Input() personalDataFields: PersonalDataForm | null;
    @ViewChildren('acc') subFormsAcc: QueryList<any>;
    form!: FormRecord;
    subForms: number[];
    subFormCollapsedList: boolean[];
    isUploadProgress = false;

    constructor(
        private formGroupFactory: FormGroupFactory,
        private booking: BookingService,
        private stateService: StateService,
        private sessionStorageService: SessionStorageService,
        private fileNumberBuilderService: FileNumberBuilderService,
        private dynamicFormService: DynamicFormService,
    ) {}

    ngOnInit(): void {
        this.subForms = Array.from(Array(this.booking.booking.participantsCount - 1).keys());
        const form = this.formGroupFactory.toFormGroup(
            this.personalDataFields as PersonalDataForm,
            this.subForms,
            this.booking.booking.personalDataValues,
        );
        this.subFormCollapsedList = this.subForms.map((_el) => {
            return false;
        });
        this.disableFieldsByWorkflow(form);

        this.form = form;

        const storageData = this.sessionStorageService.getPersonalData();

        if (storageData && storageData !== '') {
            this.form.setValue(storageData);
        }
        this.dynamicFormService.setValidation$.subscribe((value) => {
            this.isUploadProgress = value;
        });
    }

    get subFormGroups(): UntypedFormGroup[] {
        if (this.form.contains('subForms')) {
            return (<UntypedFormArray>this.form.get('subForms')).controls as UntypedFormGroup[];
        }
        return [];
    }

    @HostListener('window:beforeunload')
    beforeUnload(): void {
        this.persistSessionData();
    }

    private persistSessionData(): void {
        this.sessionStorageService.persistPersonalData(JSON.stringify(this.form.getRawValue()));
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.booking.booking.personalDataValues = {
                ...this.form.getRawValue(),
                ...this.form.value,
            };
            this.persistSessionData();
            this.stateService.next();
        } else {
            // open all subforms to show errors
            this.subFormsAcc.forEach((el) => {
                el.expandAll();
            });
            this.markTouched(this.form);
        }
    }

    private disableFieldsByWorkflow(group: UntypedFormGroup): void {
        if (this.booking.booking.hasAssignmentStrategy) {
            if (this.booking.booking.strategy instanceof StrategyName) {
                group.controls[this.booking.booking.strategy.lastNameFormField].disable();
                group.controls[this.booking.booking.strategy.lastNameFormField].setValue(
                    this.booking.booking.strategy.lastName,
                );
            }
            if (this.booking.booking.strategy instanceof StrategyList) {
                group.controls[this.booking.booking.strategy.listResultFormField].disable();
                group.controls[this.booking.booking.strategy.listResultFormField].setValue(
                    this.booking.booking.strategy.chosenOption,
                );
            }
            if (this.booking.booking.strategy instanceof StrategyFileNumber) {
                group.controls[this.booking.booking.strategy.fileNumberFormField].disable();
                group.controls[this.booking.booking.strategy.fileNumberFormField].setValue(
                    this.fileNumberBuilderService.buildFromStrategy(this.booking.booking.strategy),
                );
            }
        }
        if (this.booking.booking.hasParticipantsCount) {
            if (this.booking.booking.automaticExtension) {
                const participantField = this.booking.booking.automaticExtension['field_name'];
                if (participantField) {
                    group.controls[participantField].disable();
                    group.controls[participantField].setValue(this.booking.booking.participantsCount);
                }
            }
        }
    }

    private markTouched(group: UntypedFormGroup | UntypedFormArray): void {
        Object.values(group.controls).forEach((value) => {
            const control = value;
            if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
                this.markTouched(control);
            } else {
                if (control instanceof AbstractControl) {
                    control.markAsTouched();
                }
            }
        });
    }

    getLeftSideSub(): FormField[] {
        if (this.personalDataFields === null) {
            return [];
        }
        const leftSideEntries = this.personalDataFields!.sub_form_fields!.filter((formField: FormField) => {
            return formField.section_in_form === 'section 1';
        });
        return this.orderByPositionInSection(leftSideEntries);
    }

    getLeftSide(): FormField[] {
        if (this.personalDataFields === null) {
            return [];
        }
        const leftSideEntries = this.personalDataFields!.form_fields.filter((formField: FormField) => {
            return formField.section_in_form === 'section 1';
        });
        return this.orderByPositionInSection(leftSideEntries);
    }

    back(): void {
        this.stateService.back();
    }

    getRightSideSub(): FormField[] {
        if (this.personalDataFields === null) {
            return [];
        }
        const rightSideEntries = this.personalDataFields!.sub_form_fields!.filter((formField: FormField) => {
            return formField.section_in_form === 'section 2';
        });
        return this.orderByPositionInSection(rightSideEntries);
    }

    getRightSide(): FormField[] {
        if (this.personalDataFields === null) {
            return [];
        }
        const rightSideEntries = this.personalDataFields!.form_fields.filter((formField: FormField) => {
            return formField.section_in_form === 'section 2';
        });
        return this.orderByPositionInSection(rightSideEntries);
    }

    private orderByPositionInSection(leftSideEntries: FormField[]): FormField[] {
        return leftSideEntries.sort((a: FormField, b: FormField) => {
            return a.position_in_section - b.position_in_section;
        });
    }

    notMags(): boolean {
        return environment.deploy_environment !== 'mags-sop';
    }
}
