import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    standalone: false,
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, replacements: { [index: string]: string | undefined }): string {
        for (const key in replacements) {
            if (replacements.hasOwnProperty(key)) {
                const placeholder = `%${key}%`;

                value = value.replace(placeholder, replacements[key] as string);
            }
        }

        return value;
    }
}
