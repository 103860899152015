import { ElementRef, Injectable, Renderer2, RendererFactory2, signal, Signal, WritableSignal } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs-compat/operator/first';

@Injectable({
    providedIn: 'root'
})
export class DatePickService {
    timesLoaded: Subject<boolean> = new Subject<boolean>();

    private renderer: Renderer2;
    constructor(private rendererFactory2: RendererFactory2) {
        this.renderer = rendererFactory2.createRenderer(null, null);
    }

    focusOnFirstElement(firstElementRef?: ElementRef): void {
        if (!firstElementRef) {
            return;
        }

        let el = this.renderer.selectRootElement(firstElementRef.nativeElement);
        el.innerHTML = el.getAttribute('data-text'); // This is necessary because the element pipe is not applied in this case
        el.focus();
    }
}
