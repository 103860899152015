import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class DinSpec91379Service {
    dinSpec91379Validator(): ValidatorFn {
        const validUnicodePoints = [
            64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
            119, 120, 121, 122, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208,
            209, 210, 211, 212, 213, 214, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
            231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 248, 249, 250, 251, 252,
            253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273,
            274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294,
            295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315,
            316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336,
            337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357,
            358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378,
            379, 380, 381, 382, 391, 392, 399, 407, 416, 417, 431, 432, 439, 461, 462, 463, 464, 465, 466, 467, 468,
            469, 470, 471, 472, 473, 474, 475, 476, 478, 479, 482, 483, 484, 485, 486, 487, 488, 489, 490, 491, 492,
            493, 494, 495, 496, 500, 501, 504, 505, 506, 507, 508, 509, 510, 511, 530, 531, 536, 537, 538, 539, 542,
            543, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562, 563, 601, 616, 658, 7682, 7683, 7686, 7687,
            7690, 7691, 7692, 7693, 7694, 7695, 7696, 7697, 7708, 7709, 7710, 7711, 7712, 7713, 7714, 7715, 7716, 7717,
            7718, 7719, 7720, 7721, 7722, 7723, 7727, 7728, 7729, 7730, 7731, 7732, 7733, 7734, 7735, 7738, 7739, 7744,
            7745, 7746, 7747, 7748, 7749, 7750, 7751, 7752, 7753, 7762, 7763, 7764, 7765, 7766, 7767, 7768, 7769, 7770,
            7771, 7774, 7775, 7776, 7777, 7778, 7779, 7786, 7787, 7788, 7789, 7790, 7791, 7808, 7809, 7810, 7811, 7812,
            7813, 7814, 7815, 7820, 7821, 7822, 7823, 7824, 7825, 7826, 7827, 7828, 7829, 7830, 7831, 7838, 7841, 7842,
            7843, 7844, 7845, 7846, 7847, 7848, 7849, 7850, 7851, 7852, 7853, 7854, 7855, 7856, 7857, 7858, 7859, 7860,
            7861, 7862, 7863, 7864, 7865, 7866, 7867, 7868, 7869, 7870, 7871, 7872, 7873, 7874, 7875, 7876, 7877, 7878,
            7879, 7880, 7881, 7882, 7883, 7884, 7885, 7886, 7887, 7888, 7889, 7890, 7891, 7892, 7893, 7894, 7895, 7896,
            7897, 7898, 7899, 7900, 7901, 7902, 7903, 7904, 7905, 7906, 7907, 7908, 7909, 7910, 7911, 7912, 7913, 7914,
            7915, 7916, 7917, 7918, 7919, 7920, 7921, 7922, 7923, 7924, 7925, 7926, 7927, 7928, 7929, 768, 769, 770,
            772, 774, 775, 776, 779, 780, 784, 789, 795, 803, 805, 806, 807, 817, 863, 32, 39, 44, 45, 46, 96, 126, 168,
            180, 183, 187, 191, 33, 34, 35, 36, 37, 38, 40, 41, 42, 43, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
            59, 61, 63, 64, 91, 92, 93, 94, 95, 123, 124, 125, 161, 162, 163, 165, 167, 169, 171, 172, 174, 175, 176,
            177, 182, 215, 247, 8364, 164, 166, 184, 10, 7840,
        ];

        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === null || control.value === undefined) {
                return null;
            }
            const value = control.value as string;
            for (let i = 0; i < value.length; i++) {
                const unicodePoint = value.charCodeAt(i);
                if (!validUnicodePoints.includes(unicodePoint)) {
                    return { dinSpecInvalid: true };
                }
            }
            return null;
        };
    }
}
