import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'otb-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
    selectLang: { id: string; name: string } = { id: '', name: 'Sprache' };
    langItems: Array<{ id: string; name: string }> = [{ id: '', name: 'Sprache' }];
    subs: Subscription = new Subscription();
    constructor(private langService: LanguageService) {}

    ngOnInit(): void {
        const languages$ = this.langService.allLanguages.subscribe((languages) => {
            this.langItems = [...languages];
            const activeLanguage = this.langService.getActiveLanguage();
            this.selectLang = languages.filter((lang) => lang.id.toLowerCase() === activeLanguage.toLowerCase())[0];

            if (!this.selectLang) {
                this.selectLang = { id: '', name: 'Sprache' };
            }
        });
        // intially get the language from storage to not display placeholder when available
        this.selectLang.id = this.langService.getLanguageCodeFromStorage();

        this.subs.add(languages$);
    }

    updateLang(): void {
        this.langService.changeLanguageAndReload(this.selectLang);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
