import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { AvailableTime } from '../../../api/models/available-time';
import { Subscription } from 'rxjs';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { CalendarDateFirstService } from '../../../services/calendar/date-first/calendar-date-first.service';
import { AvailableCourtTime } from '../../../api/models/available-court-time';
import { DatePickService } from '../../../pages/date-pick/service/date-pick.service';

@Component({
    selector: 'otb-time-pick-selection',
    templateUrl: './time-pick-selection.component.html',
    styleUrls: ['./time-pick-selection.component.scss', './time-pick-selection.component.mags-sop.scss'],
    standalone: false,
})
export class TimePickSelectionComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() availableAgencies: Array<AvailableCourtTime> = [];
    @Input() dateFirst: boolean = true;
    @Output() timeSelect = new EventEmitter<AvailableTime>();

    @ViewChild('first') first: ElementRef;

    subs: Subscription = new Subscription();
    selectedDate: Date = new Date();
    timePickSecondLoading: boolean = false;
    selectedTime: AvailableTime;

    constructor(
        private calendarService: CalendarService,
        private calendarDateFirstService: CalendarDateFirstService,
        private renderer: Renderer2,
        private el: ElementRef,
        private datePickService: DatePickService,
    ) {}

    ngOnInit(): void {
        this.subs.add(
            this.calendarDateFirstService.timePickSecondLoading.subscribe((loading) => {
                this.timePickSecondLoading = loading;
            }),
        );
    }

    ngAfterViewInit(): void {
        this.subs.add(
            this.calendarService.selectedDate.subscribe((date) => {
                this.selectedDate = date;
            }),
        );

        this.datePickService.focusOnFirstElement(this.first);
    }

    timeClick(time: AvailableTime, event: Event): void {
        event.preventDefault();

        const allLinks: NodeListOf<HTMLAnchorElement> = this.el.nativeElement.querySelectorAll('.time--wrapper a');
        allLinks.forEach((link: HTMLAnchorElement) => this.renderer.setAttribute(link, 'aria-selected', 'false'));

        const clickedElement = event.target as HTMLElement;
        this.renderer.setAttribute(clickedElement, 'aria-selected', 'true');

        this.selectedTime = time;
        this.timeSelect.emit(time);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
