import { Component, OnInit } from '@angular/core';
import { FormField, PersonalDataForm } from '../../api/models';
import { PersonalDataFormService } from './personal-data-api-bridge-service/personal-data-form.service';

@Component({
    selector: 'otb-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {
    personalDataFields: PersonalDataForm;

    constructor(private personalDataFormService: PersonalDataFormService) {}

    ngOnInit(): void {
        this.personalDataFormService.personalDataForm.subscribe((personalDataForm: PersonalDataForm) => {
            personalDataForm.form_fields.forEach((field) => {
                if (field.type === 'numeric_select') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    field.options = this.numericOptionsToNumber(field.options);
                }

                if (field.type === 'checkbox') {
                    field.default_values = this.sanitizeCheckboxOptions(field);
                }
            });
            if (personalDataForm.sub_form_fields !== undefined) {
                personalDataForm.sub_form_fields.forEach((field) => {
                    if (field.type === 'numeric_select') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        field.options = this.numericOptionsToNumber(field.options);
                    }
                });
            }
            this.personalDataFields = personalDataForm;
        });
    }

    numericOptionsToNumber(options: string[]): number[] {
        return options.map((el) => Number(el));
    }

    // if default value is not in options, remove everything
    sanitizeCheckboxOptions(field: FormField): string[] {
        let defaultsValues = field.default_values;

        field.default_values.forEach((value) => {
            if (field.options.indexOf(value) === -1) {
                defaultsValues = [];
            }
        });

        return defaultsValues;
    }
}
