import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { interpret, Interpreter, State, StateMachine } from 'xstate';
import { BehaviorSubject, fromEventPattern, Observable, of, Subject } from 'rxjs';
import Utils from '../../utils';
import { WorkflowsService } from '../../api/services/workflows.service';
import { createModel } from 'xstate/es/model';
import { Model } from 'xstate/es/model.types';
import { WorkflowContext, WorkflowEvent, WorkflowModelCreators, WorkFlowTypeState } from './state.types';
import { StateNamesEnum } from '../../enum/state-names-enum';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { Breadcrumb } from '../../components/breadcrumb/model/breadcrumb';
import { DynamicBreadcrumbItem } from '../../components/breadcrumb/interface/DynamicBreadcrumbItem';
import { WorkflowResponse } from '../../api/models/workflow-response';
import { AssignmentStrategyService } from '../assignment-strategy/assignment-strategy.service';
import { PersonalDataFormService } from '../../pages/personal-data/personal-data-api-bridge-service/personal-data-form.service';
import { BookingService } from '../booking/booking.service';
import { SessionStorageKeyEnum } from '../../enum/session-storage-key-enum';
import { Agency } from '../../api/models/agency';
import { Service } from '../../api/models/service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { LanguageISOType } from '../../types/LanguageISOType';
import { ApplicationSettingsService } from '../application-settings/application-settings.service';
import { StrategyRandom } from '../assignment-strategy/strategy/strategy-random';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    state$: Observable<any> = new Subject();
    private userModel: Model<WorkflowContext, WorkflowEvent, any, WorkflowModelCreators>;
    interpreter: Interpreter<WorkflowContext, any, WorkflowEvent, WorkFlowTypeState, any>;
    private readonly updated$: Subject<boolean>;
    machineStarted$: Subject<boolean> = new Subject<boolean>();
    machine: StateMachine<WorkflowContext, any, WorkflowEvent>;
    private stateUpdatedSubject = new BehaviorSubject<boolean>(false);
    stateUpdated$ = this.stateUpdatedSubject.asObservable();

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private workflows: WorkflowsService,
        private breadcrumbService: BreadcrumbService,
        private assignmentStrategyService: AssignmentStrategyService,
        private personalDataService: PersonalDataFormService,
        private bookingService: BookingService,
        private sessionStorageService: SessionStorageService,
        private appSettingsService: ApplicationSettingsService,
    ) {
        this.updated$ = new Subject<boolean>();
        this.updated$.next(false);
    }

    init(): void {
        this.machine = this.initializeMachine();
        this.interpreter = interpret(this.machine, { devTools: true });

        this.state$ = fromEventPattern(
            (handler: any) => {
                this.interpreter.onTransition(handler).start(); // the machine startes here
                return this.interpreter;
            },
            (handler, service) => {
                service.stop();
            },
        );
        this.machineStarted$.next(true);
    }

    private initializeMachine(): StateMachine<WorkflowContext, any, WorkflowEvent> {
        /** get saved state from storage */
        const initialState: StateNamesEnum | undefined = this.getIntialMachineState();
        let machineConfigStorage: any = sessionStorage.getItem(SessionStorageKeyEnum.STATE_MACHINE_CONFIG);
        if (!machineConfigStorage) {
            machineConfigStorage = undefined;
        }
        const machine: StateMachine<WorkflowContext, any, WorkflowEvent> = this.setupMachine(
            initialState,
            machineConfigStorage,
        );
        if (machineConfigStorage) {
            const agency = <Agency>this.bookingService.booking.agency;
            const service = <Service>this.bookingService.booking.service;
            if (agency && service && agency.id !== 0 && service.id !== 0) {
                this.updateMachine(service.id.toString(), agency.id.toString());
            }
        }

        return machine;
    }

    private getIntialMachineState(): StateNamesEnum | undefined {
        let initialState: StateNamesEnum | undefined;
        if (this.appSettingsService.serviceOnly) {
            initialState = StateNamesEnum.SERVICE_ONLY;
        }
        if (sessionStorage.getItem(SessionStorageKeyEnum.STATE_MACHINE_LAST_STATE)) {
            initialState = <StateNamesEnum>sessionStorage.getItem(SessionStorageKeyEnum.STATE_MACHINE_LAST_STATE);
        }
        if (!initialState) {
            initialState = undefined;
        }

        return initialState;
    }

    setupMachine(initialState: StateNamesEnum = StateNamesEnum.COURT_AND_SERVICE, machineConfigStorage?: any): any {
        this.userModel = createModel(
            {
                hasAssignment: true,
                hasParticipantsCount: true,
                mustAcceptTerms: true,
                mustAcceptEmailConsent: false,
                bookingTimeframe: 3,
                serviceOnly: false,
                hasAppointmentType: false,
            } as WorkflowContext,
            {
                events: {
                    UPDATE: (
                        hasAssignment: boolean,
                        hasParticipantsCount: boolean,
                        mustAcceptTerms: boolean,
                        mustAcceptEmailConsent: boolean,
                        bookingTimeframe: number,
                        serviceOnly: boolean,
                        hasAppointmentType: boolean,
                    ) => {
                        return {
                            hasAssignment,
                            hasParticipantsCount,
                            mustAcceptTerms,
                            mustAcceptEmailConsent,
                            bookingTimeframe,
                            serviceOnly,
                            hasAppointmentType,
                        };
                    },
                },
            },
        );

        const serviceOnly = this.appSettingsService.serviceOnly;

        let machineConfig: any = {
            id: 'booking',
            context: this.userModel.initialContext,
            initial: initialState,
            predictableActionArguments: true,
            states: {
                // service_only -> api gibt keine forms zurück; state wird genommen sonst geskipped
                service_only: {
                    on: {
                        UPDATE: [
                            {
                                actions: this.userModel.assign({
                                    mustAcceptTerms: (_: any, event: any) => {
                                        return event.mustAcceptTerms;
                                    },
                                    mustAcceptEmailConsent: (_: any, event: any) => {
                                        return event.mustAcceptEmailConsent;
                                    },
                                    serviceOnly: (_: any, event: any) => {
                                        return event.serviceOnly;
                                    },
                                }),
                            },
                        ],
                        NEXT: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                    },
                    meta: {
                        step: 1,
                        breadcrumbName: StateNamesEnum.SERVICE_ONLY,
                        backFunction: 'backToServiceOnly',
                        isDynamic: true,
                        serviceOnly: serviceOnly,
                    },
                },

                // service_only -> court_and_service wird geskipped
                court_and_service: {
                    on: {
                        UPDATE: [
                            {
                                actions: this.userModel.assign({
                                    hasAssignment: (_: any, event: any) => {
                                        return event.hasAssignment;
                                    },
                                    hasParticipantsCount: (_: any, event: any) => {
                                        return event.hasParticipantsCount;
                                    },
                                    mustAcceptTerms: (_: any, event: any) => {
                                        return event.mustAcceptTerms;
                                    },
                                    mustAcceptEmailConsent: (_: any, event: any) => {
                                        return event.mustAcceptEmailConsent;
                                    },
                                    bookingTimeframe: (_: any, event: any) => {
                                        return event.bookingTimeframe;
                                    },
                                    serviceOnly: (_: any, event: any) => {
                                        return event.serviceOnly;
                                    },
                                    hasAppointmentType: (_: any, event: any) => {
                                        return event.hasAppointmentType;
                                    },
                                }),
                            },
                        ],
                        NEXT: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                    },
                    meta: {
                        step: 1,
                        breadcrumbName: StateNamesEnum.COURT_AND_SERVICE,
                        backFunction: 'backToCourtAndService',
                        isDynamic: true,
                    },
                },

                appointment_type: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                            {
                                target: StateNamesEnum.DATE_FIRST,
                                cond: '!hasParticipantsCount',
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                    },
                    meta: {
                        step: 2,
                        breadcrumbName: 'dynamische_frontend_texte.terminart.breadcrumb_text',
                        backFunction: 'backToAppointmentType',
                    },
                },

                // service_only -> assignment_strategy wird geskipped
                assignment_strategy: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                            {
                                target: StateNamesEnum.DATE_FIRST,
                                cond: '!hasParticipantsCount',
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                    },
                    meta: {
                        step: 3,
                        breadcrumbName:
                            'dynamische_frontend_texte.verteilmechanismen.html_content.name_breadcrumb_text',
                        backFunction: 'backToAssignmentStrategy',
                    },
                },

                // service_only -> participant_count wird geskipped
                participant_count: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_ASSIGNMENT_STRATEGY: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                    },
                    meta: {
                        step: 4,
                        breadcrumbName: 'dynamische_frontend_texte.anzahl_teilnehmer.attribute_text.breadcrumb_text',
                        backFunction: 'backToParticipantsCount',
                    },
                },
                date_first: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.PERSONAL_DATA,
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        SWITCH_TO_TIME_FIRST: [
                            {
                                target: StateNamesEnum.TIME_FIRST,
                            },
                        ],
                        BACK_TO_SERVICE_ONLY: [
                            {
                                target: StateNamesEnum.SERVICE_ONLY,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                        BACK_TO_ASSIGNMENT_STRATEGY: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                        ],
                        BACK_TO_PARTICIPANT_COUNT: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                        ],
                    },
                    meta: {
                        step: 5,
                        breadcrumbName: 'dynamische_frontend_texte.datum_auswahl.html_content.breadcrumb_text',
                        backFunction: 'backToDateFirst',
                        isDynamic: true,
                    },
                },

                // service_only -> time_first wird geskipped
                time_first: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.PERSONAL_DATA,
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                        SWITCH_TO_DATE_FIRST: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                        BACK_TO_ASSIGNMENT_STRATEGY: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                        ],
                        BACK_TO_PARTICIPANT_COUNT: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                        ],
                    },
                    meta: {
                        step: 5,
                        breadcrumbName: 'dynamische_frontend_texte.zeit_auswahl.html_content.breadcrumb_text',
                        backFunction: 'backToDateFirst',
                        isDynamic: true,
                    },
                },

                // service_only -> api bekommt nur generellen service
                // -> tage kommen zurück wie bisher
                // -> tag ist ausgewählt
                // -> api bekommt nur tag und generellen service
                // -> dabei wird court bestimmt
                // -> api für config wird erneut angefragt, dies mit court - service
                // -> Form kommen zurück
                personal_data: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.SUMMARY,
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                        BACK_TO_SERVICE_ONLY: [
                            {
                                target: StateNamesEnum.SERVICE_ONLY,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                        BACK_TO_ASSIGNMENT_STRATEGY: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                        ],
                        BACK_TO_PARTICIPANT_COUNT: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                        ],
                        BACK_TO_DATE_FIRST: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                    },
                    meta: {
                        step: 6,
                        breadcrumbName: 'dynamische_frontend_texte.breadcrumb.html_content.persoenliche_daten',
                        backFunction: 'backToPersonalData',
                    },
                },
                summary: {
                    on: {
                        NEXT: [
                            {
                                target: StateNamesEnum.THANK_YOU,
                            },
                        ],
                        BACK: [
                            {
                                target: StateNamesEnum.PERSONAL_DATA,
                            },
                        ],
                        BACK_TO_SERVICE_ONLY: [
                            {
                                target: StateNamesEnum.SERVICE_ONLY,
                            },
                        ],
                        BACK_TO_COURT_AND_SERVICE: [
                            {
                                target: StateNamesEnum.COURT_AND_SERVICE,
                            },
                        ],
                        BACK_TO_APPOINTMENT_TYPE: [
                            {
                                target: StateNamesEnum.APPOINTMENT_TYPE,
                                cond: 'hasAppointmentType',
                            },
                        ],
                        BACK_TO_ASSIGNMENT_STRATEGY: [
                            {
                                target: StateNamesEnum.ASSIGNMENT_STRATEGY,
                                cond: 'hasAssignment',
                            },
                        ],
                        BACK_TO_PARTICIPANT_COUNT: [
                            {
                                target: StateNamesEnum.PARTICIPANT_COUNT,
                                cond: 'hasParticipantsCount',
                            },
                        ],
                        BACK_TO_DATE_FIRST: [
                            {
                                target: StateNamesEnum.DATE_FIRST,
                            },
                        ],
                        BACK_TO_PERSONAL_DATA: [
                            {
                                target: StateNamesEnum.PERSONAL_DATA,
                            },
                        ],
                        BACK_TO_SUMMARY: [
                            {
                                target: StateNamesEnum.SUMMARY,
                            },
                        ],
                    },
                    meta: {
                        step: 7,
                        breadcrumbName: 'dynamische_frontend_texte.breadcrumb.html_content.zusammenfassung',
                        backFunction: 'backToSummary',
                    },
                },
                thank_you: {
                    type: 'final',
                    meta: {
                        step: 8,
                        breadcrumbName: 'dynamische_frontend_texte.breadcrumb.html_content.zusammenfassung',
                    },
                },
            },
            entry: this.userModel.assign({
                hasAssignment: true,
                hasParticipantsCount: true,
                mustAcceptTerms: true,
                mustAcceptEmailConsent: true,
                bookingTimeframe: 3,
                serviceOnly: false,
                hasAppointmentType: false,
            }),
        };

        if (machineConfigStorage) {
            try {
                machineConfig = JSON.parse(machineConfigStorage);
                machineConfig.predictableActionArguments = true;
                machineConfig.entry[0].assignment = machineConfig.context;
                machineConfig.entry.assignment = machineConfig.context;
            } catch (e) {
                this.sessionStorageService.clearWorkflowAndBooking();
                console.error('Corrupted machine config object found');
                console.error(e);
            }
        }
        machineConfig.initial = initialState;

        return this.userModel.createMachine(machineConfig, {
            guards: {
                hasAssignment: (context: any) => context.hasAssignment === true,
                hasAppointmentType: (context: any) => context.hasAppointmentType === true,
                hasParticipantsCount: (context: any) => context.hasParticipantsCount === true,
                '!hasParticipantsCount': (context: any) => context.hasParticipantsCount === false,
            },
        });
    }

    updateMachineNoAgency(serviceId: string = '2'): Observable<boolean> {
        this.handleNoAgencyState(serviceId);
        return of(true);
    }

    updateMachine(serviceId: string = '2', agencyId: string): Observable<boolean> {
        this.updated$.next(false);

        const workflowResponse$ = this.workflows.getApiPublicWorkflows({
            agency_id: agencyId,
            service_id: serviceId,
            _locale: this.locale.toLowerCase() as LanguageISOType,
        });

        workflowResponse$.subscribe((workflowResponse: WorkflowResponse) => {
            const data = workflowResponse.data;
            const settings = data.settings;
            let service_only = false;
            let hasAppointmentType = false;
            // Todo: refactor to own service
            if (
                (this.bookingService.booking.service && !this.bookingService.booking.agency) ||
                this.appSettingsService.serviceOnly
            ) {
                settings.has_assignment = false;
                settings.has_participants_count = false;
                settings.booking_timeframe = 3;
                service_only = true;
            }
            if (this.appSettingsService.onlineService && this.bookingService.booking.service?.meeting_type == 'both') {
                hasAppointmentType = true;
            }
            this.interpreter.send(
                'UPDATE',
                this.userModel.events.UPDATE(
                    settings.has_assignment,
                    settings.has_participants_count,
                    settings.must_accept_terms,
                    settings.must_accept_email_consent,
                    settings.booking_timeframe,
                    service_only,
                    hasAppointmentType,
                ),
            );
            this.stateUpdatedSubject.next(true);
            this.updated$.next(true);

            // Configure assignment strategy
            if (settings.has_assignment) {
                const newStrategy = this.assignmentStrategyService.createStrategyFromAssignment(data.assignment);
                //todo: do we need this if?????
                //if (newStrategy.strategyName !== this.bookingService.booking.strategy.strategyName) {
                this.bookingService.booking.strategy = newStrategy;
                //}
            } else {
                this.bookingService.booking.strategy = new StrategyRandom();
            }
            this.bookingService.booking.hasAssignmentStrategy = settings.has_assignment;
            this.bookingService.booking.nextRelease = workflowResponse.data.next_release;
            this.bookingService.booking.hasParticipantsCount = settings.has_participants_count;
            if (settings.has_participants_count && workflowResponse.data.automatic_extension) {
                this.bookingService.booking.automaticExtension = workflowResponse.data.automatic_extension;
                this.bookingService.booking.aESignal.set(this.bookingService.booking.automaticExtension);
            }
            // we add form data here
            this.personalDataService.personalDataForm.next(data.forms);
        });

        return this.updated$;
    }

    handleNoAgencyState(_serivceId: string): void {
        this.setupMachine(StateNamesEnum.SERVICE_ONLY);
    }

    getCurrentStepNumber(): number {
        const myEndRange: number = this.getCurrentStepMeta();
        return this.calculateStep(myEndRange);
    }

    private getCurrentStepMeta(): number {
        return this.interpreter.state.meta[this.interpreter.machine.id + '.' + this.interpreter.state.value].step;
    }

    getCurrentState(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.state;
    }

    getStepCount(): number {
        return this.calculateStep(this.interpreter.machine.states[StateNamesEnum.THANK_YOU].meta.step);
    }

    next(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'NEXT' });
    }

    back(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK' });
    }

    switchToTimeFirst(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'SWITCH_TO_TIME_FIRST' });
    }

    switchToDateFirst(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'SWITCH_TO_DATE_FIRST' });
    }

    backToServiceOnly(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_SERVICE_ONLY' });
    }

    backToCourtAndService(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_COURT_AND_SERVICE' });
    }

    backToAssignmentStrategy(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_ASSIGNMENT_STRATEGY' });
    }

    backToAppointmentType(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_APPOINTMENT_TYPE' });
    }

    backToParticipantsCount(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_PARTICIPANT_COUNT' });
    }

    backToDateFirst(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_DATE_FIRST' });
    }

    backToPersonalData(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_PERSONAL_DATA' });
    }

    backToSummary(): State<WorkflowContext, WorkflowEvent, any, WorkFlowTypeState, any> {
        return this.interpreter.send({ type: 'BACK_TO_SUMMARY' });
    }

    private calculateStep(endRange: number): number {
        let range = Utils.range(endRange, 1);
        if (!this.interpreter.state.context.hasAssignment) {
            range = range.filter((el) => 2 !== el);
        }
        if (!this.interpreter.state.context.hasParticipantsCount) {
            range = range.filter((el) => 3 !== el);
        }
        return range.length;
    }

    getActiveSteps(): StateNamesEnum[] {
        const currentStepMeta = this.getCurrentStepMeta();
        const breadcrumbs: Breadcrumb[] = [];
        const activeSteps: StateNamesEnum[] = [];
        const currentState = this.interpreter.getSnapshot();
        const serviceOnlyActive = this.appSettingsService.serviceOnly;
        for (const state in this.interpreter.machine.states) {
            const stateNode = this.interpreter.machine.states[state];
            const metaData: DynamicBreadcrumbItem = stateNode.meta;
            const stepOfItem: number = metaData.step;

            if (stepOfItem <= currentStepMeta) {
                if (!metaData.breadcrumbName) {
                    continue;
                }

                // Skip breacrumb for agency & service selection when service only is on
                if (serviceOnlyActive) {
                    if (stateNode.key === StateNamesEnum.COURT_AND_SERVICE) {
                        continue;
                    }
                } else {
                    if (stateNode.key === StateNamesEnum.SERVICE_ONLY) {
                        continue;
                    }
                }
                if (stateNode.key === StateNamesEnum.APPOINTMENT_TYPE && !currentState.context.hasAppointmentType) {
                    continue;
                }
                if (
                    (stateNode.key === StateNamesEnum.ASSIGNMENT_STRATEGY && !currentState.context.hasAssignment) || // skip when assignment strategy is not active
                    (stateNode.key === StateNamesEnum.ASSIGNMENT_STRATEGY && serviceOnlyActive) // skip assignment strategy when service only is on
                ) {
                    continue;
                }
                if (
                    (stateNode.key === StateNamesEnum.PARTICIPANT_COUNT &&
                        !currentState.context.hasParticipantsCount) || // skip when participants_count is not active
                    (stateNode.key === StateNamesEnum.PARTICIPANT_COUNT && serviceOnlyActive) // skip participants when service only is on
                ) {
                    continue;
                }

                if (
                    (stateNode.id === 'booking.time_first' && currentState.value === StateNamesEnum.DATE_FIRST) ||
                    (stateNode.id === 'booking.date_first' && currentState.value === StateNamesEnum.TIME_FIRST)
                ) {
                    continue;
                }
                const bc = this.breadcrumbService.createByCurrentStateMachine(
                    metaData,
                    <StateNamesEnum>state,
                    currentStepMeta,
                );
                breadcrumbs.push(bc);
                activeSteps.push(state as StateNamesEnum);
            }
        }

        this.breadcrumbService.activeBreadCrumbs.next(this.filterSameBreadcrumbEntry(breadcrumbs));

        return this.filterSameStepEntry(activeSteps);
    }

    private filterSameBreadcrumbEntry(steps: Breadcrumb[]): Breadcrumb[] {
        const currentStates = steps.map((bc) => bc.url);
        if (currentStates.includes(StateNamesEnum.DATE_FIRST) && currentStates.includes(StateNamesEnum.TIME_FIRST)) {
            steps = steps.filter((step) => step.url !== StateNamesEnum.TIME_FIRST);
        }
        return steps;
    }

    private filterSameStepEntry(steps: StateNamesEnum[]): StateNamesEnum[] {
        if (steps.includes(StateNamesEnum.DATE_FIRST) && steps.includes(StateNamesEnum.TIME_FIRST)) {
            steps = steps.filter((step) => step !== StateNamesEnum.TIME_FIRST);
        }
        return steps;
    }

    detectDirection(newURL: string, oldURL: string): string {
        const states = this.interpreter.machine.states;
        const stateNewSplit = newURL.split('/');
        const stateNew = stateNewSplit[stateNewSplit.length - 1];
        const stateOldSplit = oldURL.split('/');
        const stateOld = stateOldSplit[stateOldSplit.length - 1];
        if (!states[stateNew] || !states[stateOld]) {
            return '';
        }
        const orderNumber = states[stateNew]?.order - states[stateOld]?.order;
        if (orderNumber > 0) {
            return 'forward';
        } else if (orderNumber < 0) {
            return 'backward';
        }
        return 'nochange';
    }
}
