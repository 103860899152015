<nav aria-label="Prozessschritte" class="breadcrumb-container mb-4 d-flex flex-wrap" *ngIf="showBreadcrumb">
    <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs; last as isLast">
            <otb-breadcrumb-item
                [breadcrumb]="breadcrumb"
                [isLast]="isLast"
                [attr.aria-current]="isLast ? 'step' : null"
            >
            </otb-breadcrumb-item>
        </li>
    </ol>
</nav>
