import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingService } from '../../services/booking/booking.service';
import { StateService } from '../../services/state/state.service';

@Component({
    selector: 'otb-appointment-type',
    templateUrl: './appointment-type.component.html',
    styleUrls: ['./appointment-type.component.scss']
})
export class AppointmentTypeComponent implements OnInit {
    appointmentTypeFormGroup: UntypedFormGroup;
    appointmentTypeOptions: Array<{ name: string; value: string }> = [
        {
            name: 'online',
            value: 'Online'
        },
        {
            name: 'offline',
            value: 'Vor Ort'
        }
    ];
    selectedType: any | undefined;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private formBuilder: UntypedFormBuilder,
        private bookingService: BookingService,
        private stateService: StateService
    ) {}

    ngOnInit(): void {
        const defaultAppointmentType = 'online';

        this.appointmentTypeFormGroup = this.formBuilder.group({
            type: [defaultAppointmentType, Validators.required]
        });

        this.bookingService.booking.appointmentType = defaultAppointmentType;
    }

    onAppointmentTypeSelect(type: { name: string; value: string }): void {
        this.selectedType = type.name;
        this.bookingService.booking.appointmentType = this.selectedType;
    }

    onSubmit(): void {
        if (this.appointmentTypeFormGroup.valid) {
            this.stateService.next();
        } else {
            this.formAppointmentType.markAsDirty();
        }
    }

    back(): void {
        this.stateService.back();
    }

    get formAppointmentType(): AbstractControl {
        return this.appointmentTypeFormGroup.get('type') as AbstractControl;
    }
}
