import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Breadcrumb } from '../model/breadcrumb';
import { StateNamesEnum } from '../../../enum/state-names-enum';
import { StateService } from '../../../services/state/state.service';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BookingService } from '../../../services/booking/booking.service';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';

@Component({
    selector: 'otb-inner-breadcrumb',
    templateUrl: './inner-breadcrumb.component.html',
    styleUrls: ['./inner-breadcrumb.component.scss'],
    standalone: false,
})
export class InnerBreadcrumbComponent implements OnDestroy, OnInit {
    @ViewChild('breadcrumbContainer') bcContainer: ElementRef;
    subs: Subscription = new Subscription();
    showBreadcrumb: boolean = true;
    breadcrumbs: Breadcrumb[] = [];

    pagesToHide: Array<string> = [
        StateNamesEnum.SERVICE_ONLY,
        StateNamesEnum.COURT_AND_SERVICE,
        StateNamesEnum.THANK_YOU,
        StateNamesEnum.CANCEL,
        StateNamesEnum.CONFIRMATION,
    ];

    constructor(
        private stateService: StateService,
        private breadcrumbService: BreadcrumbService,
        private route: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private bookingService: BookingService,
        private appSettingService: ApplicationSettingsService,
    ) {
        const routeEvents$ = this.route.events.subscribe((el: Event) => {
            if (!(el instanceof NavigationEnd)) {
                return;
            }

            const hideAtCurrentPage = this.pagesToHide.some((page) => {
                return el.urlAfterRedirects.indexOf(page) > -1;
            });
            this.showBreadcrumb = !hideAtCurrentPage;
        });

        const bcSub$ = this.breadcrumbService.activeBreadCrumbs.subscribe((breadcrumbs: Breadcrumb[]) => {
            if (!breadcrumbs) {
                return;
            }
            this.breadcrumbs = breadcrumbs;

            if (!this.appSettingService.serviceOnly) {
                this.breadcrumbs.unshift(this.breadcrumbService.createInitialBreadcrumb(this.bookingService.booking));
            }
        });
        this.subs.add(bcSub$);
        this.subs.add(routeEvents$);
    }

    ngOnInit(): void {
        this.stateService.machineStarted$.next(true);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(_event: Event): void {
        this.changeDetectorRef.detectChanges();
        this.breadcrumbService.recalculateTopContainerHeight(this.bcContainer);
    }
}
