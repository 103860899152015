import { ElementRef, HostListener, Injectable } from '@angular/core';
import { Breadcrumb } from '../../components/breadcrumb/model/breadcrumb';
import { StateNamesEnum } from '../../enum/state-names-enum';
import { Booking } from '../../model/booking';
import { BookingService } from '../booking/booking.service';
import { DynamicBreadcrumbItem } from '../../components/breadcrumb/interface/DynamicBreadcrumbItem';
import { BehaviorSubject } from 'rxjs';
import { StateService } from '../state/state.service';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    booking: Booking;
    breadcrumbHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    innerWidth: number;
    activeBreadCrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

    constructor(private bookingService: BookingService) {}

    createInitialBreadcrumb(booking: Booking): Breadcrumb {
        const breadcrumb = new Breadcrumb();
        breadcrumb.name = booking.agency?.name ?? '';
        breadcrumb.backFunction = 'backToCourtAndService';
        breadcrumb.url = StateNamesEnum.COURT_AND_SERVICE;
        return breadcrumb;
    }

    buildNameForDateAndTime(defaultName: string, stepNumber: number): string {
        if (this.bookingService.booking.date && this.bookingService.booking.time && stepNumber > 4) {
            // TODO find a better way to globally set the locale
            return (
                this.bookingService.booking.date.toLocaleString('de-DE').split(',')[0] +
                ' / ' +
                this.bookingService.booking.time
            );
        }

        return defaultName;
    }

    createByCurrentStateMachine(
        metaData: DynamicBreadcrumbItem,
        stateName: StateNamesEnum,
        stepNumber: number,
    ): Breadcrumb {
        if (!metaData.breadcrumbName) {
            metaData.breadcrumbName = '';
        }
        if (!metaData.backFunction) {
            metaData.backFunction = '';
        }

        const breadcrumb = new Breadcrumb();
        breadcrumb.name = metaData.isDynamic
            ? this.getDynamicValueByName(stateName, metaData.breadcrumbName, stepNumber)
            : metaData.breadcrumbName;

        breadcrumb.url = stateName;
        breadcrumb.backFunction = metaData.backFunction;
        return breadcrumb;
    }

    private getDynamicValueByName(stateKey: StateNamesEnum, defaultName: string, stepNumber: number): string {
        let breadcrumbName = '';
        switch (stateKey) {
            case StateNamesEnum.SERVICE_ONLY:
                breadcrumbName = this.bookingService.booking.service?.name ?? '';
                break;
            case StateNamesEnum.COURT_AND_SERVICE:
                breadcrumbName = this.bookingService.booking.service?.name ?? '';
                break;
            case StateNamesEnum.DATE_FIRST:
                breadcrumbName = this.buildNameForDateAndTime(defaultName, stepNumber);
                break;
            case StateNamesEnum.TIME_FIRST:
                breadcrumbName = this.buildNameForDateAndTime(defaultName, stepNumber);
                break;
            default:
                breadcrumbName = stateKey;
        }

        return breadcrumbName;
    }

    recalculateTopContainerHeight(bcContainer: ElementRef | undefined): void {
        if (!bcContainer || innerWidth >= 768) {
            return;
        }

        if (bcContainer.nativeElement.offsetHeight !== this.breadcrumbHeight.value) {
            if (bcContainer.nativeElement.offsetHeight > 0) {
                this.breadcrumbHeight.next(bcContainer.nativeElement.offsetHeight);
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(_event: Event): void {
        this.innerWidth = window.innerWidth;
    }

    initialize(response: any, stateService: StateService): void {
        if (response[1] && response[1].type === 'xstate.stop') {
            return;
        }
        stateService.getActiveSteps();
    }
}
