@if (showBreadcrumb) {
    <nav aria-label="Prozessschritte" class="breadcrumb-container mb-4 d-flex flex-wrap">
        <ol class="breadcrumb">
            @for (breadcrumb of breadcrumbs; track breadcrumb.name; let isLast = $last) {
                <li>
                    <otb-breadcrumb-item
                        [breadcrumb]="breadcrumb"
                        [isLast]="isLast"
                        [attr.aria-current]="isLast ? 'step' : null"
                    >
                    </otb-breadcrumb-item>
                </li>
            }
        </ol>
    </nav>
}
