<div class="upper__container mb-5 mb-sm-2">
    <p
        [innerHTML]="
            'dynamische_frontend_texte.datum_auswahl.auswahl' | translate | replaceDateTime: selectedDate | async
        "
        class="mb-0"
    ></p>
    <p [innerHTML]="'dynamische_frontend_texte.datum_auswahl.label_zeit_auswahl' | translate | safeStyle"></p>
</div>

<div class="times-container">
    <ng-container *ngFor="let availableTime of availableAgenciesSelection">
        <div class="col-12 mb-3">
            <a
                class="time-single-container"
                tabindex="0"
                [attr.title]="
                    'dynamische_frontend_texte.datum_auswahl.zeit_auswahl_eintrag_title'
                        | translate
                        | replaceDateTime: availableTime.time.time
                        | async
                "
                (click)="timeClick(availableTime.time, availableTime, $event)"
            >
                <span
                    class="time"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: availableTime.time.time
                            | async
                    "
                ></span>
                <div class="spacer">|</div>
                <span class="court fw-bold" [innerHTML]="availableTime.name"></span>
                <ng-container *ngIf="availableTime.address">
                    ,
                    <span class="court-adress" [innerHTML]="availableTime.address"></span>
                </ng-container>
            </a>
        </div>
    </ng-container>
    <ng-container *ngIf="availableAgenciesSelection.length <= 0">
        <p
            class="mb-0 fw-bold"
            [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.auswahl_leer' | translate | safeStyle"
        ></p>
    </ng-container>
</div>

<div class="continue-container mt-4" *ngIf="timeSelected">
    <div class="selected-date">
        Ihr Wunschtermin:
        <span class="fw-bold">{{ buildSelectedValuesText() }}</span>
    </div>
    <div class="d-flex justify-content-end mt-4" *ngIf="timeSelected">
        <button
            type="button"
            aria-label="Im Buchungsprozess fortfahren"
            class="btn btn-primary"
            [disabled]="!submitBtnActive"
            (click)="submit()"
        >
            Weiter
        </button>
    </div>
</div>
<div class="filter-container mt-4">
    <h5 [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.filter_nach_dienststelle' | translate | safeStyle">
        Sie möchten lieber anhand Ihrer Dienststelle einen freien Termin buchen?
    </h5>
    <label
        for="citizen-agency"
        [innerHTML]="'dynamische_frontend_texte.landing.label_dropdown_dienststelle' | translate | star | safeStyle"
    ></label>
    <ng-select
        id="citizen-agency"
        [items]="availableAgenciesSelectionTransformed"
        bindLabel="name"
        bindValue="id"
        (change)="filterAvailableAgencies($event)"
        [clearable]="false"
        placeholder="Dienststelle auswählen"
        [(ngModel)]="selectedAgency"
    ></ng-select>
</div>
