<div class="row final-page">
    <div class="col-12">
        <h1
            *otbShowInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.danke.ueberschrift' | translate | safeStyle"
        ></h1>
        <h2
            *otbHideInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.danke.ueberschrift' | translate | safeStyle"
        ></h2>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.line1' | translate | safeStyle"></p>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.line2' | translate | safeStyle"></p>
    </div>
</div>
