import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DynamicFormService {
    private validator$ = new BehaviorSubject<boolean>(false);
    readonly setValidation$ = this.validator$.asObservable();

    constructor() {}

    setValidation(validator: boolean): void {
        this.validator$.next(validator);
    }
}
