import { Component, Input } from '@angular/core';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'otb-checkbox-field',
    templateUrl: './checkbox-field.component.html',
    styleUrls: ['./checkbox-field.component.scss']
})
export class CheckboxFieldComponent extends FieldBase {
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;

    @Input() formNumber: number = 0;

    isChecked(option: string): boolean {
        const values: string[] = this.form.controls[this.formField.name].value;
        return values.includes(option);
    }

    onCheckChange($event: any): void {
        const control: AbstractControl = this.form.controls[this.formField.name];

        let currentArray: string[] = control.value;
        if ($event.target?.checked) {
            currentArray.push($event.target?.value);
        } else {
            currentArray = currentArray.filter((element) => element !== $event.target?.value);
        }
        control.setValue(currentArray);
    }
}
