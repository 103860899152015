<a
    (click)="back()"
    (keydown.enter)="back()"
    (keydown.space)="back()"
    class="btn-secondary btn pull-left order-md-1 order-2"
    role="button"
    accesskey="z"
    tabindex="0"
    [innerHTML]="translatedText"
>
</a>
