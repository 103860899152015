import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'safeStyle',
    standalone: false,
})
export class SafeStylePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(html: string): SafeHtml {
        const allowedStyles = [
            'color',
            'background-color',
            'font-size',
            'text-align',
            'text-decoration',
            'line-height',
        ];

        const wrapper = document.createElement('div');
        wrapper.innerHTML = html;

        const allElements = wrapper.getElementsByTagName('*');

        for (let i = 0; i < allElements.length; i++) {
            const element = allElements[i];
            const style = element.getAttribute('style');

            if (style) {
                const filteredStyles = style.split(';').filter((style) => {
                    const [property] = style.split(':');
                    return allowedStyles.includes(property.trim());
                });

                const newStyle = filteredStyles.join(';');
                element.setAttribute('style', newStyle);
            }
        }

        const sanitizedHtml = wrapper.innerHTML;
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
    }
}
