import { Component, Input } from '@angular/core';
import { FieldBase } from '../field.base';
import { FormField } from '../../../../../../api/models/form-field';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'otb-text-area-field',
    templateUrl: './text-area-field.component.html',
    styleUrls: ['./text-area-field.component.scss']
})
export class TextAreaFieldComponent extends FieldBase {
    @Input() formField: FormField;
    @Input() form: UntypedFormGroup;
    @Input() formNumber: number = 0;
}
