export enum StateNamesEnum {
    SERVICE_ONLY = 'service_only',
    COURT_AND_SERVICE = 'court_and_service',
    DATE_FIRST = 'date_first',
    TIME_FIRST = 'time_first',
    ASSIGNMENT_STRATEGY = 'assignment_strategy',
    PARTICIPANT_COUNT = 'participant_count',
    PERSONAL_DATA = 'personal_data',
    SUMMARY = 'summary',
    THANK_YOU = 'thank_you',
    CANCEL = 'cancel',
    CONFIRMATION = 'confirmation',
    APPOINTMENT_TYPE = 'appointment_type'
}
