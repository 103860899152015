<div class="row">
    <div class="col-12">
        <ngx-skeleton-loader [theme]="{ width: '75%', height: '40px' }"></ngx-skeleton-loader>
    </div>
    <div class="col-12">
        <ngx-skeleton-loader [theme]="{ width: '80%' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ width: '55%' }"></ngx-skeleton-loader>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
    </div>
    <div class="col-6">
        <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
    </div>
</div>
