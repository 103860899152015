import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerTranslation } from '../../../../../../interfaces/translation-data';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    translations: DatepickerTranslation = {
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        monthNames: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        selectMonth: 'Monat wählen',
        selectYear: 'Jahr wählen',
        previousMonth: 'Vorheriger Monat',
        nextMonth: 'Nächster Monat',
        today: 'Heute',
    };

    getMonthShortName(month: number): string {
        return this.translations.monthNamesShort[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.translations.monthNames[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}.${date.month}.${date.year}`;
    }

    getWeekdayLabel(weekday: number): string {
        return this.translations.dayNamesShort[weekday - 1];
    }
}
