import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '../../services/progress-bar/progress-bar.service';

@Component({
    selector: 'otb-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: [
        './progress-bar.component.scss',
        './progress-bar.component.mags-sop.scss',
        './progress-bar.component.sh.scss'
    ]
})
export class ProgressBarComponent implements OnDestroy {
    currentStep: number = 0;
    totalSteps: number = 5;
    progress: string = '';
    show: boolean = true;
    subs: Subscription = new Subscription();

    constructor(private progressbarService: ProgressBarService) {
        this.progress = this.progressbarService.progress;
        this.currentStep = this.progressbarService.currentStep;
        this.totalSteps = this.progressbarService.totalSteps;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
