<div [formGroup]="form">
    <textarea
        [formControlName]="fieldName"
        [id]="fieldName"
        [placeholder]="formField.placeholder"
        class="form-control"
        [ngClass]="{ 'is-invalid': !isValid && (isDirty || isTouched) }"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        [attr.aria-labelledby]="fieldName + '-label'"
        [attr.aria-describedby]="
            fieldName + '-error-required ' + fieldName + '-error-maxlength ' + fieldName + '-error-nohtmlcharacters'
        "
    ></textarea>
    <otb-form-error-message
        [id]="fieldName + '-error-required'"
        class="required"
        [show]="!isValid && (isDirty || isTouched) && errors?.['required']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.textbereich_nicht_leer' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-maxlength'"
        class="max-length"
        [show]="!isValid && (isDirty || isTouched) && errors?.['maxlength'] !== undefined"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.text_area_max_length' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-nohtmlcharacters'"
        class="max-length"
        [show]="!isValid && (isDirty || isTouched) && errors?.['nohtmlcharacters'] !== undefined"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.has_no_html' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-dinSpecInvalid'"
        [show]="!isValid && (isDirty || isTouched) && errors?.['dinSpecInvalid']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.din_spec_91379' | translate"
    >
    </otb-form-error-message>
</div>
