import { Strategy } from './strategy';
import { SelectOptionType } from '../../../pages/assignment-strategy/types';
import { Assignment } from '../../../api/models/assignment';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { PersonalDataForm } from '../../../api/models/personal-data-form';
import { DinSpec91379Service } from '../../../pages/personal-data/dynamic-form/form-group-factory-service/form-field-validator-mapper-service/custom-validators/dinSpec91379/din-spec-91379.service';
import { StrategySessionStorageParserService } from './strategy-session-storage-parser.service';

export class StrategyFileNumber implements Strategy {
    strategyName: string = 'aktenzeichen';
    notice: string = '';
    departmentNumbers: Array<string> = [];
    registrationMarks: Array<string> = [];
    fileNumberPart: string;
    years: Array<string> = [];
    addition = '';
    selectedDepartmentNumber: SelectOptionType;
    selectedRegistrationMark: SelectOptionType;
    selectedYear: SelectOptionType;
    fileNumberFormField = '';

    setDataFromAssignment(assignment: Assignment): void {
        if (assignment.file_number_settings) {
            this.departmentNumbers = assignment.file_number_settings.department_numbers;
            this.registrationMarks = assignment.file_number_settings.registration_marks;
            this.fileNumberPart = StrategySessionStorageParserService.getFileNumberPart() ?? '';
            this.selectedDepartmentNumber = StrategySessionStorageParserService.getFileSelectedDepartmentNumber() ?? {
                value: '',
                label: '',
            };
            this.selectedRegistrationMark = StrategySessionStorageParserService.getFileSelectedRegistrationMark() ?? {
                value: '',
                label: '',
            };
            this.selectedYear = StrategySessionStorageParserService.getFileSelectedYear() ?? { value: '', label: '' };
            this.years = this.getYears();
            this.addition = assignment.file_number_settings.addition;
            this.notice = assignment.file_number_settings.notice;
            this.fileNumberFormField = assignment.file_number_settings.label;
        }
    }

    getYears(): Array<string> {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 20; i >= 0; i--) {
            years.push((currentYear - i).toString().substr(-2));
        }
        return years;
    }

    buildForm(personalDataForm: PersonalDataForm, dinSpec91379Service: DinSpec91379Service): FormGroup<any> {
        const currentForm = new FormGroup<any>({
            departmentNumbers: new FormControl(this.departmentNumbers, Validators.required),
            registrationMarks: new FormControl(this.registrationMarks, Validators.required),
            fileNumber: new FormControl(this.fileNumberPart, Validators.required),
            years: new FormControl(this.years, Validators.required),
        });

        if (this.addition && this.addition.trim() !== '') {
            currentForm.addControl('addition', new FormControl(this.addition, Validators.required));
        }

        return currentForm;
    }

    markDirty(form: UntypedFormGroup): void {
        form.get('departmentNumbers')?.markAsDirty();
        form.get('registrationMarks')?.markAsDirty();
        form.get('fileNumber')?.markAsDirty();
        form.get('years')?.markAsDirty();
        form.get('addition')?.markAsDirty();
    }
}
