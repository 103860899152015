import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    isDevMode,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    Signal,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from './services/state/state.service';
import { Workflow } from './api/models/workflow';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiConfiguration } from './api/api-configuration';
import { TranslationState } from './services/translate-api/translation-state';
import { DateInputService } from './services/date-input/date-input.service';
import { LanguageService } from './services/language/language.service';
import { ProgressBarService } from './services/progress-bar/progress-bar.service';
import { SessionStorageService } from './services/session-storage/session-storage.service';
import { StateNamesEnum } from './enum/state-names-enum';
import { LocationStrategy } from '@angular/common';
import { BookingService } from './services/booking/booking.service';
import { ReservationAgent } from './services/appointment/reservation.agent';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { environment } from '../environments/environment';
import { TopBreadcrumbComponent } from './components/breadcrumb/top-breadcrumb/top-breadcrumb.component';
import { RedirectService } from './services/redirect/redirect.service';
import { DynamicLocaleId } from './services/language/DynamicLocaleId';
import { TranslateService } from '@ngx-translate/core';
import { WebcomponentInterfaceService } from './services/webcomponent-interface/webcomponent-interface.service';
import { ApplicationSettingsService } from './api/services';
import { ApplicationSettingsResponse } from './api/models/application-settings-response';
import { ApplicationSettingsService as AppSettingsService } from './services/application-settings/application-settings.service';
import { ContentLinksService } from './services/content-links/content-links.service';

@Component({
    selector: 'otb-root',
    templateUrl: './otb.component.html',
    styleUrls: ['./otb.component.scss'],
    providers: [
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [TranslateService, LanguageService],
        },
    ],
    standalone: false,
})
export class OtbComponent implements OnInit, OnDestroy, AfterViewInit {
    env: string = environment.deploy_environment;
    title: string = 'otb-client';
    headlessMode: boolean = false;
    currentStateNumber: number = 0;
    breadcrumbsHeightMargin: string;
    theme: string = environment.deploy_environment ?? 'default';
    workflow: BehaviorSubject<Workflow>;
    applicationSettingsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    subs: Subscription = new Subscription();
    heightSub: Subscription = new Subscription();

    errorLoadingApplicationSettings: Signal<boolean>;

    @ViewChild('breadcrumbs') breadcrumbs: TopBreadcrumbComponent;

    constructor(
        private translationState: TranslationState,
        private apiConfiguration: ApiConfiguration,
        private router: Router,
        private stateService: StateService,
        private dateInputService: DateInputService,
        private languageService: LanguageService,
        private progressbarService: ProgressBarService,
        private sessionStorageService: SessionStorageService,
        private locationStrategy: LocationStrategy,
        private bookingService: BookingService,
        private ngZone: NgZone,
        private reservationAgent: ReservationAgent,
        private breadcrumbService: BreadcrumbService,
        private redirectService: RedirectService,
        private webcompInterface: WebcomponentInterfaceService,
        private applicationSettingsService: ApplicationSettingsService,
        private appSettingsService: AppSettingsService,
        private contentLinksService: ContentLinksService,
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // uncomment for debugging xstate
        // inspect({
        //     iframe: false
        // });

        if (false || isDevMode()) {
            console.log('%cUmgebung: ' + environment.deploy_environment, 'font-weight: bold; font-size: 1.2em;');
        }
        this.locationStrategy.onPopState((event: any) => {
            if (event.type === 'hashchange') {
                const direction = this.stateService.detectDirection(event.newURL, event.oldURL); // forward / backward
                if (direction === 'forward') {
                    this.stateService.next();
                } else if (direction === 'backward') {
                    this.stateService.back();
                }
            }
        });

        this.applicationSettingsLoaded = this.appSettingsService.applicationSettingsLoaded;
        this.errorLoadingApplicationSettings = this.appSettingsService.errorLoadingApplicationSettings;
    }

    ngOnInit(): void {
        if (this.webcompInterface.endpoint !== '') {
            this.apiConfiguration.rootUrl = this.webcompInterface.endpoint;
        } else {
            this.apiConfiguration.rootUrl = 'http://localhost';
        }

        // Handle language
        this.languageService.getAllLanguagesFromAPI();
        this.languageService.initLanguage(this.webcompInterface.language);
        this.redirectService.handleStaticRedirect();
        this.translationState.prepareState(this.languageService.getLanguageCodeFromStorage());

        const appSettings$ = this.appSettingsService.applicationSettingsLoaded.subscribe((loaded: boolean) => {
            if (loaded) {
                this.initApplication();
                this.renderer.removeClass(this.getHostElement(), 'grey-filter-active');
            } else {
                this.renderer.addClass(this.getHostElement(), 'grey-filter-active');
            }
        });
        this.subs.add(appSettings$);

        const observerOrNext = {
            next: (settings: ApplicationSettingsResponse): void => {
                const serviceOnlyResponse = settings.data.service_only;

                const serviceOnlyFromStorage = this.sessionStorageService.loadApplicationSettings()?.serviceOnly;
                if (serviceOnlyFromStorage !== undefined) {
                    // config for serviceOnly has changed
                    if (serviceOnlyFromStorage !== serviceOnlyResponse) {
                        sessionStorage.clear();
                    }
                }

                if (serviceOnlyResponse !== undefined) {
                    this.appSettingsService.serviceOnly = serviceOnlyResponse;
                }

                if (settings.data.design_frontend_settings !== undefined) {
                    this.appSettingsService.designFrontendSettingsSubject.next(settings.data.design_frontend_settings);
                }

                if (settings.data.show_easy_language_page !== undefined) {
                    this.appSettingsService.showEasyLanguagePageSubject.next(settings.data.show_easy_language_page);
                }

                if (settings.data.show_uploaded_logo !== undefined) {
                    this.appSettingsService.showUploadedLogoSubject.next(settings.data.show_uploaded_logo);
                }

                if (settings.data.show_cookie_notice !== undefined) {
                    this.appSettingsService.showCookieNoticeSubject.next(settings.data.show_cookie_notice);
                }

                if (settings.data.confirm_button !== undefined) {
                    this.appSettingsService.confirmButton = settings.data.confirm_button;
                }

                if (settings.data.mark_field_as_required !== undefined) {
                    this.appSettingsService.markFieldAsRequired.next(settings.data.mark_field_as_required);
                }

                this.sessionStorageService.persistApplicationSettings();
                this.appSettingsService.errorLoadingApplicationSettings.set(false);
                this.appSettingsService.applicationSettingsLoaded.next(true);
            },
            error: (err: any): void => {
                console.log(err);
                this.appSettingsService.errorLoadingApplicationSettings.set(true);
                this.appSettingsService.applicationSettingsLoaded.next(true);
            },
        };
        const applicationServiceHttp$ = this.applicationSettingsService
            .getApiPublicApplicationSettings({ _locale: 'de' }) // langauge is not imporant here
            .subscribe(observerOrNext);

        this.contentLinksService.getContentPages();
        this.subs.add(applicationServiceHttp$);
    }
    ngAfterViewInit(): void {
        if (this.headlessMode) {
            const MARGIN_TOP = 30;
            if (this.breadcrumbs) {
                this.breadcrumbsHeightMargin =
                    'margin-top: ' + (this.breadcrumbs.bcContainer.nativeElement.offsetHeight + MARGIN_TOP) + 'px';
            } else {
                this.breadcrumbsHeightMargin = 'margin-top: 50px';
            }

            this.heightSub = this.breadcrumbService.breadcrumbHeight.subscribe((height: number) => {
                if (height > 0) {
                    this.breadcrumbsHeightMargin = 'margin-top: ' + (height + MARGIN_TOP) + 'px';
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.heightSub.unsubscribe();
        this.subs.unsubscribe();
        if (this.webcompInterface.cancelBooking !== undefined) {
            this.webcompInterface.cancelBooking.unsubscribe();
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        const tagNames = event.composedPath().map((path: any) => path.tagName);
        if (!tagNames.includes('NGB-DATEPICKER')) {
            const target = event.composedPath()[0];
            this.dateInputService.closeAllCalender(target);
        }
    }

    initApplication(): void {
        this.router.initialNavigation(); // Manually triggering initial navigation for @angular/elements
        this.headlessMode = this.webcompInterface.headless === 'true';
        this.sessionStorageService.loadApplicationSettings();

        const machineStarted = this.stateService.machineStarted$.subscribe((_started) => {
            const stateTransition$ = this.stateService.state$.subscribe((response) => {
                const state = response[0];
                const event = response[1];

                if (
                    event.type === 'BACK_TO_COURT_AND_SERVICE' ||
                    event.type === 'BACK_TO_SERVICE_ONLY' ||
                    (event.type === 'BACK' && state.value === StateNamesEnum.COURT_AND_SERVICE)
                ) {
                    this.sessionStorageService.clearWorkflowAndBooking();
                }

                let config;

                if (event.type === 'UPDATE') {
                    config = {
                        hasAssignment: event.hasOwnProperty('hasAssignment')
                            ? event.hasAssignment
                            : state.context.hasAssignment,
                        hasParticipantsCount: event.hasOwnProperty('hasParticipantsCount')
                            ? event.hasParticipantsCount
                            : state.context.hasParticipantsCount,
                        mustAcceptTerms: event.hasOwnProperty('mustAcceptTerms')
                            ? event.mustAcceptTerms
                            : state.context.mustAcceptTerms,
                    };
                    state.context = event; // context will normally set AFTER transition, but we need it here for persisting the state
                }

                if (event.type !== 'xstate.init' && event.type !== 'NEXT') {
                    this.sessionStorageService.persistStateMachine(this.stateService.machine, config);
                }

                this.sessionStorageService.persistCurrentStateName(state.value.toString());
                this.sessionStorageService.persistBooking(this.bookingService.booking);
                this.sessionStorageService.persistApplicationSettings();

                if (this.pageIsStatic()) {
                    return;
                }

                this.router.navigate([state.value]);
                this.breadcrumbService.initialize(response, this.stateService);
            });

            this.subs.add(stateTransition$);
        });

        this.subs.add(machineStarted);

        this.progressbarService.init();
        this.stateService.init();
        this.webcompInterface.cancelBooking.subscribe(async (cancel: boolean) => {
            if (!cancel) {
                return;
            }

            this.ngZone.run(() => {
                this.sessionStorageService.clearWorkflowAndBooking();
                this.reservationAgent.cancelReservation();
                this.bookingService.clearBooking();
                this.stateService.init();
            });
        });

        this.currentStateNumber = this.stateService.getCurrentStepNumber();
    }

    private pageIsStatic(): boolean {
        const STATIC_PAGES: Array<StateNamesEnum | string> = [
            StateNamesEnum.CONFIRMATION,
            StateNamesEnum.CANCEL,
            'leichte_sprache',
            'barrierefreiheit',
        ];

        return Object.values(STATIC_PAGES).some((val) => {
            return window.location.href.indexOf(val) >= 0;
        });
    }

    private getHostElement(): HTMLElement {
        return this.el.nativeElement;
    }
}
