import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StateService } from '../state/state.service';
import { StateNamesEnum } from '../../enum/state-names-enum';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {
    totalSteps: number = 0;
    currentStep: number = 0;
    show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    progress: string = '0%';

    constructor(private stateService: StateService) {}

    init(): void {
        this.stateService.machineStarted$.subscribe((started) => {
            if (started) {
                this.stateService.state$.subscribe((response) => {
                    // todo: workaround
                    if (response[1] && response[1].type === 'xstate.stop') {
                        this.stateService.interpreter.send({ type: 'NEXT' });
                        return;
                    }
                    this.totalSteps = this.stateService.getStepCount();
                    this.currentStep = this.stateService.getCurrentStepNumber();
                    this.progress = (this.currentStep / this.totalSteps) * 100 + '%';

                    this.show.next(this.stateService.getCurrentState().value !== StateNamesEnum.THANK_YOU);
                });
            }
        });
    }
}
