export class ExceptionStackError extends Error {
    exception: any;

    constructor(message: string, exception: any) {
        super(message);
        this.exception = exception;
        this.name = this.constructor.name;
        Object.setPrototypeOf(this, ExceptionStackError.prototype);
    }
}
