<div class="row">
    <div class="col-12">
        <p class="addition">
            <span [innerHTML]="'dynamische_frontend_texte.landing.ueberschrift' | translate | safeStyle"></span>
        </p>
    </div>
    <otb-progress-bar></otb-progress-bar>
</div>
<form
    id="agency-service-selection"
    [formGroup]="agencyFormGroup"
    (ngSubmit)="onSubmit()"
    [attr.aria-label]="'dynamische_frontend_texte.barrierefreiheit.pflichtfeld_hinweis' | translate"
>
    <div class="row">
        <div class="col-12 mb-4">
            <ng-container
                *ngIf="
                    environment.deploy_environment === deployEnv.MAGS_SOP ||
                        environment.deploy_environment === deployEnv.HMDIS_INTERNAL;
                    else defaultHeader
                "
            >
                <h1
                    class="landing-intro"
                    [innerHTML]="'dynamische_frontend_texte.landing.einfuehrung' | translate | safeStyle"
                ></h1>
            </ng-container>
            <ng-template #defaultHeader>
                <h2
                    class="landing-intro"
                    [innerHTML]="'dynamische_frontend_texte.landing.einfuehrung' | translate | safeStyle"
                ></h2>
            </ng-template>
            <div
                class="mb-3"
                [innerHTML]="'dynamische_frontend_texte.barrierefreiheit.pflichtfeld_hinweis' | translate | safeStyle"
            ></div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <otb-agency-selection
                    [agencyFormGroup]="agencyFormGroup"
                    (agencyChange)="onAgencySelect($event)"
                ></otb-agency-selection>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label
                    for="citizen-service"
                    id="citizen-service-label"
                    [innerHTML]="
                        'dynamische_frontend_texte.landing.label_dropdown_leistung' | translate | star | safeStyle
                    "
                ></label>
                <ng-select
                    id="citizen-service"
                    [items]="servicesSelect"
                    bindLabel="name"
                    bindValue="id"
                    (change)="onServiceSelect($event)"
                    formControlName="service"
                    [clearable]="false"
                    placeholder="Dienstleistung auswählen"
                    [loading]="serviceSelectionAjaxLoading"
                    [loadingText]="'Dienstleistungen werden geladen...'"
                    aria-labelledby="citizen-service-label"
                ></ng-select>
                <otb-form-error-message [show]="formService.invalid && formService.dirty"></otb-form-error-message>
            </div>
        </div>
        <div [ngClass]="envCssClassesGenerator.serviceNoticeClasses">
            <div class="service-notice" [ngClass]="isEnvJustice ? 'mt-5' : 'mt-3'" *ngIf="selectedService">
                <div class="row">
                    <span
                        class="description-title"
                        [innerHTML]="
                            'dynamische_frontend_texte.landing.zusaetzliche_informationen' | translate | safeStyle
                        "
                    ></span>
                    <p [ngClass]="isEnvJustice ? 'mt-4' : 'mt-2'" [innerHTML]="selectedService.notice | safeStyle"></p>
                    <p>
                        <span
                            *ngIf="selectedService.url !== ''"
                            [innerHTML]="'dynamische_frontend_texte.landing.url' | translate | safeStyle"
                        ></span>
                        <a
                            *ngIf="selectedService.url !== ''"
                            class="ms-1"
                            [href]="selectedService.url"
                            [innerHTML]="selectedService.url"
                            [title]="selectedService.url"
                            aria-label="Verlinkung zur Dienstleistung - Dieser Link wird in einen neuen Tab geöffnet."
                            target="_blank"
                        ></a>
                    </p>
                </div>
                <div class="form-group d-flex justify-content-end">
                    <button
                        type="submit"
                        class="btn-success btn rounded-0"
                        accesskey="w"
                        [title]="'dynamische_frontend_texte.landing.button_text' | translate"
                        [value]="'dynamische_frontend_texte.landing.button_text' | translate"
                        [innerHTML]="'dynamische_frontend_texte.landing.button_text' | translate | safeStyle"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</form>
