import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'otb-accessablity-info',
    templateUrl: './accessablity-info.component.html',
    styleUrls: ['./accessablity-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false,
})
export class AccessablityInfoComponent {
    constructor() {}
}
