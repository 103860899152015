<div [formGroup]="form">
    <input
        [formControlName]="fieldName"
        [id]="fieldName"
        type="text"
        [placeholder]="formField.placeholder"
        class="form-control"
        [ngClass]="{
            'is-invalid': !isValid && (isDirty || isTouched) && !isDisabled,
        }"
        [attr.autocomplete]="formField.autocomplete !== '' ? formField.autocomplete : null"
        [attr.aria-labelledby]="fieldName + '-label'"
        [attr.aria-describedby]="
            fieldName +
            '-error-required ' +
            fieldName +
            '-error-nohtmlcharacters ' +
            fieldName +
            '-error-dinSpecInvalid'
        "
    />
    <otb-form-error-message
        [id]="fieldName + '-error-required'"
        [show]="!isValid && (isDirty || isTouched) && errors?.['required']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.text_nicht_leer' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-nohtmlcharacters'"
        class="max-length"
        [show]="!isValid && (isDirty || isTouched) && errors?.['nohtmlcharacters']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.has_no_html' | translate"
    >
    </otb-form-error-message>
    <otb-form-error-message
        [id]="fieldName + '-error-dinSpecInvalid'"
        [show]="!isValid && (isDirty || isTouched) && errors?.['dinSpecInvalid']"
        [text]="'dynamische_frontend_validatoren.persönliche_daten.din_spec_91379' | translate"
    >
    </otb-form-error-message>
</div>
