import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'otb-easy-language',
    templateUrl: './easy-language.component.html',
    styleUrls: ['./easy-language.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false,
})
export class EasyLanguageComponent {
    constructor() {}
}
