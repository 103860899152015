import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../api/models/design-frontend-settings';

@Directive({
    selector: '[dropdownCustomHoverColorDirective]',
    standalone: true,
})
export class DropdownCustomHoverColorDirective implements OnDestroy {
    private sub: Subscription = new Subscription();
    private color: string = '';

    constructor(
        private el: ElementRef,
        private applicationSettingsService: ApplicationSettingsService,
    ) {}

    ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.color = settings?.color_header1 ?? '';
                },
            ),
        );
    }

    @HostListener('open') onOpen() {
        this.applyHoverColorToDropdown();
    }

    private applyHoverColorToDropdown(): void {
        setTimeout(() => {
            const dropdownPanel = this.el.nativeElement.querySelector('.ng-dropdown-panel');
            let textColor = this.color === '' ? '#000000' : '#ffffff';
            if (dropdownPanel) {
                dropdownPanel.style.setProperty('--header-one-color', this.color);
                dropdownPanel.style.setProperty('--header-one-text-color', textColor);
            }
        }, 0);
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
