<div class="row">
    <div class="col-12">
        <p class="addition">
            <span
                [innerHTML]="'dynamische_frontend_texte.landing.html_content.ueberschrift' | translate | safeStyle"
            ></span>
        </p>
    </div>
    <otb-progress-bar></otb-progress-bar>
</div>
<form id="agency-service-selection" [formGroup]="agencyFormGroup" (ngSubmit)="onSubmit()">
    <div class="row align-items-end">
        <div class="col-12 mb-4">
            @if (
                environment.deploy_environment === deployEnv.MAGS_SOP ||
                environment.deploy_environment === deployEnv.HMDIS_INTERNAL
            ) {
                <h1
                    class="landing-intro"
                    [innerHTML]="'dynamische_frontend_texte.landing.html_content.einfuehrung' | translate | safeStyle"
                ></h1>
            } @else {
                <h2
                    class="landing-intro"
                    [innerHTML]="'dynamische_frontend_texte.landing.html_content.einfuehrung' | translate | safeStyle"
                ></h2>
            }
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <otb-agency-selection
                    [agencyFormGroup]="agencyFormGroup"
                    (agencyChange)="onAgencySelect($event)"
                ></otb-agency-selection>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label
                    for="citizen-service"
                    id="citizen-service-label"
                    [innerHTML]="
                        'dynamische_frontend_texte.landing.html_content.label_dropdown_leistung'
                            | translate
                            | required
                            | safeStyle
                    "
                ></label>
                <ng-select
                    id="citizen-service"
                    [items]="servicesSelect"
                    bindLabel="name"
                    bindValue="id"
                    (change)="onServiceSelect($event)"
                    formControlName="service"
                    [clearable]="false"
                    placeholder="Dienstleistung auswählen"
                    [loading]="serviceSelectionAjaxLoading"
                    [loadingText]="'Dienstleistungen werden geladen...'"
                    [inputAttrs]="{
                        'aria-describedby': 'citizen-service-error',
                        'aria-labelledby': 'citizen-service-label',
                    }"
                    dropdownCustomHoverColorDirective
                ></ng-select>
                <otb-form-error-message
                    id="citizen-service-error"
                    [show]="formService.invalid && formService.dirty"
                ></otb-form-error-message>
            </div>
        </div>
        <div [ngClass]="envCssClassesGenerator.serviceNoticeClasses">
            @if (selectedService) {
                <div class="service-notice" [ngClass]="isEnvJustice ? 'mt-5' : 'mt-3'">
                    <div class="row">
                        <span
                            class="description-title"
                            [innerHTML]="
                                'dynamische_frontend_texte.landing.html_content.zusaetzliche_informationen'
                                    | translate
                                    | safeStyle
                            "
                        ></span>
                        <p
                            [ngClass]="isEnvJustice ? 'mt-4' : 'mt-2'"
                            [innerHTML]="selectedService.notice | safeStyle"
                        ></p>
                        <p>
                            @if (selectedService.url !== '') {
                                <span
                                    [innerHTML]="
                                        'dynamische_frontend_texte.landing.html_content.url' | translate | safeStyle
                                    "
                                ></span>
                            }
                            @if (selectedService.url !== '') {
                                <a
                                    class="ms-1"
                                    [href]="selectedService.url"
                                    [innerHTML]="selectedService.url"
                                    [title]="selectedService.url"
                                    aria-label="Verlinkung zur Dienstleistung - Dieser Link wird in einen neuen Tab geöffnet."
                                    target="_blank"
                                ></a>
                            }
                        </p>
                    </div>
                    <div class="form-group d-flex justify-content-end">
                        <button
                            type="submit"
                            class="btn-success btn rounded-0"
                            accesskey="w"
                            btnPrimaryColorDirective
                            [innerHTML]="'dynamische_frontend_texte.shared.button.next' | translate | safeStyle"
                        ></button>
                    </div>
                </div>
            }
        </div>
    </div>
</form>
