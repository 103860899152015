import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../model/breadcrumb';
import { StateService } from '../../../services/state/state.service';
import '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';
import { DesignFrontendSettings } from '../../../api/models/design-frontend-settings';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'otb-breadcrumb-item',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: [
        './breadcrumb-item.component.scss',
        './breadcrumb-item.component.mags-sop.scss',
        './breadcrumb-item.component.hzd-internal.scss',
        './breadcrumb-item.component.hmdj.scss',
    ],
    standalone: false,
})
export class BreadcrumbItemComponent {
    progressBarColor = '';
    sub: Subscription = new Subscription();
    @Input() isLast: boolean = false;
    @Input() breadcrumb: Breadcrumb;

    faEdit = faEdit;

    constructor(
        private stateService: StateService,
        private applicationSettingsService: ApplicationSettingsService,
    ) {}

    ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.sub.add(
            this.applicationSettingsService.designFrontendSettingsSubject.subscribe(
                (settings: DesignFrontendSettings | null) => {
                    this.progressBarColor = settings?.color_progress_bar ?? '';
                },
            ),
        );
    }

    getProgressBarColor(): string | null {
        if (!this.isLast && this.isTargetEnvironment()) {
            return this.progressBarColor?.trim() || null;
        }
        return null;
    }

    isTargetEnvironment(): boolean {
        const targetEnvironments = ['hmdj', 'hzd-internal'];
        return targetEnvironments.includes(environment.deploy_environment);
    }

    onBreadcrumbClick(): void {
        if (!this.breadcrumb.backFunction) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.stateService[this.breadcrumb.backFunction]();

        if (this.breadcrumb.backFunction === 'backToCourtAndService') {
            this.stateService.init();
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
