<div class="colorblind-legend mt-5">
    <input
        id="color-blind-check"
        type="checkbox"
        class="form-check-input"
        [title]="'dynamische_frontend_texte.datum_auswahl.farbenblinde_label' | translate"
        tabindex="0"
        (change)="toggleLegend($event)"
        [attr.checked]="this.showLegend ? 'checked' : null"
    />
    <label for="color-blind-check" class="form-check-label ms-2">
        <span
            class="ms-1"
            [innerHTML]="'dynamische_frontend_texte.datum_auswahl.farbenblinde_label' | translate | safeStyle"
        ></span>
    </label>
    <div class="inner-container" *ngIf="showLegend">
        <span
            [innerHTML]="'dynamische_frontend_texte.datum_auswahl.legende_ueberschrift' | translate | safeStyle"
        ></span>
        <div class="border">
            <div class="color-preview">
                <div class="color success">
                    <fa-icon class="fa" [icon]="faCheck"></fa-icon>
                </div>
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.legende_freie_termine' | translate | safeStyle
                    "
                ></p>
            </div>
            <div class="color-preview">
                <div class="color active">
                    <fa-icon class="fa" [icon]="faStar"></fa-icon>
                </div>
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.legende_gewaehlter_tag' | translate | safeStyle
                    "
                ></p>
            </div>
            <div class="color-preview">
                <div class="color disabled">
                    <fa-icon class="fa" [icon]="faSquare"></fa-icon>
                </div>
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.legende_alle_vergeben' | translate | safeStyle
                    "
                ></p>
            </div>
            <div class="color-preview">
                <div class="color not-available">
                    <fa-icon class="fa" [icon]="faTimes"></fa-icon>
                </div>
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.legende_keine_moeglich' | translate | safeStyle
                    "
                ></p>
            </div>
        </div>
    </div>
</div>
