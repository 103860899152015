<div class="form-group" [formGroup]="form">
    <label [for]="fieldName" [ngClass]="{ required: formField.is_required }" [id]="fieldName + '-label'">
        @if (formField.is_required) {
            {{ formField.label | required }}
        } @else {
            {{ formField.label | optional }}
        }
    </label>

    <div>
        @switch (formField.type) {
            @case ('text') {
                <otb-text-field [formField]="formField" [form]="form" [formNumber]="formNumber"></otb-text-field>
            }
            @case ('email') {
                <otb-email-field [formField]="formField" [form]="form" [formNumber]="formNumber"></otb-email-field>
            }
            @case ('phone') {
                <otb-phone-field [formField]="formField" [form]="form" [formNumber]="formNumber"></otb-phone-field>
            }
            @case ('textarea') {
                <otb-text-area-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                ></otb-text-area-field>
            }
            @case ('numeric') {
                <otb-numeric-field [formField]="formField" [form]="form" [formNumber]="formNumber"></otb-numeric-field>
            }
            @case ('select') {
                <otb-select-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                    [id]="fieldName"
                ></otb-select-field>
            }
            @case ('country_select') {
                <otb-select-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                    [id]="fieldName"
                ></otb-select-field>
            }
            @case ('numeric_select') {
                <otb-numeric-select-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                    [id]="fieldName"
                ></otb-numeric-select-field>
            }
            @case ('date') {
                <otb-date-field
                    [minDate]="formField.min_date"
                    [maxDate]="formField.max_date"
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                ></otb-date-field>
            }
            @case ('checkbox') {
                <otb-checkbox-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                    [id]="fieldName"
                ></otb-checkbox-field>
            }
            @case ('file_upload') {
                <otb-file-upload-field
                    [formField]="formField"
                    [form]="form"
                    [formNumber]="formNumber"
                ></otb-file-upload-field>
            }
        }
    </div>
    @if (formField.help !== '') {
        <small class="form-text text-muted">{{ formField.help }}</small>
    }
</div>
