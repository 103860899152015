<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'bb-jm', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row">
    <div *otbShowInEnv="['hzd-internal']" class="col-12">
        @if (strategy) {
            <h1>{{ strategy.notice }}</h1>
        }
    </div>
    <div *otbHideInEnv="['hzd-internal']" class="col-12">
        @if (strategy) {
            <h2>{{ strategy.notice }}</h2>
        }
    </div>
</div>

@if (strategyName === 'unknown') {
    <div class="row">
        <div class="col-12">
            <div class="alert-box">
                <div
                    class="alert alert-danger"
                    [innerHTML]="
                        'dynamische_frontend_texte.benachrichtigungen.zuordnungsstrategie_nicht_verfuegbar' | translate
                    "
                ></div>
            </div>
        </div>
        <div class="col-12">
            <a
                (click)="back()"
                class="btn-secondary btn pull-left order-md-1 order-2"
                [title]="'dynamische_frontend_texte.verteilmechanismen.attribute_text.zurueck' | translate"
                tabindex="0"
                [innerHTML]="
                    'dynamische_frontend_texte.verteilmechanismen.html_content.zurueck' | translate | safeStyle
                "
                accesskey="z"
            >
            </a>
        </div>
    </div>
}

@if (strategyName === 'name') {
    <form [formGroup]="strategyForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12 col-md-5">
                <label for="nameAddition">Namenszusatz (Optional)</label>
                <input
                    class="form-control mb-2"
                    id="nameAddition"
                    formControlName="nameAddition"
                    type="text"
                    [placeholder]="namePlaceholder"
                />
                <small class="text-muted">Bitte tragen Sie gegebenenfalls einen Namenszusatz ein.</small>
            </div>
            <div class="col-12 col-md-7">
                <label for="name">{{ lastNameLabel }} *</label>
                <input
                    class="form-control mb-2"
                    [class.border-danger]="name.invalid && name.dirty"
                    id="name"
                    formControlName="name"
                    type="text"
                    aria-describedby="name-error-required name-error-dinSpecInvalid"
                />
                <otb-form-error-message
                    id="name-error-required"
                    [show]="name.invalid && name.dirty && name.errors?.['required']"
                >
                </otb-form-error-message>
                <otb-form-error-message
                    id="name-error-dinSpecInvalid"
                    [show]="name.invalid && name.dirty && name.errors?.['dinSpecInvalid']"
                    [text]="'dynamische_frontend_validatoren.persönliche_daten.din_spec_91379' | translate"
                >
                </otb-form-error-message>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                    <button
                        type="submit"
                        class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                        accesskey="w"
                        btnPrimaryColorDirective
                        [title]="
                            'dynamische_frontend_texte.verteilmechanismen.attribute_text.name_button_text' | translate
                        "
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.name_button_text'
                                | translate
                                | safeStyle
                        "
                    ></button>
                    <a
                        (click)="back()"
                        class="btn-secondary btn pull-left order-md-1 order-2"
                        [title]="'dynamische_frontend_texte.verteilmechanismen.attribute_text.zurueck' | translate"
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.zurueck' | translate | safeStyle
                        "
                        accesskey="z"
                        tabindex="0"
                    >
                    </a>
                </div>
            </div>
        </div>
    </form>
}

@if (strategyName === 'list') {
    <form [formGroup]="strategyForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12 col-md-7 mb-3">
                <label
                    for="list"
                    [innerHTML]="
                        'dynamische_frontend_texte.verteilmechanismen.html_content.liste_überschrift'
                            | translate
                            | safeStyle
                    "
                ></label>
                <ng-select
                    id="list"
                    [items]="listEntries"
                    formControlName="list"
                    labelForId="Auswahl"
                    [clearable]="false"
                    accesskey="w"
                    [class.border-danger]="strategyForm.controls['list'].invalid && strategyForm.controls['list'].dirty"
                    [placeholder]="
                        'dynamische_frontend_texte.verteilmechanismen.attribute_text.liste_platzhalter' | translate
                    "
                    [inputAttrs]="{ 'aria-describedby': 'list-error' }"
                >
                </ng-select>
                <otb-form-error-message
                    id="list-error"
                    [show]="strategyForm.controls['list'].invalid && strategyForm.controls['list'].dirty"
                ></otb-form-error-message>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                    <button
                        type="submit"
                        class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                        accesskey="w"
                        btnPrimaryColorDirective
                        [title]="
                            'dynamische_frontend_texte.verteilmechanismen.attribute_text.name_button_text' | translate
                        "
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.name_button_text'
                                | translate
                                | safeStyle
                        "
                    ></button>
                    <a
                        (click)="back()"
                        class="btn-secondary btn pull-left order-md-1 order-2"
                        [title]="'dynamische_frontend_texte.verteilmechanismen.attribute_text.zurueck' | translate"
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.zurueck' | translate | safeStyle
                        "
                        accesskey="z"
                        tabindex="0"
                    >
                    </a>
                </div>
            </div>
        </div>
    </form>
}

@if (strategyName === 'aktenzeichen') {
    <form [formGroup]="strategyForm" (ngSubmit)="onSubmit()">
        <div class="row align-items-end mb-3">
            <div class="col-sm-4 col-lg-2">
                <label for="Abteilungsnummer">Abteilungsnummer*</label>
                <otb-form-error-message
                    id="abteilungsnummer-error"
                    [show]="
                        strategyForm.controls['departmentNumbers'].invalid &&
                        strategyForm.controls['departmentNumbers'].dirty
                    "
                ></otb-form-error-message>
                <ng-select
                    [items]="departmentNumbers"
                    [class.border-danger]="
                        strategyForm.controls['departmentNumbers'].invalid &&
                        strategyForm.controls['departmentNumbers'].dirty
                    "
                    id="Abteilungsnummer"
                    formControlName="departmentNumbers"
                    [clearable]="false"
                    [(ngModel)]="selectedDepartmentNumber"
                    placeholder="Abteilungsnummer"
                    [inputAttrs]="{ 'aria-describedby': 'abteilungsnummer-error' }"
                ></ng-select>
            </div>
            <div class="col-sm-4 col-lg-2">
                <label for="Registerzeichen">Registerzeichen*</label>
                <otb-form-error-message
                    id="registerzeichen-error"
                    [show]="
                        strategyForm.controls['registrationMarks'].invalid &&
                        strategyForm.controls['registrationMarks'].dirty
                    "
                ></otb-form-error-message>
                <ng-select
                    [items]="registrationMarks"
                    [class.border-danger]="
                        strategyForm.controls['registrationMarks'].invalid &&
                        strategyForm.controls['registrationMarks'].dirty
                    "
                    id="Registerzeichen"
                    formControlName="registrationMarks"
                    [clearable]="false"
                    [(ngModel)]="selectedRegistrationMark"
                    placeholder="Registerzeichen"
                    [inputAttrs]="{ 'aria-describedby': 'registerzeichen-error' }"
                ></ng-select>
            </div>
            <div class="col-sm-4 col-lg-2">
                <label for="LaufendeNummer">Laufende Nummer*</label>
                <otb-form-error-message
                    id="laufendeNummer-error"
                    [show]="strategyForm.controls['fileNumber'].invalid && strategyForm.controls['fileNumber'].dirty"
                ></otb-form-error-message>
                <input
                    class="form-control"
                    id="LaufendeNummer"
                    formControlName="fileNumber"
                    type="text"
                    [class.border-danger]="
                        strategyForm.controls['fileNumber'].invalid && strategyForm.controls['fileNumber'].dirty
                    "
                    aria-describedby="laufendeNummer-error"
                />
            </div>
            <div class="col-sm-4 col-lg-2">
                <label for="Jahresangabe">Jahresangabe*</label>
                <otb-form-error-message
                    id="jahresangabe-error"
                    [show]="strategyForm.controls['years'].invalid && strategyForm.controls['years'].dirty"
                ></otb-form-error-message>
                <ng-select
                    [items]="years"
                    [class.border-danger]="
                        strategyForm.controls['years'].invalid && strategyForm.controls['years'].dirty
                    "
                    id="Jahresangabe"
                    formControlName="years"
                    [clearable]="false"
                    [(ngModel)]="selectedYear"
                    placeholder="Jahresangabe"
                    [inputAttrs]="{ 'aria-describedby': 'jahresangabe-error' }"
                ></ng-select>
            </div>
            @if (strategyForm.controls['addition'] !== undefined) {
                <div class="col-md-3">
                    <label for="Zusatz">Zusatz*</label>
                    <otb-form-error-message
                        id="zusatz-error"
                        [show]="strategyForm.controls['addition'].invalid && strategyForm.controls['addition'].dirty"
                    ></otb-form-error-message>
                    <input
                        class="form-control"
                        id="Zusatz"
                        formControlName="addition"
                        type="text"
                        [class.border-danger]="
                            strategyForm.controls['addition'].invalid && strategyForm.controls['addition'].dirty
                        "
                        readonly
                        aria-describedby="zusatz-error"
                    />
                </div>
            }
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group d-flex justify-content-between flex-md-row flex-column">
                    <button
                        type="submit"
                        class="btn-success btn order-md-2 order-1 m-0 mb-3 mb-sm-0"
                        accesskey="w"
                        btnPrimaryColorDirective
                        [title]="
                            'dynamische_frontend_texte.verteilmechanismen.attribute_text.name_button_text' | translate
                        "
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.name_button_text'
                                | translate
                                | safeStyle
                        "
                    ></button>
                    <a
                        (click)="back()"
                        class="btn-secondary btn pull-left order-md-1 order-2"
                        [title]="'dynamische_frontend_texte.verteilmechanismen.attribute_text.zurueck' | translate"
                        [innerHTML]="
                            'dynamische_frontend_texte.verteilmechanismen.html_content.zurueck' | translate | safeStyle
                        "
                        accesskey="z"
                        tabindex="0"
                    >
                    </a>
                </div>
            </div>
        </div>
    </form>
}
