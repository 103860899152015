import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentService } from '../../api/services/appointment.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageISOType } from '../../types/LanguageISOType';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';

@Component({
    selector: 'otb-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    standalone: false,
})
export class ConfirmationComponent implements OnInit, OnDestroy {
    done: boolean = false;
    error: boolean = false;
    isAppointmentAlreadyConfirmedError: boolean = false;
    confirmSub: Subscription = new Subscription();
    showButton = false;

    constructor(
        private appointmentService: AppointmentService,
        private route: ActivatedRoute,
        private transService: TranslateService,
        private appSettings: ApplicationSettingsService,
    ) {}

    ngOnInit(): void {
        if (!this.appSettings.confirmButton) {
            this.confirmConfirmation();
        } else {
            this.showButton = true;
        }
    }

    ngOnDestroy(): void {
        this.confirmSub.unsubscribe();
    }

    private getExceptionName(error: any): string {
        const jsonObject = JSON.parse(error);

        return jsonObject.errors[0].type
            .split('\\')
            .pop()
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            .toLowerCase();
    }

    confirmConfirmation(): void {
        let uuid = '';
        let code = '';
        this.route.queryParams.subscribe((params) => {
            uuid = params['uuid'];
            code = params['code'];
        });

        if (!uuid || !code) {
            this.error = true;
        }

        const postAppointmentConfirmation = this.appointmentService
            .postApiPublicAppointmentConfirmation({
                uuid: uuid,
                _locale: <LanguageISOType>this.transService.currentLang,
                body: {
                    code: code,
                },
            })
            .subscribe({
                next: (response: any) => {
                    this.done = true;
                    this.error = response !== '[]';
                    this.isAppointmentAlreadyConfirmedError = false;
                },
                error: (_error: any) => {
                    console.log(_error.message);
                    if (this.getExceptionName(_error.exception) === 'appointment-already-confirmed-exception') {
                        this.isAppointmentAlreadyConfirmedError = true;
                    }
                    this.done = true;
                    this.error = true;
                },
            });

        this.confirmSub.add(postAppointmentConfirmation);
    }
}
