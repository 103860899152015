import { Component, OnInit } from '@angular/core';
import { ContentLinksService } from '../../services/content-links/content-links.service';
import { ContentPage } from '../../api/models/content-page';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'otb-content-page',
    standalone: true,
    templateUrl: './content-page.component.html',
    styleUrl: './content-page.component.scss',
})
export class ContentPageComponent implements OnInit {
    pageContent: ContentPage | undefined;
    subs: Subscription = new Subscription();
    path: string = '';

    constructor(
        private route: ActivatedRoute,
        private contentLinksService: ContentLinksService,
    ) {}

    ngOnInit(): void {
        this.contentLinksService.getContentPages();
        this.subs.add(
            this.route.params.subscribe((params) => {
                this.path = params['dynamicPath'];
                this.contentLinksService.getContentPageByPath('/' + this.path).subscribe((contentPage) => {
                    this.pageContent = contentPage;
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
