import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MinDateService {
    minDate = (minDate: string): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            const minimalDate = new Date(minDate);
            const ngbDate = control.value;
            if (!ngbDate) {
                return null;
            }
            const currentDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);

            if (currentDate < minimalDate) {
                return {
                    mindate: { value: currentDate, minDate: minimalDate }
                };
            }

            return null;
        };
    };
}
