import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otb-form-builder-select-numeric',
    templateUrl: './select-numeric.component.html'
})
export class SelectNumericComponent implements OnInit {
    @Input() id: string | null = null;
    @Input() name: string = '';
    @Input() label: string = '';
    @Input() items: unknown[] = [];
    @Input() helpText: string | null = null;
    @Input() required: boolean = true;
    @Input() defaultValue: unknown;

    selectValue: any;

    ngOnInit(): void {
        if (this.defaultValue !== null) {
            this.selectValue = this.defaultValue;
        }

        if (this.id === null) {
            this.id = this.name;
        }
    }

    updateValue(): void {
        this.value = this.selectValue;
    }

    @Input()
    get value(): number {
        return this.selectValue;
    }

    @Output() valueChange = new EventEmitter<number>();
    set value(val) {
        this.selectValue = val;
        this.valueChange.emit(this.selectValue);
    }
}
