import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FlashMessageErrorHandlerService } from '../flash-message-error-handler/flash-message-error-handler.service';
import { ExceptionStackError } from '../../errors/ExceptionStackError';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private flashMessageErrorHandlerService: FlashMessageErrorHandlerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: any): ObservableInput<any> => {
                if (error instanceof HttpErrorResponse) {
                    // TODO: refactor into own method to skip error messages on certain requests, because we handle the errors on our own
                    const errorSkippable = error.url?.includes('i18nTranslation');

                    if (!errorSkippable) {
                        this.flashMessageErrorHandlerService.errorSubject.next(error);
                        this.flashMessageErrorHandlerService.errorStack.push(error);

                        sessionStorage.clear();
                    }
                }
                return throwError(() => new ExceptionStackError(error?.message, error?.error));
            })
        );
    }
}
