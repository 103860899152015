import { Injectable } from '@angular/core';
import { StrategyFileNumber } from './strategy/strategy-file-number';

@Injectable({
    providedIn: 'root'
})
export class FileNumberBuilderService {
    public buildFromStrategy(strategy: StrategyFileNumber): string {
        const { selectedDepartmentNumber, selectedYear, addition, selectedRegistrationMark, fileNumberPart } = strategy;
        return (
            `${selectedDepartmentNumber.value}-${selectedRegistrationMark.value}` +
            `-${fileNumberPart}/${selectedYear.value}-${addition}`
        );
    }
}
