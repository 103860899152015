<div class="upper__container mb-5 mb-sm-2">
    @if (dateFirst) {
        <p
            [innerHTML]="
                'dynamische_frontend_texte.datum_auswahl.html_content.auswahl'
                    | translate
                    | replaceDateTime: selectedDate
                    | async
            "
            class="mb-0"
            role="status"
        ></p>
    }
    <p
        [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.label_zeit_auswahl' | translate | safeStyle"
    ></p>
</div>
@if (!timePickSecondLoading) {
    <div>
        @for (court of availableAgencies; track court.name) {
            <div class="row">
                @for (singleTime of court.times; track singleTime.time; let first = $first) {
                    <div class="col-sm-4 col-6 pr-sm-1">
                        <div class="time--wrapper">
                            @if (first) {
                                <a
                                    #first
                                    tabindex="0"
                                    role="button"
                                    aria-selected="false"
                                    [attr.title]="
                                        'dynamische_frontend_texte.datum_auswahl.attribute_text.zeit_auswahl_eintrag_title'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                    (click)="timeClick(singleTime, $event)"
                                    href="#"
                                    [innerHTML]="
                                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                    [attr.data-text]="
                                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                ></a>
                            }
                            @if (!first) {
                                <a
                                    tabindex="0"
                                    role="button"
                                    aria-selected="false"
                                    [attr.title]="
                                        'dynamische_frontend_texte.datum_auswahl.attribute_text.zeit_auswahl_eintrag_title'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                    (click)="timeClick(singleTime, $event)"
                                    href="#"
                                    [innerHTML]="
                                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                    [attr.data-text]="
                                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                                            | translate
                                            | replaceDateTime: singleTime.time
                                            | async
                                    "
                                ></a>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}

@if (timePickSecondLoading) {
    <div class="row">
        <div class="col-6">
            <otb-loading-animation-dots
                [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
            ></otb-loading-animation-dots>
        </div>
    </div>
}
