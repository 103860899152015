import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../services/state/state.service';
import { StrategyName } from '../../services/assignment-strategy/strategy/strategy-name';
import { BookingService } from '../../services/booking/booking.service';
import { Strategy } from '../../services/assignment-strategy/strategy/strategy';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { StrategyFileNumber } from '../../services/assignment-strategy/strategy/strategy-file-number';
import { SelectOptionType } from './types';
import { FileNumberBuilderService } from '../../services/assignment-strategy/file-number-builder.service';
import { PersonalDataForm } from '../../api/models/personal-data-form';
import { DinSpec91379Service } from '../personal-data/dynamic-form/form-group-factory-service/form-field-validator-mapper-service/custom-validators/dinSpec91379/din-spec-91379.service';
import { StrategyList } from '../../services/assignment-strategy/strategy/strategy-list';
import { StrategyRandom } from '../../services/assignment-strategy/strategy/strategy-random';

@Component({
    selector: 'otb-assignment-strategy',
    templateUrl: './assignment-strategy.component.html',
    styleUrls: ['./assignment-strategy.component.scss']
})

//todo: This Component should be split, https://publicplan.atlassian.net/browse/TEPG-3232
export class AssignmentStrategyComponent implements OnInit {
    strategy: Strategy;
    strategyForm: UntypedFormGroup;
    departmentNumbers: SelectOptionType[] = [];
    selectedDepartmentNumber: SelectOptionType;
    registrationMarks: SelectOptionType[] = [];
    years: SelectOptionType[] = [];
    selectedRegistrationMark: SelectOptionType;
    selectedYear: SelectOptionType;

    namePlaceholder: string = 'ggf.: Di, Von, Zu, Al-, El-, Da';
    lastNameLabel: string = 'Nachname';
    personalDataForm: PersonalDataForm;

    listEntries: Array<string> = [];
    choosenOption: string;

    constructor(
        private stateService: StateService,
        private bookingService: BookingService,
        private sessStorageService: SessionStorageService,
        private fileNumberBuilderService: FileNumberBuilderService,
        private dinSpec91379Service: DinSpec91379Service
    ) {}

    ngOnInit(): void {
        this.strategy = this.bookingService.booking.strategy;
        this.personalDataForm = this.bookingService.booking.personalDataForm;
        this._buildFormGroup();
    }

    _buildFormGroup(): void {
        this.strategyForm = this.strategy.buildForm(this.personalDataForm, this.dinSpec91379Service);
        if (this.strategy instanceof StrategyName) {
            this.namePlaceholder = 'ggf.: Di, Von, Zu, Al-, El-, Da'; // todo https://publicplan.atlassian.net/browse/TEPG-3232 übersetzbar machen...

            let lastNameField = this.personalDataForm.form_fields.filter(
                (field) => field.name === (this.strategy as StrategyName).lastNameFormField
            );
            if (lastNameField.length > 0) {
                this.lastNameLabel = lastNameField[0].label;
            }
            this.lastNameLabel = lastNameField[0]?.label;
        }
        if (this.strategy instanceof StrategyList) {
            this.listEntries = this.strategy.options;
            this.strategyForm.get('list')?.valueChanges.subscribe((value) => {
                this.choosenOption = value;
            });
        }

        if (this.strategy instanceof StrategyFileNumber) {
            this.departmentNumbers = this.strategy.departmentNumbers.map((departmentNumber) => ({
                value: departmentNumber,
                label: departmentNumber
            }));
            this.selectedDepartmentNumber = this.strategy.selectedDepartmentNumber;
            this.registrationMarks = this.strategy.registrationMarks.map((registrationMark) => ({
                value: registrationMark,
                label: registrationMark
            }));

            this.selectedRegistrationMark = this.strategy.selectedRegistrationMark;

            this.years = this.strategy.years.map((year) => ({
                value: year,
                label: year
            }));

            this.selectedYear = this.strategy.selectedYear;
        }
    }

    _setStrategyData(): void {
        if (this.strategy instanceof StrategyName) {
            this.strategy.lastName = this.strategyForm.controls['name'].value;
            this.strategy.nameAddition = this.strategyForm.controls['nameAddition'].value;

            this.bookingService.booking.strategy = this.strategy;
            this.writeToSessionStorage();
        }

        if (this.strategy instanceof StrategyList) {
            this.strategy.chosenOption = this.strategyForm.controls['list'].value;

            this.bookingService.booking.strategy = this.strategy;
            this.writeToSessionStorage();
        }

        if (this.strategy instanceof StrategyFileNumber) {
            this.strategy.selectedDepartmentNumber = this.selectedDepartmentNumber;
            this.strategy.selectedRegistrationMark = this.selectedRegistrationMark;
            this.strategy.fileNumberPart = this.strategyForm.controls['fileNumber'].value;
            this.strategy.selectedYear = this.selectedYear;
            this.strategy.addition = this.strategyForm.controls['addition']?.value || '';

            this.bookingService.booking.strategy = this.strategy;
            this.writeToSessionStorage();
        }
    }

    _markFormAsDirty(): void {
        this.strategy.markDirty(this.strategyForm);
    }

    private writeToSessionStorage(): void {
        const sessData = this.sessStorageService.getPersonalData();
        if (this.strategy instanceof StrategyRandom || sessData === null) {
            return;
        }

        if (this.strategy instanceof StrategyName) {
            if (sessData?.lastName) {
                sessData.lastName = this.strategy.lastName;
            }
        }

        if (this.strategy instanceof StrategyList) {
            sessData.choosenOption = this.choosenOption;
        }

        if (this.strategy instanceof StrategyFileNumber) {
            sessData[this.strategy.fileNumberFormField] = this.fileNumberBuilderService.buildFromStrategy(
                this.strategy
            );
        }

        this.sessStorageService.persistPersonalData(JSON.stringify(sessData));
    }

    onSubmit(): void {
        if (!this.strategyForm) {
            throw new Error('Strategy form is not defined');
        }

        if (this.strategyForm.valid) {
            this._setStrategyData();
            this.stateService.next();
        } else {
            this._markFormAsDirty();
        }
    }

    back(): void {
        this.stateService.back();
    }

    get name(): AbstractControl {
        return this.strategyForm.get('name') as AbstractControl;
    }

    get strategyName(): string {
        return this.bookingService.booking.strategy.strategyName;
    }
}
