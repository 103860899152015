<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'bb-jm', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

@if (calendarShow) {
    <div class="row" [class.d-none]="calendarLoading">
        <div class="col-12">
            <h2
                *otbHideInEnv="['hzd-internal']"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift' | translate | safeStyle
                "
            ></h2>
            <h1
                *otbShowInEnv="['hzd-internal']"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift' | translate | safeStyle
                "
            ></h1>
        </div>
        @if (showFormSwitcherTop && !isServiceOnly) {
            <div class="col-12 mb-5">
                <div class="changeform__container">
                    <a
                        (click)="switchToTimeFirst($event)"
                        href="#"
                        class="changeform has--before-arrow"
                        title="{{
                            'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmte_zeit'
                                | translate
                        }}"
                        [innerHTML]="
                            'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmte_zeit'
                                | translate
                                | safeStyle
                        "
                    >
                    </a>
                </div>
            </div>
        }
        <div class="col-12 col-md-6 mb-5">
            <div class="upper__container mb-5 mb-sm-2">
                <p
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.label_kalendar' | translate | safeStyle
                    "
                ></p>
            </div>
            <div #calendarContainer class="mb-3" [class.colorblind-active]="colorLegendShow | async">
                <input type="hidden" #datepicker />
            </div>
            @if (!showFormSwitcherTop && !isServiceOnly) {
                <div class="col-12 mb-5">
                    <div class="changeform__container">
                        <a
                            (click)="switchToTimeFirst($event)"
                            href="#"
                            class="changeform has--before-arrow"
                            title="{{
                                'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmte_zeit'
                                    | translate
                            }}"
                            [innerHTML]="
                                'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmte_zeit'
                                    | translate
                                    | safeStyle
                            "
                        >
                        </a>
                        <fa-icon class="fa" [icon]="faArrowCircleRight"></fa-icon>
                    </div>
                </div>
            }
            <otb-color-legend></otb-color-legend>
        </div>
        <div class="col-12 col-md-6">
            <!-- if application_settings service_only is false, show only regular process -->
            @if (!timesLoading) {
                @if (!isServiceOnly) {
                    <otb-time-pick-selection
                        [availableAgencies]="availableAgencyTimes"
                        (timeSelect)="onTimeSelect($event)"
                    ></otb-time-pick-selection>
                    @if (appointmentReservationLoading | async) {
                        <div class="d-flex mt-3 align-items-center justify-content-center">
                            <div class="mx-3 d-inline">
                                <span>Ihr Termin wird reserviert...</span>
                            </div>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                } @else {
                    <otb-service-only-time-pick
                        [availableDetailedAgencies]="availableDetailedAgencyTimes"
                        (timeSelect)="onTimeSelectServiceOnly($event)"
                    ></otb-service-only-time-pick>
                    @if (appointmentReservationLoading | async) {
                        <div class="d-flex mt-3 align-items-center justify-content-center">
                            <div class="mx-3 d-inline">
                                <span>Ihr Termin wird reserviert...</span>
                            </div>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                }
            } @else {
                <otb-loading-animation-dots
                    [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
                ></otb-loading-animation-dots>
            }
            <!-- else show service only -->
        </div>
    </div>
} @else {
    <div class="row">
        <div class="col-12">
            <p
                class="info__header"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift_keine_termine'
                        | translate
                        | safeStyle
                "
            ></p>
            <p
                class="info__content"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.naechste_freie_termine'
                        | translate
                        | replaceDateTime: nextAppointmentAt
                        | async
                "
            ></p>
        </div>
    </div>
}
@if (calendarLoading) {
    <div class="row">
        <div class="col-12">
            <otb-loading-animation-dots
                [text]="'dynamische_frontend_texte.lade_animation.kalender'"
            ></otb-loading-animation-dots>
        </div>
    </div>
}

<div class="row">
    <div class="col-12">
        <otb-back-button></otb-back-button>
    </div>
</div>
