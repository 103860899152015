import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MaxDateService {
    maxDate = (maxDate: string): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            const maxiDate = new Date(maxDate);
            const ngbDate = control.value;
            const currentDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);

            if (currentDate > maxiDate) {
                return { maxdate: { value: currentDate, maxDate: maxiDate } };
            }

            return null;
        };
    };
}
