/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AvailableDatesResponse } from '../../models/available-dates-response';

export interface GetApiPublicAvailableDates$Params {

/**
 * Lastname of Citizen.
 */
  strategy_name_last_name?: string;

/**
 * File number of Citizen.
 */
  strategy_file_number_file_number?: string;

/**
 * Department Number of Citizen.
 */
  strategy_file_number_department_number?: string;

/**
 * Choosen Option
 */
  strategy_list_chosen_option?: string;

/**
 * Participants count
 */
  participants_count: number;

/**
 * Agency ID
 */
  agency_id?: string;

/**
 * Service ID
 */
  service_id: string;

/**
 * Choosen time
 */
  time_choice?: string;

/**
 * optional already reserved uuid
 */
  reserved_uuid?: string;
  '_locale': 'de' | 'en' | 'fr';
}

export function getApiPublicAvailableDates(http: HttpClient, rootUrl: string, params: GetApiPublicAvailableDates$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableDatesResponse>> {
  const rb = new RequestBuilder(rootUrl, getApiPublicAvailableDates.PATH, 'get');
  if (params) {
    rb.query('strategy_name_last_name', params.strategy_name_last_name, {});
    rb.query('strategy_file_number_file_number', params.strategy_file_number_file_number, {});
    rb.query('strategy_file_number_department_number', params.strategy_file_number_department_number, {});
    rb.query('strategy_list_chosen_option', params.strategy_list_chosen_option, {});
    rb.query('participants_count', params.participants_count, {});
    rb.query('agency_id', params.agency_id, {});
    rb.query('service_id', params.service_id, {});
    rb.query('time_choice', params.time_choice, {});
    rb.query('reserved_uuid', params.reserved_uuid, {});
    rb.path('_locale', params['_locale'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AvailableDatesResponse>;
    })
  );
}

getApiPublicAvailableDates.PATH = '/api/public/{_locale}/available_dates';
