import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationSettingsService } from '../../services/application-settings/application-settings.service';

@Pipe({
    name: 'optional',
    pure: true,
    standalone: false,
})
export class OptionalPipe implements PipeTransform {
    constructor(private applicationSettingsService: ApplicationSettingsService) {}

    transform(value: string): string {
        if (!this.applicationSettingsService.markFieldAsRequired.value) {
            return value + ' (Optional)';
        }
        return value;
    }
}
