<div class="upper__container mb-5 mb-sm-2">
    <p
        [innerHTML]="
            'dynamische_frontend_texte.datum_auswahl.html_content.auswahl'
                | translate
                | replaceDateTime: selectedDate
                | async
        "
        class="mb-0"
        role="status"
    ></p>
    <p
        [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.label_zeit_auswahl' | translate | safeStyle"
    ></p>
</div>

<div class="times-container">
    @for (availableTime of availableAgenciesSelection; track availableTime) {
        <div class="col-12 mb-3">
            <a
                class="time-single-container"
                tabindex="0"
                [attr.title]="
                    'dynamische_frontend_texte.datum_auswahl.attribute_text.zeit_auswahl_eintrag_title'
                        | translate
                        | replaceDateTime: availableTime.time.time
                        | async
                "
                (click)="timeClick(availableTime.time, availableTime, $event)"
            >
                <span
                    class="time"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: availableTime.time.time
                            | async
                    "
                ></span>
                <div class="spacer">|</div>
                <span class="court fw-bold" [innerHTML]="availableTime.name"></span>
                @if (availableTime.address) {
                    ,
                    <span class="court-adress" [innerHTML]="availableTime.address"></span>
                }
            </a>
        </div>
    }
    @if (availableAgenciesSelection.length <= 0) {
        <p
            class="mb-0 fw-bold"
            [innerHTML]="'dynamische_frontend_texte.zeit_auswahl.html_content.auswahl_leer' | translate | safeStyle"
        ></p>
    }
</div>

@if (timeSelected) {
    <div class="continue-container mt-4">
        <div class="selected-date">
            {{ 'dynamische_frontend_texte.zeit_auswahl.html_content.wunschtermin' | translate | safeStyle }}
            <span class="fw-bold">{{ buildSelectedValuesText() }}</span>
        </div>
        @if (timeSelected) {
            <div class="d-flex justify-content-end mt-4">
                <button
                    type="button"
                    class="btn btn-primary"
                    accesskey="w"
                    btnPrimaryColorDirective
                    [disabled]="!submitBtnActive"
                    (click)="submit()"
                    [innerHTML]="'dynamische_frontend_texte.shared.button.next' | translate | safeStyle"
                ></button>
            </div>
        }
    </div>
}
<div class="filter-container mt-4">
    <h5
        [innerHTML]="
            'dynamische_frontend_texte.zeit_auswahl.html_content.filter_nach_dienststelle' | translate | safeStyle
        "
    >
        Sie möchten lieber anhand Ihrer Dienststelle einen freien Termin buchen?
    </h5>
    <label
        for="citizen-agency"
        [innerHTML]="
            'dynamische_frontend_texte.landing.html_content.label_dropdown_dienststelle'
                | translate
                | required
                | safeStyle
        "
    ></label>
    <ng-select
        id="citizen-agency"
        [items]="availableAgenciesSelectionTransformed"
        bindLabel="name"
        bindValue="id"
        (change)="filterAvailableAgencies($event)"
        [clearable]="false"
        placeholder="Dienststelle auswählen"
        [(ngModel)]="selectedAgency"
        dropdownCustomHoverColorDirective
    ></ng-select>
</div>
