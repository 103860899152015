<footer class="mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <span
                    class="align-self-center me-auto"
                    [innerHTML]="'dynamische_frontend_texte.footer.copyright' | translate | safeStyle"
                ></span>
                <a
                    class="p-2"
                    [attr.aria-label]="
                        generateAriaLabel(
                            'dynamische_frontend_texte.footer.datenschutz',
                            'dynamische_frontend_texte.barrierefreiheit.externe_url_aria_label'
                        )
                    "
                    target="_blank"
                    [attr.href]="'dynamische_frontend_texte.footer.datenschutz_link' | translate"
                    [innerHTML]="'dynamische_frontend_texte.footer.datenschutz' | translate | safeStyle"
                ></a>
                <a
                    class="p-2"
                    [attr.aria-label]="
                        generateAriaLabel(
                            'dynamische_frontend_texte.footer.impressum',
                            'dynamische_frontend_texte.barrierefreiheit.externe_url_aria_label'
                        )
                    "
                    target="_blank"
                    [attr.href]="'dynamische_frontend_texte.footer.impressum_link' | translate"
                    [innerHTML]="'dynamische_frontend_texte.footer.impressum' | translate | safeStyle"
                ></a>
            </div>
        </div>
    </div>
</footer>
