import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number): string {
    return `0${value}`.slice(-2);
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
    format(date: NgbDateStruct | null): string {
        return date ? `${padNumber(date.day)}.${padNumber(date.month)}.${date.year}` : '';
    }

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const dateParts = value.trim().split('.');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: 0, day: toInteger(dateParts[0]), month: 0 };
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return {
                    year: 0,
                    day: toInteger(dateParts[0]),
                    month: toInteger(dateParts[1]),
                };
            } else if (
                dateParts.length === 3 &&
                isNumber(dateParts[0]) &&
                isNumber(dateParts[1]) &&
                isNumber(dateParts[2])
            ) {
                return {
                    year: toInteger(dateParts[2]),
                    day: toInteger(dateParts[0]),
                    month: toInteger(dateParts[1]),
                };
            }
        }
        return null;
    }
}
