import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class DateRangeService {
    dateRange = (minDate: string, maxDate: string): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            const minimalDate = new Date(minDate);
            const maximumDate = new Date(maxDate);
            const ngbDate = control.value;
            if (!ngbDate) {
                return null;
            }

            const currentDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);

            if (currentDate < minimalDate || currentDate > maximumDate) {
                return {
                    date_range: {
                        value: currentDate,
                        minDate: minimalDate,
                        maxDate: maximumDate
                    }
                };
            }

            return null;
        };
    };
}
