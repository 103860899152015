import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateService } from '../../../services/state/state.service';
import { Breadcrumb } from '../model/breadcrumb';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { BookingService } from '../../../services/booking/booking.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { StateNamesEnum } from '../../../enum/state-names-enum';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';

@Component({
    selector: 'otb-top-breadcrumb',
    templateUrl: './top-breadcrumb.component.html',
    styleUrls: ['./top-breadcrumb.component.scss', './top-breadcrumb.component.mags-sop.scss']
})
export class TopBreadcrumbComponent implements OnDestroy, OnInit {
    @ViewChild('breadcrumbContainer') bcContainer: ElementRef;
    subs: Subscription = new Subscription();
    showIntroText: boolean = true;
    showBreadcrumb: boolean = true;
    breadcrumbs: Breadcrumb[] = [];

    pagesToHide: Array<StateNamesEnum> = [
        StateNamesEnum.SERVICE_ONLY,
        StateNamesEnum.COURT_AND_SERVICE,
        StateNamesEnum.THANK_YOU,
        StateNamesEnum.CANCEL,
        StateNamesEnum.CONFIRMATION
    ];

    constructor(
        private stateService: StateService,
        private breadcrumbService: BreadcrumbService,
        private bookingService: BookingService,
        private changeDetectorRef: ChangeDetectorRef,
        private applicationSettingsService: ApplicationSettingsService,
        private route: Router
    ) {
        const machineSub = this.stateService.machineStarted$.subscribe((started) => {
            if (started) {
                this.subs.add(
                    this.stateService.state$.subscribe((response) => {
                        // todo: workaround
                        if (response[1] && response[1].type === 'xstate.stop') {
                            return;
                        }
                        this.stateService.getActiveSteps();
                        this.recalculateTopContainerHeight();
                    })
                );
            }
        });

        const bcSub = this.breadcrumbService.activeBreadCrumbs.subscribe((breadcrumbs: Breadcrumb[]) => {
            if (!breadcrumbs) {
                return;
            }
            this.breadcrumbs = breadcrumbs;

            if (!this.applicationSettingsService.serviceOnly) {
                this.breadcrumbs.unshift(this.breadcrumbService.createInitialBreadcrumb(this.bookingService.booking));
            }
        });
        this.subs.add(machineSub);
        this.subs.add(bcSub);

        const routeEvents = this.route.events.subscribe((el: Event) => {
            if (!(el instanceof NavigationEnd)) {
                return;
            }

            const hideAtCurrentPage = this.pagesToHide.some((page) => {
                return el.urlAfterRedirects.indexOf(page) > -1;
            });
            this.showBreadcrumb = !hideAtCurrentPage;
        });

        this.subs.add(routeEvents);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(_event: Event): void {
        this.recalculateTopContainerHeight();
    }

    recalculateTopContainerHeight(): void {
        this.changeDetectorRef.detectChanges();
        this.breadcrumbService.recalculateTopContainerHeight(this.bcContainer);
    }

    ngOnInit(): void {
        this.stateService.machineStarted$.next(true);
    }
}
